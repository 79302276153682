<template>
  <object-select
    :value="selectedValue"
    :options="options"
    :placeholder="placeholder"
    :state="state"
    :clearable="clearable"
    :disabled="disabled"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "./ObjectSelect";
import t from "../../../i18n";

const values = [
  {value: "identity_card"},
  {value: "passport"},
  {value: "other"},
  {value: "residence_card"}
];

export default {
  name: "IdentificationDocumentSelect",
  components: {
    ObjectSelect,
  },
  props: {
    value: {type: String, default: null},
    state: ObjectSelect.props.state, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz dokument tożsamości"},
    disabled: {type: Boolean, default: false},
    clearable: {type: Boolean, default: true},
    hideOtherValue: {type: Boolean, default: false}
  },
  data() {
    return {
      optionValues: this.hideOtherValue ? values.filter((val) => val.value !== "other")  : values,
    };
  },
  computed: {
    options() {
      return this.optionValues.map(option => ({
        ...option,
        name: t(`@identification-document.${option.value}`),
      }));
    },
    selectedValue() {
      if (!this.value) {
        return null
      } else {
        return this.options.find((option) => option.value === this.value);
      }
    },
  },
  watch: {
    hideOtherValue() {
      this.optionValues = this.hideOtherValue ? values.filter((val) => val.value !== "other")  : values;
      if(this.hideOtherValue && this.value === "other"){
        this.update(null);
      }
    }
  },
  methods: {
    update(option) {
      this.$emit("input", option ? option.value : null);
    },
  },
};
</script>
