module.exports = {
    "success": "Sukces",
    "info": "Informacje",
    "warning": "Ostrzeżenie",
    "danger": "Zagrożenie",
    "Content": "Treść",
    "PESEL does not match birth date": "PESEL nie pasuje do daty urodzenia",
    "@education-level.basic": "podstawowe",
    "@education-level.without_basic": "brak wykształcenia podstawowego",
    "@education-level.gymnasium": "gimnazjalne",
    "@education-level.vocational": "zasadnicze zawodowe oraz branżowe",
    "@education-level.secondary": "średnie ogólnokształcące, techniczne oraz branżowe",
    "@education-level.post-secondary": "policealne",
    "@education-level.higher_licentiate_or_engineer": "wyższe z tytułem licencjata, inżyniera lub równorzędnym",
    "@education-level.higher_master": "wyższe z tytułem magistra lub równorzędnym",
    "@education-level.higher_doctor": "wyższe ze stopniem naukowym co najmniej doktora",
    "@education-level.other": "inne",
    "@marital-status.married": "w związku małżeńskim",
    "@marital-status.single": "panna/kawaler",
    "@marital-status.divorced": "rozwiedziona/rozwiedziony",
    "@marital-status.widowed": "wdowa/wdowiec",
    "@identification-document.identity_card": "Dowód osobisty",
    "@identification-document.driving_license": "Prawo jazdy",
    "@identification-document.passport": "Paszport",
    "@identification-document.other": "Inny dokument stwierdzający tożsamość",
    "@identification-document.residence_card": "Karta pobytu",
    "User's account is disabled": "Konto użytkownika zablokowane. Skontaktuj się z administratorem.",
    "Invalid login or password.": "Niepoprawny login lub hasło.",
    "Invalid jwt.": "Niepoprawny token jwt.",
    "@employment.professionally_active": "aktywny zawodowo",
    "@employment.pensioner": "emeryt/rencista",
    "@employment.on_the_support_of_other_people": "na utrzymaniu innych osób",
    "@employment.unemployed": "bezrobotny",
    "@employment.maintained_from_others_sources": "utrzymuje się z innych źródeł",
    "Missing or invalid two factor authentication code.": "Podaj tymczasowy kod autoryzacyjny",
    "Description can not be null": "Ta wartość nie powinna być pusta.",
    "First range": "Pierwszy przedział",
    "Range interval": "powtarzany co",
    "days": "dni",
    "adult-schizophrenia": "dorośli - schizofrenia",
    "adult-depression": "dorośli - depresja",
    "adults-bipolar disorder": "dorośli - chad zaburzenie afektywne dwubiegunowe",
    "adults-eating disorder": "dorośli - zaburzenia odżywiania",
    "adults-anxiety disorders": "dorośli - zaburzenia lękowe",
    "adults-adaptive disorders": "dorośli - zaburzenia adaptacyjne",
    "adults-personality disorders": "dorośli - zaburzenia osobowości",
    "adults-dementia disorders": "dorośli - zaburzenia otępienne",
    "children-screening": "przesiewowy dla dzieci i młodzieży",
    "adults-basic": "dorośli - podstawowy",
    "environmental-basic": "podstawowy środowiskowy",
    "categorical-profile-sczp-dim": "kategorialny dla ŚCZP DiM",
    "screening-profile-helpline-sczp-dim": "przesiewowy – infolinia - ŚCZP DiM",
    "range_of_disability": "Stopień upośledzenia",
    "capacity": "Zdolność",
    "execution": "Wykonanie",
    "environmental_factors": "Czynniki środowiskowe",
    "essence_of_change": "Istota zmiany",
    "location": "Umiejscowienie zmiany",
    "dietitian": "Dietetyk",
    "pediatrician": "Pediatra",
    "@disability-level.none": "brak",
    "@disability-level.category_province_disability_opinion_team": "Orzeczone przez powiatowe lub wojewódzkie zespoły do spraw orzekania o stopniu niepełnosprawności",
    "@disability-level.light": "lekki",
    "@disability-level.moderate": "umiarkowany",
    "@disability-level.high": "znaczny",
    "@disability-level.under_16": "Orzeczenie o niepełnosprawności dziecka do 16 roku życia bez ustalania stopnia niepełnosprawności",
    "@disability-level.category_zus_doctor_opinion": "Orzeczone przez lekarza orzecznika ZUS",
    "@disability-level.zus_partial_incapacity_for_work": "Częściowa niezdolność do pracy",
    "@disability-level.zus_partial_incapacity_for_work_before_1998": "Częściowa niezdolność do pracy na orzeczeniu wydanym w okresie od 1 stycznia do 16 sierpnia 1998 r.",
    "@disability-level.zus_full_incapacity_for_work": "Całkowita niezdolność do pracy",
    "@disability-level.zus_full_incapacity_for_work_and_living": "Całkowita niezdolność do pracy i do samodzielnej egzystencji",
    "@disability-level.category_disability_commission_opinion": "Orzeczone przez komisje do spraw inwalidztwa i zatrudnienia",
    "@disability-level.commission_iii_degree": "III grupa inwalidzka",
    "@disability-level.commission_ii_degree": "II grupa inwalidzka",
    "@disability-level.commission_i_degree": "I grupa inwalidzka",
    "@disability-level.category_krus_disability_opinion_team": "Orzeczone przez Kasy Rolniczego Ubezpieczenia Społecznego",
    "@disability-level.krus_incapacity_for_work_without_benefit": "Stała lub długotrwała niezdolność do pracy w gospodarstwie rolnym bez prawa do zasiłku pielęgnacyjnego",
    "@disability-level.krus_incapacity_for_work_with_benefit": "Stała lub długotrwała niezdolność do pracy w gospodarstwie rolnym z prawem do zasiłku pielęgnacyjnego",
    "@disability-level.refusal_to_provide_information": "Odmowa podania informacji",
    "physiotherapist": "Fizjoterapeuta",
    "doctor": "Lekarz",
    "nurse": "Pielęgniarz/ka",
    "driver": "Kierowca",
    "receptionist": "Recepcjonista/ka",
    "psychologist": "Psycholog",
    "psychotherapist": "Psychoterapeuta",
    "neuro_speech_therapist": "Neurologopeda",
    "speech_therapist": "Logopeda",
    "legal guardian": "Opiekun prawny",
    "son": "Syn",
    "daughter": "Córka",
    "mother": "Matka",
    "father": "Ojciec",
    "brother": "Brat",
    "sister": "Siostra",
    "pedagogue": "Pedagog",
    "case history": "Historia pacjenta",
    "patient reported questionnaire": "Kwestionariusz pacjenta",
    "clinical examination": "Badanie kliniczne",
    "specialist examination": "Badanie specjalistyczne",
    "Execution": "Wykonanie",
    "@diagnosis_general_type_adjective.medical": "medyczna",
    "@diagnosis_general_type_adjective.functional": "funkcjonalna",
    "@diagnosis_general_type_adjective.family_interview": "rodzinna",
    "@diagnosis_general_type_adjective.psychiatric_child": "psychiatryczna",
    "@diagnosis_general_type_adjective.pediatric_child": "pediatryczna",
    "@diagnosis_general_type_adjective.neurological_child": "neurologiczna",
    "@patient_attachment_category.information_card": "Karty informacyjne",
    "@patient_attachment_category.other": "Pozostałe",
    "@patient_attachment_category.authorization": "Upoważnienia",
    "@patient_attachment_category.prescription": "Recepty",
    "@patient_attachment_category.appointment": "Wizyty",
    "@patient_attachment_category.survey": "Ankiety",
    "@patient_attachment_category.opinion": "Opinie psychologiczno - pedagogiczne",
    "@patient_attachment_category.medical_examination": "Badania lekarskie",
    "@patient_attachment_category.registration_card": "Karta zgłoszenia pacjenta",
    "@patient_attachment_category.developmental_interview": "Wywiad rozwojowy",
    "@patient_attachment_category.consent_of_legal_guardians": "Zgody opiekunów prawnych",
    "@patient_attachment_category.outgoing_documents": "Dokumenty wychodzące",
    "@patient_attachment_category.court_letters": "Pisma sądowe",
    "created": "utworzona",
    "canceled": "odwołana",
    "finished": "zakończona",
    "opened": "otwarta",
    "checkedin": "zarejestrowana",
    "call": "telefoniczny",
    "eyewitness": "naoczny",
    "direct": "bezpośredni",
    "mail": "mailowy",
    "video": "wideo rozmowa",
    "application": "aplikacja komputerowa",
    "other": "inny",
    "@not_working_reason.pension": "Renta",
    "@not_working_reason.parental_leave": "Urlop wychowawczy",
    "@not_working_reason.childcare": "Opieka nad dzieckiem",
    "@not_working_reason.unemployed": "Bezrobotny",
    "@education.basic": "podstawowe",
    "@education.vocational": "zawodowe",
    "@education.secondary": "średnie",
    "@education.higher": "wyższe",
    "@professional_work.works": "Pracuje",
    "@professional_work.not_works": "Nie pracuje",
    "@pregnancy_normality.correct": "Prawidłowa",
    "@pregnancy_normality.complications": "Powikłana",
    "yes": "tak",
    "no": "nie",
    "information_refusal": "odmowa podania informacji",
    "does_not_remember": "Klient/ka nie pamięta",
    "@family_state.full": "Rodzina pełna",
    "@family_state.alone_parent": "Samotnie wychowujący rodzic",
    "@family_state.reconstructed": "Rodzina rekonstruowana",
    "@family_state.foster": "Rodzina zastępcza",
    "@pre_school_went.positive": "Chodził/a do przedszkola",
    "@pre_school_went.negative": "Nie chodził/a",
    "Wrong PWZ in file": "Niepoprawny numer zawodu w pliku",
    "Unsupported file": "Niepoprawny plik",
    "Numbers already exists.": "Numery recept w pliku zostały już raz zaimportowane.",
    "Pwz can not be null": "Brak numeru PWZ.",
    "Cannot finish document before start date.": "Nie można zamknąć dokumentu przed datą rozpoczęcia.",
    "Too many drugs on the prescription. Max for this type: 1": "Zbyt dużo leków na recepcie. Dopuszczalny tylko 1 lek na recepcie RPW.",
    "Too many drugs on the prescription. Max for this type: 5": "Zbyt dużo leków na recepcie. Dopuszczalne tylko 5 leków na recepcie RP.",
    "Request failed with status code 413": "Błąd. Plik zbyt duży.",
    "Worker(s) has another appointment(s) in this term": "Wybrany pracownik ma inną wizytę w tym terminie.",
    "Available Prescription Number not found.": "Brak dostępnych numerów recpet.",
    "There's a limit of 20 patients on one Appointment": "Przekroczono limit pacjentów na wizycie. Dopuszczalny limit: 20.",
    "family": "Członek rodziny",
    "friend": "Przyjaciel",
    "colleague": "Znajomy/znajoma z pracy",
    "institution": "Pracownik instytucji",
    "professional": "Specjalista",
    "outsider": "Osoba postronna",
    "@incapacitation-degree.none": "brak",
    "@incapacitation-degree.partly": "ubezwłasnowolnienie częściowe",
    "@incapacitation-degree.completely": "ubezwłasnowolnienie całkowite",
    "@incapacitation-degree-document.none": "nie jest ubezwłasnowolniona",
    "@incapacitation-degree-document.partly": "jest ubezwłasnowolniona częsciowo",
    "@incapacitation-degree-document.completely": "jest ubezwłasnowolniona całkowicie",
    "@predicate.threat_of_life_or_health": "osoba badana zagraża bezpośrednio własnemu życiu lub zdrowiu innych osbób.",
    "@predicate.deterioration_of_health": "nieprzyjęcie osoby badanej do szpitala psychiatrycznego spowoduje znaczne pogorszenie stanu zdrowia psychicznego.",
    "@predicate.unable_to_satisfy_basic_life_needs": "osoba badana jest niezdolna do zaspokojenia podstawowych potrzeb życiowych z powodu choroby psychicznej, a uzasadnione jest przewidywanie, że leczenie w szpitalu psychitraczynym przyniesie poprawę jej stanu zdrowia.",
    "@person-eligible.guardian": "Osoba sprawująca opiekę faktyczną",
    "@person-eligible.statutory_representative": "Przedstawiciel ustawowy",
    "hospital": "Skierowanie do szpitala",
    "consultation": "Skierowanie na konsultację",
    "versionTag": "Tag wersji",
    "roles": "Role",
    "treatmentTypes": "Rodzaje leczenia",
    "Worker's account is disabled": "Konto pracownika zablokowane. Skontaktuj się z administratorem.",
    "for_students": "Dla uczniów",
    "for_students_and_families": "Dla uczniów i rodzin",
    "for_OZDKP_families": "Dla rodzin OzDKP",
    "for_persons_of_public_trust": "Dla osób zaufania publicznego",
    "for_social_workers": "Dla pracowników socjalnych",
    "picnic": "Piknik",
    "Can not open archive": "Nie można otworzyć archiwum",
    "Required is one file in archive only": "Wymagany jest tylko jeden plik w archiwum",
    "closed": "zamknięty",
    "@ipz-status.opened": "otwarty",
    "@ipz-status.closed": "zakończony",
    "@ipz-status.canceled": "odwołany",
    "@child-ipz-status.edit": "w trakcie edycji",
    "@child-ipz-status.opened": "otwarty",
    "@child-ipz-status.closed": "zamknięty",
    "interview": "Wywiad",
    "questionnaire": "Kwestionariusz",
    "@person-eligible.grandmother_m": "babcia macierzysta",
    "@person-eligible.grandfather_m": "dziadek macierzysty",
    "@person-eligible.grandmother_o": "babcia ojczysta",
    "@person-eligible.grandfather_o": "dziadek ojczysty",
    "@person-eligible.extended_family": "dalsza rodzina",
    "@person-eligible.social_worker": "pracownik socjalny",
    "@person-eligible.neighbors": "sąsiedzi",
    "@person-eligible.spiritual_person": "osoba duchowna",
    "@person-eligible.husband": "mąż",
    "@person-eligible.wife": "żona",
    "@person-eligible.grandson": "wnuk",
    "@person-eligible.granddaughter": "wnuczka",
    "@person-eligible.partner_m": "partner",
    "@person-eligible.partner_f": "partnerka",
    "@person-eligible.policeman": "policjant",
    "@person-eligible.curator": "kurator",
    "@person-eligible.doctor": "doktor",
    "@person-eligible.nurse": "pielęgniarka",
    "@person-eligible.close_person": "bliska osoba (przyjaciel)",
    "@person-eligible.therapist": "terapeuta",
    "@person-eligible.son": "syn",
    "@person-eligible.daughter": "córka",
    "@person-eligible.stepmother": "macocha",
    "@person-eligible.stepfather": "ojczym",
    "@person-eligible.colleague_m": "kolega",
    "@person-eligible.colleague_f": "koleżanka",
    "@person-eligible.sister": "siostra",
    "@person-eligible.brother": "brat",
    "@person-eligible.mother": "matka",
    "@person-eligible.father": "ojciec",
    "@person-eligible.family_assistant": "asystent rodziny",
    "@person-eligible.foster_care_coordinator": "koordynator pieczy zastępczej",
    "@person-eligible.another_person_from_the_backrest_network": "inna osoba z sieci oparcia",
    "@person-eligible.stepson": "pasierb",
    "@person-eligible.stepdaughter": "pasierbica",
    "@person-eligible.stepbrother": "brat przyrodni",
    "@person-eligible.stepsister": "siostra przyrodnia",
    "@person-eligible.charge": "podopieczny",
    "@person-eligible.charge_by_law": "podopieczny (ustawowo)",
    "@cancel-reason-categories.not_needed": "Pobyt ciągły nie jest potrzebny dla pacjenta",
    "@cancel-reason-categories.not_appear": "Pacjent nie stawił się na pobyt ciągły",
    "@cancel-reason-categories.mistake": "Pomyłka pracownika ŚCZP",
    "@cancel-reason-categories.other": "Inne, jakie?",
    "@cancel-reason-categories.change_specialist_decision": "Zmiana decyzji specjalisty kierującego klienta na pobyt ciągły",
    "@cancel-reason-categories.non_compliance_with_conditions": "Nie przestrzeganie warunków świadczenia usługi (zapisów regulaminu / umowy)",
    "@cancel-reason-categories.failure_to_appear": "Klient się nie stawił (nie powiadomił o rezygnacji z usługi)",
    "@cancel-reason-categories.client_resignation": "Rezygnacja klienta",
    "@cancel-reason-categories.annulled": "Pacjent odwołał pobyt ciągły (pacjent zgłosił brak potrzeby pobytu ciągłego)",
    "@cancel-reason.patient_did_not_show_up": "pacjent nie stawił się na wizytę",
    "@cancel-reason.patient_canceled": "pacjent odwołał wizytę",
    "@cancel-reason.appeal_due_to_the_fault_of_a_specialist": "odwołanie z winy specjalisty",
    "@cancel-reason.reception_mistake": "pomyłka recepcji",
    "@cancel-reason.doubled_visit": "zdublowana wizyta",
    "@cancel-reason.other": "inny",
    "@appointment-statistics-status.not-yet-realized": "Niezrealizowane",
    "@appointment-statistics-status.planned": "Zaplanowane",
    "@appointment-statistics-status.completed": "Zrealizowane",
    "@diagnosis.examined": "Przebadano",
    "@diagnosis.not_examined": "Nie badano",
    "@diagnosis.state": "Stan:",
    "@diagnosis.correct": "prawidłowy",
    "@diagnosis.not_correct": "nieprawidłowy",
    "@diagnosis.fit": "sprawny",
    "@diagnosis.not_fit": "niesprawny",
    "@diagnosis.unaffected": "niezaburzona",
    "@diagnosis.affected": "nieprawidłowa",
    "@diagnosis.full_extent": "w pełnym zakresie",
    "@diagnosis.incorrect": "nieprawidłowe",
    "@diagnosis.upper_limbs.strength.weakened": "osłabiona",
    "@diagnosis.upper_limbs.strength.norm": "w normie",
    "@diagnosis.upper_limbs.strength.incorrect": "nieprawidłowa",
    "@diagnosis.upper_limbs.strength.asymmetry": "asymetria",
    "@diagnosis.upper_limbs.reflexes.norm": "prawidłowe",
    "@diagnosis.upper_limbs.reflexes.weakened": "wzmożone osłabione",
    "@diagnosis.upper_limbs.reflexes.asymmetry": "asymetria",
    "@diagnosis.upper_limbs.reflexes.others": "inne",
    "@diagnosis.upper_limbs.tension.norm": "prawidłowe",
    "@diagnosis.upper_limbs.tension.increased": "wzmożone",
    "@diagnosis.upper_limbs.tension.weakened": "osłabione",
    "@diagnosis.upper_limbs.tension.asymmetry": "asymetria",
    "@diagnosis.upper_limbs.cerebellar.not_examined": "nie badano",
    "@diagnosis.upper_limbs.cerebellar.no_deviations": "bez odchyleń",
    "absent": "nieobecne",
    "present": "obecne",
    "@diagnosis.upper_limbs.cerebellar.others": "inne",
    "adequate": "adekwatny",
    "inadequate": "nieodpowiedni",
    "@diagnosis.psychiatric.details.behaviour_drive.correct": "prawidłowe",
    "@diagnosis.psychiatric.details.behaviour_drive.mobility": "ruchliwość",
    "@diagnosis.psychiatric.details.behaviour_drive.immobility": "bezruch",
    "@diagnosis.psychiatric.details.behaviour_drive.motor_stereotypy": "stereotypia ruchowa",
    "@diagnosis.psychiatric.details.behaviour_drive.calm_behavior": "zachowanie spokojne",
    "@diagnosis.psychiatric.details.behaviour_drive.strange": "dziwaczne",
    "@diagnosis.psychiatric.details.behaviour_drive.clownish": "błazeńskie",
    "@diagnosis.psychiatric.details.behaviour_drive.inhibition": "zahamowanie",
    "@diagnosis.psychiatric.details.behaviour_drive.restraint": "otamowanie",
    "@diagnosis.psychiatric.details.behaviour_drive.excitement": "podniecenie",
    "@diagnosis.psychiatric.details.behaviour_drive.psychomotor_stimulation": "pobudzenie psychoruchowe",
    "@diagnosis.psychiatric.details.behaviour_drive.other": "inne",
    "@diagnosis.psychiatric.details.expression.cheerful": "pogodny",
    "@diagnosis.psychiatric.details.expression.grim": "posępny",
    "@diagnosis.psychiatric.details.expression.aching": "zbolały",
    "@diagnosis.psychiatric.details.expression.careworn": "zgnębiony",
    "@diagnosis.psychiatric.details.expression.expressions_lively": "mimika dość żywa",
    "@diagnosis.psychiatric.details.expression.expressions_not_vivid": "mimika niezbyt żywa",
    "@diagnosis.psychiatric.details.expression.expressions_stiff": "mimika sztywna",
    "@diagnosis.psychiatric.details.expression.face_masked": "twarz maskowata",
    "@diagnosis.psychiatric.details.expression.grimaces_tics": "grymasy, tiki",
    "@diagnosis.psychiatric.details.expression.other": "inne",
    "@diagnosis.psychiatric.details.somatic_complaints.missing": "brak skarg",
    "@diagnosis.psychiatric.details.somatic_complaints.present": "obecne skargi (czy w czasie wywiadu dziecko skarżyło się na zawroty głowy, bóle głowy itp., inne)",
    "@diagnosis.psychiatric.details.consciousness.unaffected": "niezaburzona",
    "@diagnosis.psychiatric.details.consciousness.somnolence": "senność",
    "@diagnosis.psychiatric.details.consciousness.half_coma": "półśpiączka",
    "@diagnosis.psychiatric.details.consciousness.coma": "śpiączka",
    "@diagnosis.psychiatric.details.consciousness.narrowed": "zwężone pole świadomości",
    "@diagnosis.psychiatric.details.consciousness.blackout": "zamroczenie",
    "@diagnosis.psychiatric.details.orientation.full": "pełna",
    "@diagnosis.psychiatric.details.orientation.limited": "ograniczona",
    "@diagnosis.psychiatric.details.orientation.limited.time": "w czasie",
    "@diagnosis.psychiatric.details.orientation.limited.place": "w miejscu",
    "@diagnosis.psychiatric.details.orientation.limited.surroundings": "otoczeniu",
    "@diagnosis.psychiatric.details.orientation.limited.own_person": "własnej osobie",
    "@diagnosis.psychiatric.details.orientation.limited.confusion": "dezorientacja",
    "@diagnosis.psychiatric.details.thoughts.no_deviations": "bez odchyleń",
    "@diagnosis.psychiatric.details.thoughts.mating_disorder": "zaburzenie kojarzenia",
    "@diagnosis.psychiatric.details.thoughts.impoverishment": "zubożenie kojarzenia",
    "@diagnosis.psychiatric.details.thoughts.dissociation": "rozkojarzenie",
    "@diagnosis.psychiatric.details.thoughts.disruption": "rozerwanie związków myślowych",
    "@diagnosis.psychiatric.details.thoughts.accelerated": "przyśpieszony tok myślenia",
    "@diagnosis.psychiatric.details.thoughts.slow": "zwolniony tok myślenia",
    "@diagnosis.psychiatric.details.thoughts.race": "gonitwa myśli",
    "@diagnosis.psychiatric.details.thoughts.logorrhea": "słowotok",
    "@diagnosis.psychiatric.details.thoughts.other": "inne",
    "@diagnosis.psychiatric.details.speech.norm": "niezaburzona",
    "@diagnosis.psychiatric.details.speech.verbosity": "gadatliwość",
    "@diagnosis.psychiatric.details.speech.reticence": "małomówność",
    "@diagnosis.psychiatric.details.speech.mutism": "mutyzm",
    "@diagnosis.psychiatric.details.speech.speech_defects": "wady wymowy",
    "@diagnosis.psychiatric.details.speech.aphonia": "bezgłos",
    "@diagnosis.psychiatric.details.speech.deaf_mute": "głuchoniemota",
    "@diagnosis.psychiatric.details.speech.aphasia": "afazja",
    "@diagnosis.psychiatric.details.speech.dysarthria": "dysartria",
    "@diagnosis.psychiatric.details.speech.word_creation": "słowotwory",
    "@diagnosis.psychiatric.details.speech.stereotypies": "stereotypie",
    "@diagnosis.psychiatric.details.speech.echolalia": "echolalie",
    "@diagnosis.psychiatric.details.speech.neologisms": "neologizmy",
    "@diagnosis.psychiatric.details.speech.other": "inne",
    "@diagnosis.psychiatric.details.motor.norm": "prawidłowa",
    "@diagnosis.psychiatric.details.motor.incorrect": "nieprawidłowa",
    "@diagnosis.psychiatric.details.motor.difficulties_moving": "trudności w poruszaniu się",
    "@diagnosis.psychiatric.details.motor.mobility_clumsiness": "niezgrabność ruchowa",
    "@diagnosis.psychiatric.details.motor.visible_motor_stereotypes": "widoczne stereotypie ruchowe",
    "@diagnosis.psychiatric.details.motor.tics": "tiki",
    "@diagnosis.psychiatric.details.motor.tremor": "drżenia",
    "@diagnosis.psychiatric.details.motor.other": "inne",
    "@diagnosis.psychiatric.details.attention.norm": "prawidłowa",
    "@diagnosis.psychiatric.details.attention.distracted": "rozproszona",
    "@diagnosis.psychiatric.details.attention.shifted": "przerzutna",
    "@diagnosis.psychiatric.details.attention.viscous": "lepka",
    "@diagnosis.psychiatric.details.attention.difficulties_focusing": "trudności skupiania uwagi",
    "@diagnosis.psychiatric.details.attention.easy_wear": "łatwe nużenie się",
    "@diagnosis.psychiatric.details.attention.other": "inne",
    "@diagnosis.psychiatric.details.mood.norm": "niezaburzony",
    "@diagnosis.psychiatric.details.mood.lowered": "obniżony",
    "@diagnosis.psychiatric.details.mood.elevated": "podwyższony",
    "@diagnosis.psychiatric.details.mood.indifferent": "obojętny",
    "@diagnosis.psychiatric.details.mood.lively": "żywy",
    "@diagnosis.psychiatric.details.mood.happy": "wesoły",
    "@diagnosis.psychiatric.details.mood.dull": "tępy",
    "@diagnosis.psychiatric.details.mood.depressed": "przygnębiony",
    "@diagnosis.psychiatric.details.mood.lazy": "leniwy",
    "@diagnosis.psychiatric.details.mood.unstable": "chwiejny",
    "@diagnosis.psychiatric.details.mood.tearful": "płaczliwy",
    "@diagnosis.psychiatric.details.mood.euphoric": "euforyczny",
    "@diagnosis.psychiatric.details.mood.manic": "maniakalny",
    "@diagnosis.psychiatric.details.mood.other": "inne",
    "@diagnosis.psychiatric.details.affect.norm": "prawidłowy",
    "@diagnosis.psychiatric.details.affect.pale": "blady",
    "@diagnosis.psychiatric.details.affect.vivid": "żywy",
    "@diagnosis.psychiatric.details.affect.incorrect": "nieprawidłowy",
    "@diagnosis.psychiatric.details.perceptual_disorder.norm": "nieobecne",
    "@diagnosis.psychiatric.details.perceptual_disorder.illusions": "złudzenia",
    "@diagnosis.psychiatric.details.perceptual_disorder.hallucinations": "omamy",
    "@diagnosis.psychiatric.details.perceptual_disorder.pseudohallucinations": "pseudohalucynacje",
    "@diagnosis.psychiatric.details.perceptual_disorder.body_schema_disorder": "zaburzenia schematu ciała",
    "@diagnosis.psychiatric.details.perceptual_disorder.hypochondrial": "dolegliwości hipochondryczne",
    "@diagnosis.psychiatric.details.perceptual_disorder.migraine": "migrena",
    "@diagnosis.psychiatric.details.perceptual_disorder.panic": "zjawiska napadowe",
    "@diagnosis.psychiatric.details.perceptual_disorder.aura": "aura",
    "@diagnosis.psychiatric.details.perceptual_disorder.deja_vu": "deja vu",
    "@diagnosis.psychiatric.details.perceptual_disorder.micropsia": "mikropsje",
    "@diagnosis.psychiatric.details.perceptual_disorder.macropsia": "makropsje",
    "@diagnosis.psychiatric.details.perceptual_disorder.other": "inne",
    "@diagnosis.psychiatric.details.pathological_thought.norm": "nieobecne",
    "@diagnosis.psychiatric.details.pathological_thought.delusions": "urojenia",
    "@diagnosis.psychiatric.details.pathological_thought.obsessive_compulsive": "natręctwa myślowe",
    "@diagnosis.psychiatric.details.pathological_thought.obsessions": "natręctwa ruchowe",
    "@diagnosis.psychiatric.details.pathological_thought.magical_thinking": "myślenie magiczne",
    "@diagnosis.psychiatric.details.pathological_thought.delusional_interpretation": "urojeniowa interpretacja trafnych spostrzeżeń",
    "@diagnosis.psychiatric.details.pathological_thought.other": "inne",
    "@diagnosis.psychiatric.details.illness_sense.norm": "adekwatne",
    "@diagnosis.psychiatric.details.illness_sense.insufficient": "niedostateczne",
    "@diagnosis.psychiatric.details.illness_sense.lack": "brak",
    "@diagnosis.psychiatric.details.illness_sense.exaggerated": "przesadne",
    "@diagnosis.psychiatric.details.illness_sense.dissimulation": "dysymulacja",
    "@diagnosis.psychiatric.details.illness_sense.simulation": "symulacja",
    "@diagnosis.psychiatric.details.illness_sense.aggravation": "agrawacja",
    "@diagnosis.psychiatric.details.illness_sense.other": "inne",
    "@diagnosis.psychiatric.details.memory.norm": "bez zaburzeń",
    "@diagnosis.psychiatric.details.memory.other": "inne",
    "@diagnosis.psychiatric.details.intelligence.outstanding": "wybitna",
    "@diagnosis.psychiatric.details.intelligence.average": "przeciętna",
    "@diagnosis.psychiatric.details.intelligence.mediocre": "mierna",
    "@diagnosis.psychiatric.details.intelligence.low": "niska",
    "@diagnosis.psychiatric.details.intelligence.reduced": "obniżona",
    "@diagnosis.psychiatric.details.intelligence.stupor": "otępienie",
    "@diagnosis.psychiatric.details.intelligence.retard": "upośledzenie umysłowe",
    "@diagnosis.psychiatric.details.intelligence.retard.level": "Określ poziom upośledzenia",
    "@diagnosis.psychiatric.details.urges.reduction_disappearance": "obniżenie lub zanik",
    "@diagnosis.psychiatric.details.urges.anorexia": "anoreksja",
    "@diagnosis.psychiatric.details.urges.bulimia": "bulimia",
    "@diagnosis.psychiatric.details.urges.sexual": "właściwości popędu seksualnego",
    "@diagnosis.psychiatric.details.urges.self_injury_tendencies": "skłonność do samookaleczeń, działań impulsywnych",
    "@diagnosis.psychiatric.details.urges.contaminate_tendency": "skłonność do zanieczyszczania się",
    "@diagnosis.psychiatric.details.urges.other": "inne",
    "@continuousStay.daily": "Oddział dzienny",
    "@continuousStay.daily_up_to_4_hours": "Oddział dzienny (do 4 godzin)",
    "@continuousStay.full_day": "Oddział całodobowy",
    "@continuousStay.in_secured_flat": "Pobyt w mieszkaniu chronionym",
    "@continuousStay.overnight_intervention_spaces": "Noclegowe miejsca interwencyjne",
    "@continuousStay.rehabilitation_sanatorium": "Turnus rehabilitacyjny",
    "@continuousStay.in_hostel": "Pobyt w hostelu",
    "@continuousStay.away_workshops": "Warsztaty wyjazdowe",
    "@continuousStay.in_progress": "w trakcie",
    "@continuousStay.closed": "zamknięty",
    "@continuousStay.canceled": "odwołany",
    "@prevention.class_branches": "oddziały klasowe",
    "@prevention.social_campaign": "kampania społeczna",
    "@prevention.for_specialists": "dla specjalistów",
    "@prevention.ozdkp_relatives": "bliscy OZDKP",
    "@prevention.for_parents": "dla rodziców",
    "@prevention.social_workers": "pracownicy socjalni",
    "@prevention.public_trust_workers": "pracownicy zaufania publicznego",
    "@prevention.POZ_doctors": "lekarze POZ",
    "@prevention.priests": "księża",
    "@prevention.police": "policja",
    "@prevention.municipal_police": "straż miejska",
    "@prevention.judges": "sędziowie",
    "@prevention.prosecutors": "prokuratorzy",
    "@prevention.psychologists": "psycholodzy",
    "@prevention.school_educators": "pedagodzy szkolni",
    "@prevention.others": "inni",
    "@prevention.medical_caregivers": "opiekunowie medyczni",
    "@prevention.family_assistants": "asystenci rodziny",
    "@prevention.occupational_therapists": "terapeuci zajęciowi",
    "@prevention.medical_rescuers": "ratownicy medyczni",
    "@prevention.nurses": "pielęgniarze",
    "local": "lokalna",
    "remote": "wyjazdowa",
    "telephone": "telefoniczna",
    "@appointment-params-location.inhouse": "Usługa w placówce, centrum",
    "@appointment-params-location.health_facility": "Placówka zdrowia poza ŚCZP: POZ, AOS, izba przyjęć, oddział szpitalny, ZOL",
    "@appointment-params-location.social_welfare_facility": "Placówka pomocy społecznej poza ŚCZP: ops, śds, klub samopomocy, dps, schronisko dla bezdomnych, placówka opiekuńczo-wychowawcza, ośrodek wsparcia dziennego dla dzieci i młodzieży, żłobek",
    "@appointment-params-location.education_facility": "Placówka oświatowa: przedszkole, szkoła. ppp, mow, mos, osw",
    "@appointment-params-location.other_facility": "Inna instytucja: ośrodek penitencjarny, policyjna izba dziecka, inne",
    "@appointment-params-contact.personally": "Kontakt osobisty",
    "@appointment-params-contact.remotely": "Kontakt zdalny za pośrednictwem urządzeń elektronicznych - rozmowa telefoniczna, czat, telekonferencja, videokonferencja",
    "@appointment-params-contact.written": "Kontakt pisemny - za pośrednictwem słowa pisanego np. list, email",
    "@appointment-params-mode.crisis": "Kryzysowy / nagły - odpowiedź na zgłoszenie do 24h",
    "@appointment-params-mode.urgent": "Pilny - odpowiedź na zgłoszenie do 72h",
    "@appointment-params-mode.ordinary": "Zwyczajny - odpowiedź na zgłoszenie w miarę wolnych terminów",
    "@appointment-params-oda.oda": "Porada/sesja była poprowadzona w Podejściu Otwartego Dialogu",
    "@appointment-params-additional-parameters.active_care_CZP": "Opieka czynna w rozumieniu CZP",
    "@appointment-params-formOfCare.uzamb": "UZAMB- opieka ambulatoryjna, w siedzibie",
    "@appointment-params-formOfCare.uzsrd": "UZSRD - opieka środowiskowa, w domu",
    "@appointment-params-formOfCare.uzodd": "UZODD - opieka dzienna, oddział dzienny-półdzienny",
    "@appointment-params-formOfCare.uznmk": "UZNMK - opieka całodobowa, noclegowe miejsce kryzysowe",
    "@appointment-params-formOfCare.uzhst": "UZHST - opieka całodobowa w hostelu",
    "@appointment-params-formOfCare.uzdor": "UZDOR - doraźna",
    "@appointment-params-formOfCare.usamb": "USAMB  - usługa społeczna wykonana w placówce pomocy społeczna",
    "@appointment-params-formOfCare.ussrd": "USSRD - usługa społeczna w środowisku klienta;",
    "@appointment-params-formOfCare.ussds": "USSDS - usługa społeczna w ŚDS",
    "@appointment-params-formOfCare.usksp": "USKSP - klub samopomocy",
    "@appointment-params-formOfCare.uscow": "USCOW - całodobowa opieka w ośrodku wsparcia",
    "@appointment-params-formOfCare.usmch": "USMCH - mieszkanie chronione i wspierane",
    "@appointment-params-ipzStage.ipz_before": "Przed IPZ",
    "@appointment-params-ipzStage.ipz_create": "Ustalenie IPZ",
    "@appointment-params-ipzStage.ipz_supervision": "Monitoring IPZ",
    "@appointment-params-ipzStage.ipz_close": "Zamknięcie IPZ",
    "@source-of-information.internet": "internet",
    "@source-of-information.internet_news_website": "portal informacyjny lub horyzontalny",
    "@source-of-information.internet_thematic_website": "portal tematyczny lub edukacyjny",
    "@source-of-information.internet_social_networking_website": "portal społecznościowy",
    "@source-of-information.internet_do_not_remember": "nie pamiętam",
    "@source-of-information.press": "prasa",
    "@source-of-information.press_text_field": "prasa",
    "@source-of-information.press_do_not_remember": "nie pamiętam",
    "@source-of-information.radio": "radio",
    "@source-of-information.radio_text_field": "radio",
    "@source-of-information.radio_do_not_remember": "nie pamiętam",
    "@source-of-information.tv": "telewizja",
    "@source-of-information.tv_text_field": "telewizja",
    "@source-of-information.tv_do_not_remember": "nie pamiętam",
    "@source-of-information.social_env": "otoczenie społeczne",
    "@source-of-information.social_env_close_family": "bliżsi członkowie rodziny (rodzice, rodzeństwo, dziadkowie)",
    "@source-of-information.social_env_extended_family": "dalsi członkowie rodziny (wujostwo, kuzynostwo, itp.)",
    "@source-of-information.social_env_friends": "od znajomych lub przyjaciół",
    "@source-of-information.social_env_neighbours": "sąsiedzi",
    "@source-of-information.social_env_fellow_worker": "od osób, z którymi pracuje zawodowo",
    "@source-of-information.social_env_hobby_colleague": "od osób zaangażowanych w grupę hobbystyczną",
    "@source-of-information.social_env_do_not_remember": "nie pamiętam",
    "@source-of-information.school": "szkoła",
    "@source-of-information.school_psychologist": "od psychologa szkolnego",
    "@source-of-information.school_pedagogue": "od pedagoga szkolnego",
    "@source-of-information.school_class_tutor": "od wychowawcy klasy",
    "@source-of-information.school_subject_teacher": "od nauczyciela przedmiotu",
    "@source-of-information.school_childes_friend": "od rówieśnika dziecka",
    "@source-of-information.school_parent_of_other_child": "od rodzica innego dziecka",
    "@source-of-information.school_other_person": "od innej osoby związanej ze środowiskiem szkolnym",
    "@source-of-information.school_do_not_remember": "nie pamiętam",
    "@source-of-information.social_trust": "osoby zaufania społecznego",
    "@source-of-information.social_trust_social_welfare_worker": "pracownik Ośrodka Pomocy Społecznej (OPS)",
    "@source-of-information.social_trust_province_labor_office_employee": "pracownik Powiatowego Urzędu Pracy",
    "@source-of-information.social_trust_sczp_employee": "pracownik Środowiskowego Centrum Zdrowia Psychicznego (ŚCZP)",
    "@source-of-information.social_trust_justice_system_employee": "pracownik wymiaru sprawiedliwości",
    "@source-of-information.social_trust_poz_doctor": "pracownicy POZ",
    "@source-of-information.social_trust_priest": "ksiądz",
    "@source-of-information.social_trust_policeman_or_city_guard": "policjant lub strażnik miejski",
    "@source-of-information.social_trust_psychologist": "psycholog",
    "@source-of-information.social_trust_psychiatrist_employee": "psychiatra",
    "@source-of-information.social_trust_oik_employee": "pracownik Ośrodka Interwencji Kryzysowej (OIK)",
    "@source-of-information.social_trust_pcpr_employee": "pracownik Powiatowego Centrum Pomocy Rodzinie (PCPR)",
    "@source-of-information.social_trust_dps_employee": "pracownik Domu Pomocy Społecznej (DPS)",
    "@source-of-information.social_trust_sds_employee": "pracownik Środowiskowego Domu Samopomocy (ŚDS)",
    "@source-of-information.social_trust_do_not_remember": "nie pamiętam",
    "@source-of-information.other": "inne",
    "@source-of-information.other_text_field": "inne",
    "@source-of-information.other_do_not_remember": "nie pamiętam",
    "@source-of-information.diplomatic_place": "placówka dyplomatyczna",
    "@source-of-information.diplomatic_place_embassy": "ambasada",
    "@source-of-information.diplomatic_place_consulate": "konsulat",
    "@source-of-information.diplomatic_place_do_not_remember": "nie pamiętam",
    "@therapeutic-scope.psycho_medical": "psychologiczne / medyczne",
    "@therapeutic-scope.social": "społeczne",
    "@therapeutic-scope.support_net": "sieci oparcia",
    "@therapeutic-realization.done": "Tak",
    "@therapeutic-realization.not_done": "Nie",
    "@therapeutic-realization.in_progress": "W trakcie",
    "@sex.male": "Mężczyzna",
    "@sex.female": "Kobieta",
    "@age.child": "Dziecko",
    "@age.adolescent": "Młodzież szkolna",
    "@age.adult": "Dorosły",
    "@age.hard_to_determine": "Trudno określić grupę wiekową",
    "@workplace.government_administration": "W administracji rządowej",
    "@workplace.local_government_administration": "W administracji samorządowej",
    "@workplace.small_enterprise": "W mikro/małym/średnim przedsiębiorstwie",
    "@workplace.large_enterprise": "W dużym przedsiębiorstwie",
    "@workplace.non_governmental_organization": "W organizacji pozarządowej",
    "@workplace.self_employed": "Prowadząca działalność na własny rachunek",
    "@workplace.others": "Inne miejsce pracy",
    "@profession.others": "Inny zawód",
    "@profession.practical_training_intructor": "Instruktor praktycznej nauki zawodu",
    "@profession.preschool_education_teacher": "Nauczyciel wychowania przedszkolnego",
    "@profession.vocational_education_teacher": "Nauczyciel kształcenia zawodowego",
    "@profession.health_protection_support_institution_employee": "Pracownik instytucji systemu wsparcia ochrony zdrowia",
    "@profession.social_assistance_and_integration_institution_key_employee": "Kluczowy pracownik instytucji pomocy i integracji społecznej",
    "@profession.higher_education_institution_employee": "Pracownik instytucji szkolnictwa wyższego",
    "@profession.family_support_and_foster_case_system_institution_employee": "Pracownik instytucji systemu wspierania rodziny i pieczy zastępczej",
    "@profession.social_economy_support_center_employee": "Pracownik ośrodka wsparcia ekonomii społecznej",
    "@profession.psychological_and_pedagogical_counselor": "Pracownik poradni psychologiczno-pedagogicznej",
    "@profession.farmer": "Rolnik",
    "@appointment-params.formOfCare": "Forma opieki",
    "@appointment-params.mode": "Tryb odpowiedzi na zamówienie pomocy",
    "@appointment-params.oda": "ODA",
    "@appointment-params.ipzStage": "Etap pracy z IPZ",
    "@appointment-params.contact": "Forma kontaktu",
    "@appointment-params.location": "Miejsce środowiskowe",
    "@dataUnificationStatus.pending": "Do poprawy",
    "@dataUnificationStatus.completed": "Poprawiony",
    "@dataUnificationStatus.waiting_for_patient": "Do poprawy z klientem",
    "@dataUnificationStatus.ex_patient": "Klient zakończył proces leczenia",
    "@treatmentTypeClassification.A": "usługa ambulatoryjna",
    "@treatmentTypeClassification.S": "usługa środowiskowa",
    "@treatmentTypeClassification.D": "usługa doraźna",
    "@treatmentTypeClassification.H": "usługi całodobowe nieszpitalne w hostelu dla młodzieży powyżej 16 r.ż. - 8 miejsc hostelowych",
    "@treatmentTypeClassification.OD": "usługa dzienna",
    "@role-in-process.psychiatrist": "Lekarz/Psychiatra",
    "@role-in-process.psychotherapist": "Psycholog/Psychoterapeuta",
    "@role-in-process.nurse": "Pielęgniarki",
    "@role-in-process.recovery_assistant": "Asystent zdrowienia",
    "@role-in-process.family_assistant": "Asystent rodziny",
    "@role-in-process.case_manager": "Case manager / Indywidualny doradca",
    "@role-in-process.occupational_therapist": "Terapeuta zajęciowy",
    "@role-in-process.environmental_therapist": "Terapeuta środowiskowy",
    "@role-in-process.social_worker": "Pracownik socjalny",
    "@role-in-process.mobile_team": "Zespół Mobilny",
    "@role-in-process.reception": "Rejestracja",
    "@role-in-process.career_counselor": "Doradca zawodowy",
    "@prescription-status-verified": "Zweryfikowana",
    "@prescription-status-created": "Utworzona",
    "@prescription-status-canceled": "Anulowana",
    "@prescription-status-completed": "Zrealizowana",
    "@prescription-status-queued": "W trakcie tworzenia",
    "@request-reports-record-status-completed": "Utworzony",
    "@request-reports-record-status-pending": "W trakcie tworzenia",
    "@request-reports-record-status-rejected": "Odrzucony",
    "@simplified-ipz-entry-type-appointments": "wizyty",
    "@simplified-ipz-entry-type-manual": "ręcznie",
    "@simplified-ipz-entry-type-ipz_closed": "Zamknięcie IPZ",
    "@simplified-ipz-entry-type-icf_core_set_defined": "Zdefiniowano Zestaw Kluczowy ICF",
    "@simplified-ipz-entry-type-ipz_target_created": "Utworzono Cel",
    "@simplified-ipz-entry-type-continuous_stay_created": "Utworzono Pobyt Ciągły",
    "@simplified-ipz-entry-type-survey_created": "Utworzono Badanie",
    "@simplified-ipz-entry-type-ipz_created": "Otwarcie IPZ",
    "@simplified-ipz-entry-type-patient_registered": "Rejestracja Pacjenta",
    "@simplified-ipz-targets-and-tasks": "cele",
    "@simplified-ipz-entry-part-category.interview": "wywiad",
    "@simplified-ipz-entry-part-category.examination": "badanie",
    "@simplified-ipz-entry-part-category.recommendations": "zalecenia",
    "@simplified-ipz-entry-part-category.manual": "ręcznie",
    "@ipz-target-status.planned": "Planowane",
    "@ipz-target-status.in_progress": "W trakcie",
    "@ipz-target-status.finished": "Zakończony",
    "@ipz-target-status.canceled": "Anulowany",
    "@establishment-type.establishment": "podmiot medyczny",
    "@establishment-type.non_medical_establishment": "podmiot niemedyczny",
    "@establishment-type.institute": "zakład leczniczy",
    "@establishment-type.division": "jednostka organizacyjna",
    "@establishment-type.unit": "komórka organizacyjna",
    "@adult-type.parent": "Rodzic/opiekun",
    "@adult-type.support_network": "Sieć oparcia",
    "@adult-type.specialist": "Specjalista",
    "@gap.registration-date": "Data rejestracji",
    "@gap.marital-status": "Stan cywilny",
    "@gap.disability-level": "Poziom niepełnosprawności",
    "@gap.source-of-information": "Źródło informacji o ŚCZP",
    "@gap.address-zip-code": "Kod pocztowy",
    "@gap.education": "Wykształcenie",
    "@gap.is-professionally-inactive-person": "Czy osoba jest bierna zawodowo",
    "@gap.does-work": "Czy osoba jest pracująca",
    "@gap.other-education-and-older-then-12": "Proszę popraw atrybut \"Wykształcenie\" - pacjent posiada więcej niż 12 lat oraz wykształcenie INNE",
    "@sick-leave-status-created": "Utworzone",
    "@sick-leave-status-canceled": "Anulowane",
    "@treatment-type-scope.all": "Wszystkie",
    "@treatment-type-scope.ambulatory": "Ambulatoryjne",
    "@treatment-type-scope.continuous-stay": "Dzienne",
    "@test-result-status.opened": "otwarty",
    "@test-result-status.finished": "zamknięty",
    "@test-result-status.draft": "otwarty",
    "@internal-referral-status.draft": "wersja robocza",
    "@internal-referral-status.acceptance": "oczekujące na akceptację",
    "@internal-referral-status.accepted": "zaakceptowane",
    "@internal-referral-status.canceled": "anulowane",
    "@internal-referral-status.finished": "zakończone",
    "@internal-referral-status.rejected": "odrzucone",
    "@internal-referral-direction.sent": "wysłane",
    "@internal-referral-direction.received": "otrzymane",
    "@age-group-target.adults": "dorośli",
    "@age-group-target.children": "dzieci",
    "@age-group-target.all": "wszystkie grupy wiekowe",
    "@bmi.severe_thinness": "wygłodzenie",
    "@bmi.moderate_thinness": "wychudzenie",
    "@bmi.mild_thinness": "niedowaga",
    "@bmi.normal": "wartość prawidłowa",
    "@bmi.pre_obese": "nadwaga",
    "@bmi.obese_class_i": "I stopień otyłości",
    "@bmi.obese_class_ii": "II stopień otyłości",
    "@bmi.obese_class_iii": "otyłość skrajna",
    "@requestIKUPTreatmentCategory.all": "Wszystkie",
    "@requestIKUPTreatmentCategory.daily": "Usługi dzienne",
    "@requestIKUPTreatmentCategory.ambulatory_and_environments_and_adhoc": "Usługi Ambulatoryjne / Środowiskowe / Doraźne",
    "@referral-type.hospital": "Skierowanie do szpitala",
    "@referral-type.clinic": "Skierowanie do poradni specjalistycznej",
    "@referral-type.diagnostics": "Skierowanie do pracowni diagnostycznej",
    "@referral-status.created": "Zweryfikowane",
    "@referral-status.completed": "Wystawione",
    "@referral-status.rejected": "Odrzucone",
    "@referral-status.canceled": "Anulowane",
    "@request-status.created": "Utworzony",
    "@request-status.accepted": "Zaakceptowany",
    "@request-status.canceled": "Odrzucony",
    "@request-type.CancelAppointmentCommand": "Wniosek o odwołanie wizyty",
    "@hospitalization-type.unknown": "nieznana",
    "@hospitalization-type.psychiatric": "psychiatryczna",
    "@hospitalization-type.full_day": "całodobowa",
    "@hospitalization-type.daily": "dzienna",
    "@survey-status.created": "utworzone",
    "@survey-status.invited": "wysłano zaproszenie",
    "@survey-status.opened": "otwarte",
    "@survey-status.closed": "zamknięte",
    "@internal-referral-direction.referring": "strona kierująca",
    "@internal-referral-direction.receiving": "strona odbierająca",
    "@patient-form-of-care.active_care": "czynna",
    "@patient-form-of-care.long_term_environmental": "długoterminowa środowiskowa",
    "@patient-form-of-care.long_term_ambulatory": "długoterminowa ambulatoryjna",
    "@patient-form-of-care.short_term": "krótkoterminowa",
    "@anonymous-service-information.scopeOfActivitiesAndHealthcareInformation": "Udzielanie informacji o zakresie działania centrum i możliwości uzyskania świadczeń opieki zdrowotnej",
    "@anonymous-service-information.benefitsOfSocialAssistance": "Wskazanie miejsca uzyskania niezbędnego świadczenia z zakresu pomocy społecznej",
    "@anonymous-service-information.initialAssessmentHealthNeedsAndProvidingSupport": "Wstępna ocena potrzeb zdrowotnych i udzielenie wsparcia oraz uzgodnienie wstępnego planu dalszego postępowania",
    "@anonymous-service-information.agreeingDateAndPlaceCZPServices": "Uzgodnienie terminu i miejsca uzyskania świadczeń w CZP",
    "@anonymous-service-information.providingHealthServices": "Udzielenie świadczeń zdrowotnych",
    "@patient-form-of-care.consultative": "konsultacyjna",
    "@nfz-worker-type.specialist_in_psychiatry": "lekarz specjalista w dziedzinie psychiatrii",
    "@nfz-worker-type.incomplete_specialist_in_psychiatry": "lekarz, który posiada specjalizację I stopnia lub w trakcie specjalizacji w dziedzinie psychiatrii",
    "@nfz-worker-type.specialist_in_clinical_psychology": "specjalista w dziedzinie psychologii klinicznej",
    "@nfz-worker-type.specialist_in_psychotherapy_of_children_and_adolescents": "specjalista w dziedzinie psychoterapii dzieci i młodzieży",
    "@nfz-worker-type.psychologist": "psycholog",
    "@nfz-worker-type.nurse": "pielęgniarka/pielęgniarz",
    "@nfz-worker-type.environmental_therapist": "terapeuta środowiskowy",
    "@nfz-worker-type.occupational_therapist": "terapeuta zajęciowy",
    "@nfz-worker-type.psychotherapist": "psychoterapeuta",
    "@nfz-worker-type.recovery_assistant": "asystent zdrowienia",
    "@nfz-worker-type.career_counselor": "doradca zawodowy",
    "@nfz-worker-type.addiction_therapist": "terapeuta uzależnień",
    "@nfz-worker-type.supervisor": "superwizor",
    "@nfz-worker-type.clinical_psychologist_or_in_the_course_of_specialization": "psycholog kliniczny lub w trakcie specjalizacji",
    "@nfz-worker-type.speech_therapist": "logopeda",
    "@nfz-worker-type.educator": "pedagog",
    "@nfz-worker-type.special_educator": "pedagog specjalny",
    "@nfz-worker-type.physiotherapist": "fizjoterapeuta",
    "@nfz-worker-type.social_worker": "pracownik socjalny",
    "@nfz-worker-type.dietician": "dietetyk",
    "@nfz-alternative-treatment.first_contact_visit": "wizyta pierwszego kontaktu",
    "@nfz-alternative-treatment.recovery_assistant_support": "wsparcie asystenta zdrowienia",
    "@nfz-alternative-treatment.support_of_career_counselor": "wsparcie doradcy zawodowego",
    "@nfz-alternative-treatment.participation_in_support_group": "udział w grupie wsparcia",
    "@nfz-alternative-treatment.coordination_activities_within_PTiZ": "działania koordynacyjne w ramach PTiZ",
    "@nfz-alternative-treatment.nursing_service": "usługa pielęgniarska",
    "@nfz-alternative-treatment.advice_from_community_therapist": "porada terapeuty środowiskowego",
    "@nfz-alternative-treatment.participation_in_provision_of_service": "współuczestniczenie w realizacji usługi",
    "@nfz-alternative-treatment.psychological_support": "wsparcie psychologiczne",
    "@nfz-alternative-treatment.treatments_and_injections": "zabiegi i iniekcje",
    "@type-of-benefit.branch": "oddział",
    "@type-of-benefit.zls": "zlś",
    "@type-of-benefit.psychotherapy": "psychoterapia",
    "@type-of-benefit.psychologist": "psycholog",
    "@waiting-list-status.opened": "aktualne",
    "@waiting-list-status.finished": "wykreślone",
    "@daily-description-worker-category.doctor": "lekarz",
    "@daily-description-worker-category.psychologist_or_psychotherapist": "psycholog / psychoterapeuta",
    "@daily-description-worker-category.occupational_therapist": "terapeuta zajęciowy",
    "@daily-description-worker-category.nurse": "pielęgniarka",
    "@daily-description-worker-category.other_specialist": "inny specjalista",
    "@application-form-category.personal": "Zgłoszenie osobiste",
    "@application-form-category.telephone": "Zgłoszenie telefoniczne",
    "@application-form-category.email": "Zgłoszenie e-mail",
    "@application-form-caseType.sudden": "Przypadek nagły",
    "@application-form-caseType.urgent": "Przypadek pilny",
    "@application-form-caseType.stable": "Przypadek stabilny",
    "@patient_attachment_category.therapeutic_and_recovery_plan_snapshot": "Wersja planu terapetucznego i zdroweinia",
    "@nfz-communication.pending": "oczekujący",
    "@nfz-communication.sent": "wysłany",
    "@nfz-communication.received": "odebrany",
    "Valid referral not found.": "Nie znaleziono aktualnego skierowania.",
    "Patient has same treatment in current day.": "Pacjent miał już wykonane takie same świadczenie w tym samym dniu.",
    "Patient dont have insurance.": "Pacjent nie ma potwierdzonego ubezpieczenia",
    "The duration of the visit incompatible with the selected service.": "Czas trwania wizyty niezgody z wymaganym czasem usługi.",
    "Chosen admission mode require additional entitlements.": "Wybrany tryb przyjęcia wymaga dokumentu potwierdzającego uprawnienia dodatkowe.",
    "Date of the appointment is not included in the document date range.": "Data wizyty nie wchodzi w zakres dat dokumentu.",
    "In case of uploaded new pkcs12wss certificate you need to provide password.": "W przypadku wgrywania nowego certyfikatu WSS musisz podać hasło.",
    "@treatment-status.active": "Aktywne",
    "@treatment-status.finished": "Zakończone",
    "@treatment-status.lack_of_information": "Brak informacji",
    "@statistic_data_status.rejected_by_nfz": "Z błędam",
    "@statistic_data_status.to_send": "Do wysłania",
    "@statistic_data_status.sent": "Wysłane",
    "@statistic_data_status.dont_report": "Nie raportuj",
    "@statistic_data_status.non_accountable": "Nierozliczone",
    "@paper_referral_type.healing_entity": "podmiot leczniczy",
    "@paper_referral_type.professional_practice": "praktyka zawodowa",
    "@paper_referral_type.businessman": "osoba fizyczna wykonująca działalność gospodarczą",
    "@paper_referral_type.institution": "instytucja",
    "@paper_referral_number_type.regon": "REGON",
    "@paper_referral_number_type.bookNumber": "nr księgi rejestrowej rejestru podmiotów wykonujących działalność leczniczą",
    "Regon or book number is required": "Wymagany jest regon albo numer nr księgi rejestrowej"
};