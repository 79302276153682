<template>
  <multiselect
    v-tabindex
    :internal-search="false"
    :value="stickyPatient"
    :loading="loading || isLoading"
    :show-labels="false"
    :searchable="true"
    :options="options"
    :reset-after="true"
    :options-limit="15"
    :placeholder="placeholder"
    :custom-label="customLabel"
    label="displayName"
    track-by="patientId"
    @search-change="onSearchInputChange"
    @select="onPatientSelect"
    @open="onDropdownClose"
    @remove="onPatientSelect"
  >
    <template #noResult>
      Nie znaleziono pacjenta.
    </template>
    <template #singleLabel="props">
      <span
        class="btn p-0 mr-2"
        style="white-space: normal"
        @mousedown="goToPatientCard(props.option.patientId)"
      >
        {{ props.option.displayName }}
      </span>
    </template>
    <template #option="props">
      <div class="d-flex flex-row justify-content-between align-items-center">
        <span>
          {{ props.option.displayName }}
        </span>
        <div>
          <is-granted
            v-if="nfzConfiguration"
            :privileges="['PATIENT_IPZ_LIST']"
            :subject="props.option.patientId"
            component="fragment"
          >
            <router-link
              :to="{name: 'patientCard', params: {patientId: props.option.patientId}, hash: '#patientCardIpz'}"
              class="btn btn-danger btn-sm m-1"
            >
              PTiZ
            </router-link>
          </is-granted>
          <router-link
            :to="{name: 'patientCard', params: {patientId: props.option.patientId}, hash: '#appointmentTabsCard'}"
            class="btn btn-primary btn-sm m-1"
          >
            Umów wizytę
          </router-link>
          <router-link
            :to="{name: 'patientCard', params: {patientId: props.option.patientId}, hash: '#patientAttachmentsCard'}"
            class="btn btn-warning btn-sm m-1"
          >
            Załącz dokument
          </router-link>
          <router-link
            :to="{name: 'patientCard', params: {patientId: props.option.patientId}, hash: '#patientCardTop'}"
            class="btn btn-secondary btn-sm m-1"
          >
            Karta pacjenta
          </router-link>
        </div>
      </div>
    </template>
    <template #noOptions>
      <span />
    </template>
    <template #caret="props">
      <div
        v-if="!stickyPatient"
        class="multiselect__select"
        @click="props.toggle"
      />
      <div
        v-else
        class="clear"
        @mousedown="onPatientRemove"
      >
        <i class="fa fa-times" />
      </div>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";
import read from "../../rest/read";
import {mapState} from "vuex";
import IsGranted from "../../components/IsGranted"

export default {
  name: "PatientSearch",
  components: {
    multiselect: Multiselect,
    IsGranted,
  },
  directives: {
    tabindex: {
      inserted(el) {
        el.setAttribute("tabindex", 0);
      },
    },
  },
  props: {
    loading: {type: Boolean, default: false},
    stickyPatient: {type: Object, default: null},
  },
  data() {
    return {
      typingTimeout: null,
      isLoading: false,
      options: []
    };
  },
  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration,
      clinicParameters: state => state.clinicParameters.parameters,
    }),
    placeholder() {
      if (!this.clinicParameters) {
        return "Wyszukaj pacjenta";
      }
      return this.clinicParameters.patientAnonymous
        ? "Numer Teczki / Numer Zewnętrzny pacjenta"
        : "Nazwisko, PESEL lub Numer Teczki pacjenta";
    },
  },
  methods: {
    async onSearchInputChange(phrase) {
      this.options = [];
      clearTimeout(this.typingTimeout);
      if ("" === phrase) {
        return;
      }

      this.typingTimeout = setTimeout(async () => {
        this.isLoading = true;
        const {items} = await read("/api/patients", {phrase});
        this.options = items.map(item => ({...item, displayName: this.customLabel(item)}));
        this.isLoading = false;
      }, 1000);
    },
    onPatientSelect(selectedOption) {
      this.$emit("change", selectedOption);
    },
    onPatientRemove() {
      this.$emit("patientRemove");
    },
    onDropdownClose() {
      this.options = [];
    },
    goToPatientCard(patientId) {
      this.$router.push({name: "patientCard", params: {patientId}});
    },
    customLabel({displayName, fileNumber}) {
      return (!this.clinicParameters.patientAnonymous && fileNumber)
        ? `${displayName} | ${fileNumber}`
        : displayName;
    },
  },
};
</script>

<style scoped lang="scss">
  @import "~vue-multiselect/dist/vue-multiselect.min.css";
  .clear {
    position: absolute;
    right: 0;
    cursor: pointer;
    padding: 0.625rem 15px;
  }
  .custom-router-link {
    color: #fff;
  }
</style>
