<template>
  <div class="card-box">
    <form>
      <h3>Dodawanie zakresu</h3>
      <div class="d-flex flex-wrap">
        <b-form-group
          label="Zakres od:"
          class="mr-sm-5"
        >
          <div class="d-flex flex-wrap flex-md-nowrap">
            <date-picker
              v-model="rangeStartDate"
              class="select"
              data-test="range-start-date"
              placeholder="Data"
            />
            <hour-select
              v-model="rangeStartTime"
              class="select"
              data-test="range-start-time"
              placeholder="Godzina"
            />
          </div>
        </b-form-group>
        <b-form-group
          label="Zakres do:"
        >
          <div class="d-flex flex-wrap flex-md-nowrap">
            <date-picker
              v-model="rangeEndDate"
              class="select"
              data-test="range-end-date"
              placeholder="Data"
            />
            <hour-select
              v-model="rangeEndTime"
              class="select"
              data-test="range-end-time"
              placeholder="Godzina"
            />
          </div>
        </b-form-group>
      </div>

      <div class="form-group">
        <div class="form-check">
          <input
            id="all-day"
            v-model="isRecurring"
            class="form-check-input"
            data-test="all-day"
            type="checkbox"
          >
          <label
            for="all-day"
            class="form-check-label"
          >
            Powtarzalny
          </label>
        </div>
      </div>

      <transition name="fade">
        <div
          v-if="isRecurring"
          class="d-flex flex-wrap"
        >
          <b-form-group
            label="Co jaki czas zakres ma sie powtarzać:"
            label-for="interval"
            class="mr-md-5"
          >
            <interval-duration-select
              id="interval"
              v-model="intervalDays"
              class="select"
              data-test="recurring-interval"
            />
          </b-form-group>

          <b-form-group
            label="Data zakończenia:"
            label-for="date-end"
          >
            <date-picker
              id="date-end"
              v-model="dateEnd"
              class="w-auto"
              data-test="recurring-date-end"
            />
          </b-form-group>
        </div>
      </transition>

      <b-form-group
        label="Komórka organizacyjna:"
      >
        <branch-select
          id="branch"
          v-model="selectedBranch"
          data-test="selected-branch"
        />
      </b-form-group>

      <b-form-group label="Typ personelu">
        <nfz-worker-type-select
          v-model="workerType"
          :worker-id="workerId"
        />
      </b-form-group>

      <transition name="fade">
        <div
          v-if="errorSubmitting"
          class="alert alert-danger alert-dismissible"
        >
          <button
            type="button"
            class="close"
            @click="errorSubmitting = false"
          >
            <span>&times;</span>
          </button>
          {{ errorMessage }}
        </div>
      </transition>

      <div
        v-if="successSubmitting"
        class="alert alert-success alert-dismissible"
      >
        <button
          type="button"
          class="close"
          @click="successSubmitting = false"
        >
          <span>&times;</span>
        </button>
        Zapisano zmiany
      </div>

      <div class="d-flex justify-content-end flex-wrap">
        <button
          class="btn btn-secondary"
          data-test="go-back"
          @click.prevent="goBackToList"
        >
          Powrót
        </button>
        <button
          :disabled="submittingInProgress"
          class="btn btn-primary m-l-15"
          data-test="submit"
          @click.prevent="submit"
        >
          <i
            :class="submittingInProgress ? 'fa-spinner fa-spin' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import stringifyDate from "../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import create from "../../../rest/create";
import processResponseException from "../../../utils/errors/processResponseException";
import DatePicker from "../../Form/DatePicker/DatePicker";
import HourSelect from "../../Form/Select/HourSelect";
import BranchSelect from "../../Branch/BranchSelect";
import IntervalDurationSelect from "../../Form/Select/IntervalDurationSelect";
import NfzWorkerTypeSelect from "@/components/Form/Select/NfzWorkerTypeSelect";

export default {
  name: "WorkerWorkingHoursForm",
  components: {
    IntervalDurationSelect,
    BranchSelect,
    HourSelect,
    DatePicker,
    NfzWorkerTypeSelect,
  },
  props: {
    workerId: {type: String, required: true},
  },
  data() {
    return {
      selectedBranch: null,
      workerType: null,
      rangeStartDate: null,
      rangeStartTime: null,
      rangeEndDate: null,
      rangeEndTime: null,
      intervalDays: null,
      dateEnd: null,
      isRecurring: false,
      errorSubmitting: false,
      errorMessage: "",
      successSubmitting: false,
      submittingInProgress: false,
    };
  },
  computed: {
    rangeStart() {
      if (this.rangeStartDate && this.rangeStartTime) {
        const startDate = stringifyDate(this.rangeStartDate, DATE_FORMAT.DATE);
        return `${startDate} ${this.rangeStartTime.HH}:${this.rangeStartTime.mm}`;
      }
      return null;
    },
    rangeEnd() {
      if (this.rangeEndDate && this.rangeEndTime) {
        const endDate = stringifyDate(this.rangeEndDate, DATE_FORMAT.DATE);
        return `${endDate} ${this.rangeEndTime.HH}:${this.rangeEndTime.mm}`;
      }
      return null;
    },
  },
  methods: {
    async submit() {
      if (!(this.rangeStart || this.rangeEnd)) {
        this.errorSubmitting = true;
        this.errorMessage = "Wybierz zakres dat";
        return;
      }
      if (!this.selectedBranch) {
        this.errorSubmitting = true;
        this.errorMessage = "Wybierz jednostkę";
        return;
      }
      if (!this.workerType) {
        this.errorSubmitting = true;
        this.errorMessage = "Wybierz typ personelu";
        return;
      }
      if (this.isRecurring === false) {
        this.intervalDays = null;
      }
      this.submittingInProgress = true;
      try {
        await create(`/api/workers/${this.workerId}/working-hours`, {
          rangeStart: this.rangeStart,
          rangeEnd: this.rangeEnd,
          rangeIntervalDays: this.intervalDays,
          dateEnd: this.dateEnd !== null ? stringifyDate(this.dateEnd, DATE_FORMAT.DATE) : null,
          branchId: this.selectedBranch,
          nfzWorkerType: this.workerType,
        });
        this.successSubmitting = true;
        this.errorSubmitting = false;
        this.submittingInProgress = false;
        this.$emit("rangeSubmitted");
      } catch(exception) {
        this.errorSubmitting = true;
        this.successSubmitting = false;
        this.submittingInProgress = false;
        const errors = processResponseException(exception);
        this.errorMessage = errors.map(error => error.message).join();
      }
    },
    goBackToList() {
      this.$router.push({name: "listWorkingHoursOfWorker"});
    }
  },
}
</script>

<style scoped lang="scss">
  .select {
    width: 228px!important;
    max-width: 100%;
  }
</style>
