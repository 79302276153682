<template>
  <div class="card-box">
    <h4>Dane osobowe</h4>
    <hr>
    <div class="row justify-content-around">
      <div class="col-md-6 col-xl-5">
        <b-form-group
          label="Imię"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model.trim="personalDataForm.name"
            :state="state('name')"
            type="text"
            required
            placeholder="Wprowadź imię"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="name"
          />
        </b-form-group>
        <b-form-group
          label="Nazwisko"
          label-for="surname"
        >
          <b-form-input
            id="surname"
            v-model.trim="personalDataForm.surname"
            :state="state('surname')"
            type="text"
            required
            placeholder="Wprowadź nazwisko"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="surname"
          />
        </b-form-group>
        <b-form-group
          v-if="!nfzConfiguration"
          label="Tytuł naukowy"
          label-for="academicDegreeTitle"
        >
          <b-form-input
            id="academicDegreeTitle"
            v-model.trim="personalDataForm.academicDegreeTitle"
            :state="state('academicDegreeTitle')"
            required
            placeholder="Wprowadź tytuł naukowy"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="academicDegreeTitle"
          />
        </b-form-group>
        <b-form-group
          label="Tytuł zawodowy"
          label-for="professionalTitle"
        >
          <b-form-input
            id="professionalTitle"
            v-model.trim="personalDataForm.professionalTitle"
            :state="state('professionalTitle')"
            placeholder="Wprowadź tytuł zawodowy"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="professionalTitle"
          />
        </b-form-group>
        <b-form-group
          v-if="!nfzConfiguration"
          label="Specjalizacje lekarskie i lekarsko-dentystyczne"
          label-for="specializations"
        >
          <worker-medical-specializations-select
            v-model="personalDataForm.medicalSpecializations"
            :state="state('medicalSpecializations')"
            @input="updateModel"
          />
          <p v-if="personalDataForm.specializations">
            Poprzednio wybrana specjalizacja: {{ personalDataForm.specializations }}
          </p>
          <error-message
            :errors="errors"
            field="medicalSpecializations"
          />
        </b-form-group>
        <b-form-group
          v-if="!nfzConfiguration"
          label="Zawód"
          label-for="mainProfession"
        >
          <main-profession-select
            id="mainProfession"
            v-model="personalDataForm.mainProfession"
            :state="state('mainProfession')"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="mainProfession"
          />
        </b-form-group>
        <b-form-group
          v-if="!nfzConfiguration"
          label="Zawód dodatkowy"
          label-for="additionalProfessions"
        >
          <additional-profession-select
            id="additionalProfessions"
            v-model="personalDataForm.additionalProfessions"
            :state="state('additionalProfessions')"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="additionalProfessions"
          />
        </b-form-group>
        <b-form-group
          label="Rola w systemie"
          label-for="roleType"
        >
          <role-type-select
            id="roleType"
            v-model="personalDataForm.role"
            :state="state('role')"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="role"
          />
        </b-form-group>
        <b-form-group
          label="Rola w procesie zdrowienia"
          label-for="roleInProcess"
        >
          <role-in-process-type-select
            id="roleInProcess"
            v-model="personalDataForm.roleInProcess"
            :state="state('roleInProcess')"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="roleInProcess"
          />
        </b-form-group>

        <b-form-group
          label="Typ personelu"
          label-for="nfzWorkerType"
        >
          <nfz-worker-type-select
            id="nfzWorkerType"
            v-model="personalDataForm.nfzWorkerType"
            :state="state('nfzWorkerType')"
            multiple
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="nfzWorkerType"
          />
        </b-form-group>

        <div class="m-t-50">
          <b-form-checkbox
            v-if="edit === true"
            id="permissionsCheckbox"
            v-model="personalDataForm.accountInactive"
            @input="updateModel"
          >
            Konto nieaktywne
          </b-form-checkbox>
        </div>
      </div>

      <div class="col-md-6 col-xl-5">
        <b-form-group
          label="Numer PESEL"
          label-for="pesel"
        >
          <b-form-input
            id="pesel"
            v-model.trim="personalDataForm.pesel"
            :state="state('pesel')"
            required
            placeholder="Wprowadź PESEL"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="pesel"
          />
        </b-form-group>

        <b-form-group
          label="Telefon prywatny"
          label-for="phoneNumber"
        >
          <b-form-input
            id="phoneNumber"
            v-model.trim="personalDataForm.phoneNumber"
            :state="state('phoneNumber')"
            placeholder="Wprowadź numer telefonu"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="phoneNumber"
          />
        </b-form-group>

        <b-form-group
          label="Numer służbowy"
          label-for="businessPhoneNumber"
        >
          <b-form-input
            id="businessPhoneNumber"
            v-model.trim="personalDataForm.businessPhoneNumber"
            :state="state('businessPhoneNumber')"
            placeholder="Wprowadź numer służbowy telefonu"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="businessPhoneNumber"
          />
        </b-form-group>

        <b-form-group
          label="Adres e-mail"
          label-for="personalEmail"
        >
          <b-form-input
            id="personalEmail"
            v-model.trim="personalDataForm.personalEmail"
            :state="state('personalEmail')"
            required
            placeholder="Wprowadź adres e-mail"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="personalEmail"
          />
        </b-form-group>
        <b-form-group
          label="Domyślna komórka organizacyjna"
          label-for="branchId"
        >
          <branch-select
            v-model="personalDataForm.branchId"
            :check-if-any-branch-exists="true"
            :state="state('branchId')"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="branchId"
          />
        </b-form-group>
        <b-form-group
          label="Typ uprawnienia wykonywania zawodu"
          label-for="professionType"
        >
          <profession-type-select
            id="professionType"
            v-model="personalDataForm.professionType"
            :state="state('professionType')"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="professionType"
          />
        </b-form-group>
        <b-form-group
          label="Numer prawa wykonywania zawodu"
          label-for="professionNumber"
        >
          <b-form-input
            id="professionNumber"
            v-model.trim="personalDataForm.professionNumber"
            :state="state('professionNumber')"
            type="text"
            required
            placeholder="Wprowadź numer prawa wykonywania zawodu"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="professionNumber"
          />
        </b-form-group>

        <b-form-group
          v-if="!nfzConfiguration"
          label="Jednostki współpracujące"
          label-for="cooperatingUnitId"
        >
          <cooperating-units-select
            id="cooperatingUnitId"
            v-model="personalDataForm.cooperatingUnitId"
            :state="state('cooperatingUnitId')"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="cooperatingUnitId"
          />
        </b-form-group>
        <b-form-group
          v-if="isUserCanBeCoordinator"
        >
          <b-form-checkbox
            v-model="personalDataForm.teamCoordinator"
            :state="state('teamCoordinator')"
          >
            Specjalista jest koordynatorem zespołu
          </b-form-checkbox>
          <error-message
            :errors="errors"
            field="teamCoordinator"
          />
        </b-form-group>
        <b-form-group
          label="Koordynator"
          label-for="coordinator"
        >
          <worker-select
            id="coordinator"
            v-model="personalDataForm.coordinator"
            :exclude-worker-ids="[personalDataForm.workerId]"
            is-coordinator
            :state="state('coordinator')"
          />
          <error-message
            :errors="errors"
            field="coordinator"
          />
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import MainProfessionSelect from "../Form/Select/MainProfessionSelect";
import AdditionalProfessionSelect from "../Form/Select/AdditionalProfesssionSelect";
import ErrorMessage from "../Form/ErrorMessage";
import ProfessionTypeSelect from "../Form/Select/ProfessionTypeSelect";
import RoleTypeSelect from "../Form/Select/RoleTypeSelect";
import BranchSelect from "../Branch/BranchSelect";
import RoleInProcessTypeSelect from "../Form/Select/RoleInProcessTypeSelect";
import CooperatingUnitsSelect from "../Form/Select/CooperatingUnitsSelect";
import WorkerSelect from "./WorkerSelect";
import WorkerMedicalSpecializationsSelect from "./WorkerMedicalSpecializationsSelect";
import {coordinatorRoles} from "../../utils/roles/specialistRoles";
import {mapState} from "vuex";
import NfzWorkerTypeSelect from "../Form/Select/NfzWorkerTypeSelect";

export default {
  components: {
    NfzWorkerTypeSelect,
    WorkerMedicalSpecializationsSelect,
    WorkerSelect,
    CooperatingUnitsSelect,
    BranchSelect,
    ProfessionTypeSelect,
    ErrorMessage,
    MainProfessionSelect,
    AdditionalProfessionSelect,
    RoleTypeSelect,
    RoleInProcessTypeSelect,
  },
  props: {
    edit: {type: Boolean, default: false},
    value: {type: Object, required: true},
    errors: {type: Array, default: () => []},
  },
  data() {
    const personalDataForm = {
      additionalProfessions: [],
      roleInProcess: [],
      medicalSpecializations: [],
      nfzWorkerType: [],
      accountInactive: false,
      teamCoordinator: false,
      coordinator: null
    };
    return {
      personalDataForm,
      coordinatorRoles,
    };
  },
  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration
    }),
    isUserCanBeCoordinator() {
      if(!this.value.role) {
        return false;
      }
      return typeof this.value.role === "string"
        ? this.coordinatorRoles.includes(this.value.role)
        : this.coordinatorRoles.includes(this.value.role.value)
    }
  },
  watch: {
    value(data) {
      this.personalDataForm = data;
    }
  },
  methods: {
    updateModel() {
      const model = this.personalDataForm;
      model.pesel = this.personalDataForm.pesel || null;

      this.$emit("input", model);
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
  },
}
</script>
