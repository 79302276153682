import type {Patient, PatientContinuousStay} from "../store/modules/appointmentListItem/types";

export interface Address {
  country: string;
  zipCode: string;
  city: string;
  street: string;
}

export interface Branch {
  branchId: string;
  name: string;
  address: Address;
}

export interface Worker {
  workerId: string;
  name: string;
  treatmentTypeId: string;
  isTreatmentTypeUndefined: boolean;
}

export interface Specialist extends Worker {
  nfzWorkerType: string
}

export enum AppointmentLocationType {
  REMOTE ="remote",
  LOCAL = "local",
  PHONE = "phone"
}

export interface AppointmentLocation {
  isLocal: boolean
  address: Address;
  type: AppointmentLocationType;
}

export enum AppointmentStatusType {
  CREATED = "created",
  CHECKED_IN = "checkedin",
  OPENED = "opened",
  FINISHED = "finished",
  CANCELED = "canceled",
}

export interface AppointmentItem {
  additionalSpecialists: Worker[],
  appointmentId: string;
  branch: Branch;
  cancelComment: string|null;
  cancelReason: string|null;
  continuousStay: boolean;
  date: string; //YYYY-MM-DDTHH:mm:ss
  duration: number,
  earlierPossibility: boolean|null;
  location: AppointmentLocation;
  mainSpecialist: Worker;
  patientContinuousStays: PatientContinuousStay[];
  patientPresence: boolean
  patients: Patient[];
  plan: string;
  status: AppointmentStatusType;
  registrationNotes?: string;
  nfzWorkerTypes: Array<{ workerId: string, nfzWorkerType: string }>
}

// https://docs.google.com/spreadsheets/d/1F3zkw3X2Dv2cTm2XjoEFx4kCtEtr_NWc21L_QVddJSk/edit#gid=0
// kod świadczenia
export enum AppointmentTreatmentBenefitCode {
  DAILY = "11",
  ADVICE = "4",
  VISIT = "6",
  SESSION = "10",
}
