<template>
  <div>
    <card
      :loading="loading"
      title="Osoba dorosła"
    >
      <template #control>
        <is-granted
          :privileges="['CHANGE_PATIENT_ADULT_TYPE']"
        >
          <b-btn
            v-b-modal.changeAdultStatus
            :disabled="loading"
            variant="primary"
            class="btn-sm"
          >
            <i class="fa fa-pencil-alt" />
          </b-btn>
        </is-granted>
      </template>

      <p>{{ getLabel(adultType) }}</p>
    </card>

    <b-modal
      id="changeAdultStatus"
      no-close-on-backdrop
      title="Osoba dorosła"
      title-tag="h3"
    >
      <template #default>
        <b-form-group
          label="Osoba dorosła"
          label-for="adult-type"
        >
          <adult-type-select
            v-model="adultTypeForm"
          />
          <error-message
            :errors="errors"
            field="adult-type"
          />
        </b-form-group>
      </template>

      <template #modal-footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="cancel()"
        >
          Anuluj
        </button>
        <button
          :disabled="loading"
          class="btn btn-primary"
          @click="submit"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Card from "../../Card.vue";
import AdultTypeSelect from "../../Form/Select/AdultTypeSelect.vue";
import IsGranted from "../../IsGranted.vue";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import read from "../../../rest/read";
import processResponseException from "../../../utils/errors/processResponseException";
import update from "../../../rest/update";
import t from "@/i18n";

export default {
  name: "PatientCardAdultType",
  components: {
    Card,
    AdultTypeSelect,
    IsGranted,
    ErrorMessage
  },
  props: {
    patientId: {type: String, required: true}
  },
  data() {
    return {
      loading: false,
      adultType: null,
      adultTypeForm: null
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        const response = await read(`/api/patients/${this.patientId}/adult-type`)
        this.adultType = response.adultType;
        this.adultTypeForm = response.adultType
      } catch (exception) {
        this.errors = processResponseException(exception)
      }
      this.loading = false
    },
    cancel() {
      this.errors = [];
      this.adultTypeForm = null;
      this.$bvModal.hide("changeAdultStatus")
    },
    getLabel(status) {
      return status ? t(`@adult-type.${status}`) : "Brak"
    },
    async submit() {
      this.loading = true;
      try {
        const data = {
          adultType: this.adultTypeForm
        }

        await update(`/api/patients/${this.patientId}/adult-type`, data)
        await this.loadData();
        this.errors = [];
        this.$bvModal.hide("changeAdultStatus")
      } catch (exception) {
        this.errors = processResponseException(exception)
      }
      this.loading = false
    }
  }
}
</script>
