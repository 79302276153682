<template>
  <div>
    <div class="card-box">
      <div class="form-row">
        <div
          class="form-group col"
          :class="readOnly ? 'h4' : 'font-weight-bold'"
        >
          Wynik diagnozy psychologicznej
        </div>

        <b-btn
          v-if="!readOnly"
          v-b-modal.add-psychological-diagnosis-result
          class="btn btn-primary btn-sm float-right m-b-10"
        >
          <i class="fa fa-plus" />
          Dodaj problem w opiece psychologicznej
        </b-btn>
      </div>

      <div
        v-if="module.diagnosisResults.length !== 0"
        class="table-responsive"
      >
        <table class="table">
          <thead>
            <tr>
              <th>
                Data diagnozy
              </th>

              <th class="w-xs">
                Kod kategorii problemu
              </th>

              <th class="w-xs">
                Nazwa kategorii problemu
              </th>

              <th>
                Notatka
              </th>

              <th v-if="!readOnly" />
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(item, index) in module.diagnosisResults"
              :key="index"
            >
              <td>{{ item.diagnosisDate }}</td>

              <td>{{ item.problemCategory }}</td>

              <td>{{ getPsychologicalCareProblemCategoryLabel(item.problemCategory) }}</td>

              <td>{{ item.note }}</td>

              <td
                v-if="!readOnly"
                class="text-right"
              >
                <b-btn
                  size="sm"
                  variant="outline-secondary"
                  class="d-flex align-items-center"
                  @click="onClickRemove(item)"
                >
                  <i class="fa fa-trash mr-1" /> Usuń
                </b-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <add-psychological-diagnosis-results
      :patient-id="patientId"
      @psychologicalDiagnosisResultCreated="addPsychologicalDiagnosis"
    />
  </div>
</template>

<script>
import AddPsychologicalDiagnosisResults from "@/components/PsychologicalDiagnosis/AddPsychologicalDiagnosisResults";
import {
  getPsychologicalCareProblemCategoryLabel,
} from "./PsychologicalCareProblemCategory/PsychologicalCareProblemCategories";

export default {
  name: "PsychologicalDiagnosisResultModule",
  components: {
    AddPsychologicalDiagnosisResults
  },
  model: {
    prop: "module",
  },
  props: {
    module: {required: true, type: Object},
    readOnly: {required: true, type: Boolean},
    patientId: {type: String, required: true},
  },
  methods: {
    getPsychologicalCareProblemCategoryLabel,
    onClickRemove(itemToRemove){
      const module = {
        ...this.module,
        diagnosisResults: this.module.diagnosisResults.filter(item => item.problemCategory !== itemToRemove.problemCategory)
      };
      this.$emit("input", module);
    },
    addPsychologicalDiagnosis(item) {
      const module = {
        ...this.module,
        diagnosisResults: this.module.diagnosisResults.concat([item]),
      };
      this.$emit("input", module);
    },
  },
}
</script>
