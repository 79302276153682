import { render, staticRenderFns } from "./EstablishmentUnitTreatmentTypesWeightsTable.vue?vue&type=template&id=236b6f24&scoped=true&"
import script from "./EstablishmentUnitTreatmentTypesWeightsTable.vue?vue&type=script&lang=ts&"
export * from "./EstablishmentUnitTreatmentTypesWeightsTable.vue?vue&type=script&lang=ts&"
import style0 from "./EstablishmentUnitTreatmentTypesWeightsTable.vue?vue&type=style&index=0&id=236b6f24&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "236b6f24",
  null
  
)

export default component.exports