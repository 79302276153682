export type PsychologicalCareProblemCategoryOption = {
  name: string,
  value: PsychologicalCareProblemCategoryCode
}

export enum PsychologicalCareProblemCategoryCode {
  HYPERACTIVITY_IMPULSIVITY = "a.1",
  DIRECT_AGGRESSION = "a.2",
  ANTISOCIAL_BEHAVIOR = "a.3",
  DISTRUST = "b.1",
  DEPRESSION = "b.2",
  SOCIAL_BEHAVIOR = "b.3",
  ANHEDONIA = "c.1",
  SUBMISSIVENESS = "c.2",
  COMPULSIVENESS = "c.3",
  PERCEPTION_DISORDERS = "d.1",
  THINKING_DISORDERS = "d.2",
  MEMORY_DISORDERS = "d.3",
  FOCUS_DISORDERS = "d.4",
  EXECUTIVE_FUNCTION_DISORDERS = "d.5",
  INTELLECTUAL_DISABILITY = "d.6",
  NEURODEVELOPMENTAL_DISORDERS = "e.1",
  FAMILY_SITUATION_ADAPTIVE_REACTION = "f.1",
  SCHOOL_SITUATION_ADAPTIVE_REACTION = "f.2",
  CULTURAL_PEER_GROUP_ADAPTIVE_REACTION = "f.3",
  SEXUAL_DEVELOPMENT_REACTION = "f.4",
  DISEASE_SITUATION_REACTION = "f.5",
  PAST_TRAUMA_STATE = "f.6",
  SELF_DESTRUCTIVE_WITHOUT_SUICIDAL_INTENT_BEHAVIOR = "g.1",
  SELF_DESTRUCTIVE_WITH_SUICIDAL_INTENT_BEHAVIOR = "g.2",
  SOMATIC_SYMPTOMS = "h.0",
  OTHERS = "i.0",
  Z03 = "y.0",
}

export const getPsychologicalCareProblemCategoryLabel = (code: PsychologicalCareProblemCategoryCode): string => {
  switch (code) {
    case PsychologicalCareProblemCategoryCode.HYPERACTIVITY_IMPULSIVITY:
      return "Nadaktywność i impulsywność"
    case PsychologicalCareProblemCategoryCode.DIRECT_AGGRESSION:
      return "Agresja bezpośrednia"
    case PsychologicalCareProblemCategoryCode.ANTISOCIAL_BEHAVIOR:
      return "Zachowania antyspołeczne"
    case PsychologicalCareProblemCategoryCode.DISTRUST:
      return "Nieufność-ksobność"
    case PsychologicalCareProblemCategoryCode.DEPRESSION:
      return "Lęk-depresja"
    case PsychologicalCareProblemCategoryCode.SOCIAL_BEHAVIOR:
      return "Zahamowanie społeczne"
    case PsychologicalCareProblemCategoryCode.ANHEDONIA:
      return "Anhedonia"
    case PsychologicalCareProblemCategoryCode.SUBMISSIVENESS:
      return "Submisyjność"
    case PsychologicalCareProblemCategoryCode.COMPULSIVENESS:
      return "Kompulsywność"
    case PsychologicalCareProblemCategoryCode.PERCEPTION_DISORDERS:
      return "Zaburzenia spostrzegania"
    case PsychologicalCareProblemCategoryCode.THINKING_DISORDERS:
      return "Zaburzenia myślenia"
    case PsychologicalCareProblemCategoryCode.MEMORY_DISORDERS:
      return "Zaburzenia pamięci"
    case PsychologicalCareProblemCategoryCode.FOCUS_DISORDERS:
      return "Zaburzenia uwagi"
    case PsychologicalCareProblemCategoryCode.EXECUTIVE_FUNCTION_DISORDERS:
      return "Zaburzenia funkcji wykonawczych"
    case PsychologicalCareProblemCategoryCode.INTELLECTUAL_DISABILITY:
      return "Niepełnosprawność intelektualna"
    case PsychologicalCareProblemCategoryCode.NEURODEVELOPMENTAL_DISORDERS:
      return "Zaburzenia o podłożu neurorozwojowym"
    case PsychologicalCareProblemCategoryCode.FAMILY_SITUATION_ADAPTIVE_REACTION:
      return "Reakcja adaptacyjna związana z sytuacją rodzinną"
    case PsychologicalCareProblemCategoryCode.SCHOOL_SITUATION_ADAPTIVE_REACTION:
      return "Reakcja adaptacyjna związana z sytuacją w szkole lub przedszkolu"
    case PsychologicalCareProblemCategoryCode.CULTURAL_PEER_GROUP_ADAPTIVE_REACTION:
      return "Reakcja adaptacyjna związana z grupą rówieśniczą lub wpływem kulturowym"
    case PsychologicalCareProblemCategoryCode.SEXUAL_DEVELOPMENT_REACTION:
      return "Reakcja związana z rozwojem seksualnym dziecka oraz nastolatka"
    case PsychologicalCareProblemCategoryCode.DISEASE_SITUATION_REACTION:
      return "Reakcja związana z sytuacją choroby"
    case PsychologicalCareProblemCategoryCode.PAST_TRAUMA_STATE:
      return "Stan po doświadczeniu traumy w przeszłości"
    case PsychologicalCareProblemCategoryCode.SELF_DESTRUCTIVE_WITHOUT_SUICIDAL_INTENT_BEHAVIOR:
      return "Zachowania autodestrukcyjne bez intencji samobójczej"
    case PsychologicalCareProblemCategoryCode.SELF_DESTRUCTIVE_WITH_SUICIDAL_INTENT_BEHAVIOR:
      return "Zachowania autodestrukcyjne z intencją samobójczą"
    case PsychologicalCareProblemCategoryCode.SOMATIC_SYMPTOMS:
      return "Objawy i skargi somatyczne (psychosomatyczne)"
    case PsychologicalCareProblemCategoryCode.OTHERS:
      return "Inne"
    case PsychologicalCareProblemCategoryCode.Z03:
      return "Z03"
  }

  throw new Error(`Unknown psychological care problem code: ${code}`);
}
