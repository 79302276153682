<template>
  <div class="d-flex row">
    <treatment-type-expanded-select
      v-for="treatment in items"
      :key="treatment.value"
      :treatment-type="treatment"
      :selected-values="value"
      :class="colClass"
      :errors="errors"
      @update="update"
    />
  </div>
</template>


<script>
import TreatmentTypeExpandedSelect from "../TreatmentType/TreatmentTypeExpandedSelect";
import read from "../../rest/read";

export default {
  name: "TreatmentWorkerSelect",
  components: {
    TreatmentTypeExpandedSelect,
  },
  props: {
    value: {type: Array, required: true},
    colClass: {type: String, default: null},
    errors: {type: Array, default: () => []},
    errorsFieldName: {type: String, default: "treatmentTypes"},
  },
  data() {
    return {
      items: [],
    }
  },
  async mounted() {
    const {items} = await read("/api/treatment-types", {active: 1, treeStructure: true, withoutUndefined: true});
    this.items = items;
  },
  methods: {
    update(treatmentType) {
      const values = this.value;

      if(values.includes(treatmentType.value)) {
        this.removeTreatmentType(values, treatmentType.value);
      } else {
        this.addTreatmentType(values, treatmentType.value);
      }

      this.$emit("input", values);
    },
    removeTreatmentType(collection, treatmentTypeId) {
      const index = collection.indexOf(treatmentTypeId);
      if(index !== -1) {
        collection.splice(index, 1);
      }
    },
    addTreatmentType(collection, treatmentTypeId) {
      if (!collection.includes(treatmentTypeId)) {
        collection.push(treatmentTypeId);
      }
    }
  },
}
</script>
