import {isGranted} from "../security/isGranted";

export const registerPatientButtonMixin = {
  data() {
    return {
      patientCreateAllowed: null,
    };
  },
  async mounted() {
    await this.checkCreatePatientPermission()
  },
  methods: {
    async checkCreatePatientPermission() {
      this.patientCreateAllowed = await isGranted(["CREATE_NFZ_PATIENT"]);
    }
  },
};
