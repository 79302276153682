<template>
  <div class="card-box">
    <h3>Dodaj osobę powiązaną</h3>
    <eligible-persons
      v-model="persons"
      :disabled="loading"
      :errors="errors"
      :optional-pesel="true"
      :age="age"
      :patient-id="patientId"
      form-expanded
      @addPatientAddress="addPatientAddress($event)"
    />
    <error-message
      :errors="errors"
    />
    <div class="text-right">
      <router-link
        :to="{name: 'patientCard', params: {patientId}}"
        class="btn btn-secondary"
      >
        Powrót
      </router-link>
      <button
        :disabled="loading"
        class="btn btn-primary ml-2"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </div>
</template>
<script>
import {generateUuid} from "../../utils/uuid/generateUuid";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import update from "../../rest/update";
import EligiblePersons from "../../components/EligiblePersons/EligiblePersons";
import {BadRequestException} from "../../rest";
import ErrorMessage from "../../components/Form/ErrorMessage";
import processResponseException from "../../utils/errors/processResponseException";
import read from "../../rest/read";
import {mapActions} from "vuex";

export default {
  name: "EligiblePersonsPage",
  components: {
    ErrorMessage,
    EligiblePersons,
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true}
  },
  data() {
    return {
      persons: [],
      loading: false,
      patientAddress: null,
      name: null,
      surname: null,
      pesel: null,
      age: null

    };
  },
  async mounted(){
    this.loadData();
    this.getPatientAddress();
  },

  methods: {
    ...mapActions("toastNotification", ["showToast"]),
    addPatientAddress(personIndex){
      this.persons = this.persons.map((person, index) => {
        if (index === personIndex) {
          return {
            ...person,
            id: generateUuid(),
            address: {...this.patientAddress},
          };
        }
        return person;
      });
    },
    async submit() {
      const eligiblePersons = this.persons.map(person => ({
        name: person.name,
        surname: person.surname,
        pesel: person.pesel,
        address: person.address,
        type: person.type,
        description: person.description,
        phoneNumber: person.phoneNumber,
        email: person.email,
      }));
      try {
        this.loading = true;
        await update(`/api/patients/${this.patientId}/persons-eligible`, {eligiblePersons});
        this.errors = [];
        this.showToast({
          message: "Zmiany zostały zapisane",
          variant: "success",
        });
      } catch(exception) {
        this.errors = processResponseException(exception);
        if (exception instanceof BadRequestException) {
          this.showToast({
            message:"Formularz zawiera błędy.\nZmiany nie zostały zapisane",
            variant: "danger",
          });
        } else {
          this.showToast({
            message: "Wystąpił błąd.\nZmiany nie zostały zapisane",
            variant: "danger",
          });
        }
      }
      this.loading = false;
    },
    async loadData(){
      const {name, surname, pesel, eligiblePersons,age} = await read(`/api/patients/${this.patientId}/persons-eligible`);
      this.name = name;
      this.surname = surname;
      this.pesel = pesel;
      this.age = age;
      this.persons = eligiblePersons.map(person => ({...person, id: generateUuid()}))
    },
    async getPatientAddress(){
      this.patientAddress = await read(`/api/patients/${this.patientId}/living_address`);
    },
    state(field){
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    getSubErrors(field) {
      return subErrors(this.errors, field);
    },
  },
}
</script>
