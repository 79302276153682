






























































































































































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import Card from "../../components/Card.vue";
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import IsGranted from "../../components/IsGranted.vue";
import {
  EstablishmentTreatmentTypeWeightsUnit,
  UnitPatchDTO,
} from "../../types/Establishment";
import update from "../../rest/update";
import processResponseException from "../../utils/errors/processResponseException";

interface TableEntry {
  year: number;
  month: number;
  weight: number | string;
  unitId: string;
  treatmentTypeId: string;
  error?: boolean;
  updated?: boolean;
}

@Component({
  name: "EstablishmentUnitTreatmentTypesWeightsTable",
  components: {
    IsGranted,
    ErrorMessage,
    Card,
  },
})

export default class EstablishmentUnitTreatmentTypesWeightsTable extends Mixins(ErrorsMixin) {
  @Prop({type: Number, required: true}) readonly selectedYear!: number;
  @Prop({type: Boolean, required: true}) readonly editing!: boolean;
  @Prop({type: Array, required: true}) readonly units!: EstablishmentTreatmentTypeWeightsUnit[];

  $refs!: {
    tablesWrapper: HTMLDivElement
  }

  private saving: boolean = false;
  public errorsPerUnit: string[] = []; // unit ids

  values: TableEntry[] = []

  fieldsDefinitions = [
    {key: "name", label: "Typ świadczenia"},
    {key: "settlementCode", label: "Kod rozliczeniowy"},
    {key: "month1", label: "01 styczeń", month: "1"},
    {key: "month2", label: "02 luty", month: "2"},
    {key: "month3", label: "03 marzec", month: "3"},
    {key: "month4", label: "04 kwiecień", month: "4"},
    {key: "month5", label: "05 maj", month: "5"},
    {key: "month6", label: "06 czerwiec", month: "6"},
    {key: "month7", label: "07 lipiec", month: "7"},
    {key: "month8", label: "08 sierpień", month: "8"},
    {key: "month9", label: "09 wrzesień", month: "9"},
    {key: "month10", label: "10 październik", month: "10"},
    {key: "month11", label: "11 listopad", month: "11"},
    {key: "month12", label: "12 grudzień", month: "12"},
  ]

  @Watch("units", {immediate: true}) onUnitsLoad(): void {
    this.values = []
    this.units.forEach(unit => {
      let entry = {
        unitId: unit.id,
        treatmentTypeId: "",
      }

      unit.treatmentTypes.forEach(treatmentType => {
        entry = {
          ...entry,
          treatmentTypeId: treatmentType.id
        }

        treatmentType.weights.forEach(weight => {
          this.values.push({...entry, ...weight} as TableEntry)
        })
      })
    })
  }

  getInvalidClass({unitId, treatmentId, month}: {unitId: string, treatmentId: string, month: string}) {
    const item = this.values.find(valueItem =>
      valueItem.year === this.selectedYear
      && valueItem.month === Number(month)
      && valueItem.unitId === unitId
      && valueItem.treatmentTypeId === treatmentId
    )

    return (item && item.error) ? "invalid" : ""
  }

  getInitialValue({unitId, treatmentId, month}: {unitId: string, treatmentId: string, month: string}) {
    const valueItem = this.values.find(valueItem =>
      valueItem.year === this.selectedYear
      && valueItem.month === Number(month)
      && valueItem.unitId === unitId
      && valueItem.treatmentTypeId === treatmentId
      && valueItem.updated
    )

    if (valueItem || valueItem === "") return valueItem.weight

    let initialVal = ""
    this.units.forEach(unit => {
      if (unit.id === unitId) {
        unit.treatmentTypes.forEach(treatmentType => {
          if (treatmentType.id === treatmentId) {
            const weightItem = treatmentType.weights.find(weight => weight.month === Number(month) && weight.year === this.selectedYear)
            if (weightItem) {
              initialVal = String(weightItem.weight)
            }
          }
        })
      }
    })

    return initialVal || ""
  }

  formatUnitAddress({zipCode, city, street}: {zipCode?: string, city?: string, street?: string}) {
    const postalCodeCity = (zipCode && city) ? `${zipCode} ${city}` : ""
    return street
      ? `ul. ${street}, ${postalCodeCity}`
      : postalCodeCity
  }

  async submit() {
    const fieldsWithErrors = this.values.filter(val => val.error)
    const invalidUnitId = fieldsWithErrors.map(val => val.unitId)
    this.errorsPerUnit = invalidUnitId

    if (fieldsWithErrors.length) {
      const firstError = this.$refs.tablesWrapper.querySelector(`#${invalidUnitId[0]}`)
      firstError?.scrollIntoView({behavior: "smooth"})

      return
    }

    this.$emit("setEditing", false)

    const payloadUnits = this.values.reduce((values, currentValues) => {
      if (!["0", 0].includes(currentValues.weight) && !currentValues.weight) {
        // save 0 but not empty (deleted) value
        return values
      }

      const unitItem = values.find(val => val.unitId === currentValues.unitId)

      const weightEntry = {
        year: currentValues.year,
        month: currentValues.month,
        weight: Number(currentValues.weight),
      }
      const treatmentTypeEntry = {
        treatmentTypeId: currentValues.treatmentTypeId,
        entries: [weightEntry]
      }

      if (unitItem) {
        const treatmentItem = unitItem.entries.find(treatmentEntry => treatmentEntry.treatmentTypeId === currentValues.treatmentTypeId)

        treatmentItem
          ? treatmentItem.entries.push(weightEntry)
          : unitItem.entries.push(treatmentTypeEntry)

        return values
      }

      return [
        ...values,
        {
          unitId: currentValues.unitId,
          entries: [treatmentTypeEntry]
        }
      ]
    }, [] as UnitPatchDTO[])

    this.saving = true;
    try {
      await update("/api/unit/treatment-type-weights", {
        units: payloadUnits
      });
      await this.$emit("fetchData")
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.saving = false;
  }

  change(e: Event, {unitId, treatmentId, month}: {unitId: string, treatmentId: string, month: string}) {
    const value = (e.target as HTMLInputElement).value.replace(",", ".")

    const validationRegex = /^(?!0\d)\d*(\.\d{1,4})?$/ // number with optionally 1-4 decimal places

    const isInvalidValue = value ? !validationRegex.test(value) : false

    let newEntry: TableEntry = {
      unitId: unitId,
      treatmentTypeId: treatmentId,
      year: this.selectedYear,
      month: Number(month),
      weight: isInvalidValue
        ? value // invalid string
        : value
          ? Number(value)
          : "",
      error: isInvalidValue,
      updated: true
    }

    const foundEntryInValues = this.values.find(valueItem =>
      valueItem.year === newEntry.year
      && valueItem.month === newEntry.month
      && valueItem.unitId === unitId
      && valueItem.treatmentTypeId === treatmentId
    )

    if (foundEntryInValues) {
      this.values = this.values.reduce((values, currentItem) => {
        const isFoundItem = currentItem.year === newEntry.year
          && currentItem.month === newEntry.month
          && currentItem.unitId === unitId
          && currentItem.treatmentTypeId === treatmentId

        return [
          ...values,
          isFoundItem ? newEntry : currentItem
        ] as TableEntry[]
      }, [] as TableEntry[])
    } else {
      this.values = [
        ...this.values,
        newEntry
      ]
    }
  }

  async cancel() {
    await this.$emit("fetchData")
    this.$emit("setEditing", false)
  }

  scrollTo(id: string) {
    document.getElementById(id)
      ?.scrollIntoView({
        behavior: "smooth",
        inline: "start"
      })
  }
}
