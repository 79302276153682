<template>
  <object-select
    :value="selectedOption"
    :options="options"
    :disabled="disabled"
    :placeholder="placeholder"
    :clearable="false"
    :state="state"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "../Form/Select/ObjectSelect";

export default {
  name: "CodeVIISelect",
  components: {ObjectSelect},
  props: {
    value: {type: String, default: null},
    disabled: ObjectSelect.props.disabled, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz kod VII"},
    state: {type: Boolean, default: null},
  },
  data() {
    return {
      loading: true,
      options: [
        {value: "1791", name: "Poradnia psychologiczna dla dzieci i młodzieży"},
        {value: "1701", name: "Poradnia zdrowia psychicznego dla dzieci i młodzieży"},
        {value: "2703", name: "Odział dzienny psychiatryczny rehabilitacyjny dla dzieci"},
      ],
    }
  },
  computed: {
    selectedOption() {
      if ("string" === typeof this.value) {
        return this.options.find(option => option.value === this.value);
      }
      return this.value;
    },
  },
  methods: {
    update(option) {
      this.$emit("input", option ? option.value : null);
    },
  },
}
</script>
