<template>
  <div>
    <h4
      v-if="null !== description.date && !dateEdit"
      class="m-b-10"
    >
      {{ description.date }}
    </h4>
    <h4
      v-if="displayCreator && null !== description.creator"
      class="m-b-10"
    >
      Autor wpisu: {{ description.creator }}
    </h4>
    <b-form-group
      v-if="dateEdit"
      label="Data"
      label-for="date"
    >
      <date-picker
        id="date"
        v-model="formData.date"
        :state="state('date')"
        @input="update"
      />
      <error-message
        :errors="errors"
        field="date"
      />
    </b-form-group>

    <b-form-group class="m-b-15 m-t-20">
      <b-form-checkbox
        v-model="formData.patientNotShowUp"
        :disabled="readonly"
        :state="state('patientPresence')"
        @change="update"
      >
        Pacjent się nie zjawił
      </b-form-checkbox>
      <error-message
        :errors="errors"
        field="patientPresence"
      />
    </b-form-group>

    <b-form-group
      label="Typ pracownika"
      label-for="workerCategory"
    >
      <continuous-stay-daily-description-worker-category
        id="workerCategory"
        v-model="formData.workerCategory"
        :state="state('workerCategory')"
        :disabled="readonly"
        @input="update"
      />
      <error-message
        :errors="errors"
        field="workerCategory"
      />
    </b-form-group>

    <b-form-group
      label="ICD-9"
      label-for="icd9codes"
    >
      <icd9-code-select
        id="icd9codes"
        v-model="formData.icd9codes"
        multiple
        :disabled="readonly"
        :state="state('icd9codes')"
        @input="update"
      />
      <error-message
        :errors="errors"
        field="icd9codes"
      />
    </b-form-group>

    <b-form-group
      id="stayDescription"
      label="Opis przebiegu pobytu"
      label-for="stayDescription"
    >
      <textarea-counter
        v-model="formData.stayDescription"
        :disabled="readonly"
        :max-characters-count="15000"
        :state="state('stayDescription')"
        rows="3"
        @input="updateDescription"
      />
      <error-message
        :errors="errors"
        field="stayDescription"
      />
    </b-form-group>
  </div>
</template>

<script>
import ErrorMessage from "../../../Form/ErrorMessage";
import TextareaCounter from "../../../Form/Textarea/TextareaCounter";
import DatePicker from "../../../Form/DatePicker/DatePicker";
import ContinuousStayDailyDescriptionWorkerCategory
  from "../../../Form/Select/ContinuousStayDailyDescriptionWorkerCategory";
import Icd9CodeSelect from "../../../Referrals/Icd9CodeSelect.vue";

export default {
  name: "ContinuousStaySpecialistsDescriptionForm",
  components: {
    ContinuousStayDailyDescriptionWorkerCategory,
    ErrorMessage,
    DatePicker,
    Icd9CodeSelect,
    TextareaCounter
  },
  props: {
    description: {type: Object, required: true},
    errors: {type: Array, default: () => []},
    readonly: {type: Boolean, default: false},
    dateEdit: {type: Boolean, default: false},
    displayCreator: {type: Boolean, default: true}
  },
  data() {
    return {
      formData: {
        patientNotShowUp: false,
        workerCategory: null,
        date: new Date(this.description.date),
        stayDescription: null,
        icd9codes: [],
      },
      loading: false
    }
  },
  watch: {
    description(){
      this.setFormData();
    }
  },
  mounted() {
    this.setFormData();
  },
  methods: {
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    update() {
      this.$emit("update", this.formData);
    },
    updateDescription() {
      this.$emit("update",{
        ...this.formData,
        stayDescription: null !== this.formData.stayDescription
          ? this.formData.stayDescription.trim()
          : this.formData.stayDescription
      })
    },
    setFormData(){
      if(this.description){
        this.formData = {
          ...this.description,
          date: new Date(this.description.date),
        };
      }
    }
  }
}
</script>
