<template>
  <b-modal
    id="open-appointment-modal"
    no-close-on-backdrop
    title="Otwórz wizytę"
    size="lg"
    title-tag="h3"
    @hide="clearErrors"
    @show="showModal"
  >
    <template #default>
      <appointment-details
        :appointment-id="appointmentId"
        :date="date"
        :patients="patients"
      />
      <error-message :errors="errors" />

      <div
        v-if="workers.length"
        class="form-group row"
      >
        <i class="col-2 text-center fa fa-2x fa-user-md" />
        <div class="col-10">
          <div
            v-for="(worker, idx) in sortedWorkers"
            :key="worker.workerId"
            :class="idx === sortedWorkers.length - 1 ? '' : 'margin-bottom'"
            class="worker"
          >
            <div v-if="worker.nfzWorkerType">
              <b class="first-uppercase">{{ t(`@nfz-worker-type.${worker.nfzWorkerType}`) }}</b>
            </div>

            <input
              :value="worker.name"
              readonly
              class="form-control"
            >
          </div>
        </div>
      </div>

      <div
        class="form-group row"
      >
        <i class="col-2 text-center fa fa-2x fa-hospital-alt" />
        <div class="col-10">
          <branch-select
            v-model="newBranchId"
            check-if-any-branch-exists
            :state="state('branchId')"
            disabled
          />
          <error-message
            :errors="errors"
            field="branchId"
          />
        </div>
      </div>

      <is-granted
        :privileges="['FINISH_EMPTY_APPOINTMENT']"
        :subject="appointmentId"
      >
        <div class="text-center">
          <p>
            Zaplanowana wizyta nie posiada zdefiniowanego pacjenta,
            dlatego możesz ją zamknąć bez konieczności wypełniania dokumentu.
          </p>
          <button
            class="btn btn-sm btn-warning text-center"
            @click="finishEmptyAppointment"
          >
            Zamknij wizytę bez otwierania dokumentu
          </button>
        </div>
      </is-granted>
    </template>

    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Zamknij
      </button>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary"
        @click="open"
      >
        <i
          v-if="loading"
          class="fas fa-spin fa-spinner"
        />
        Otwórz wizytę
      </button>
    </template>
  </b-modal>
</template>

<script>
import AppointmentDetails from "../AppointmentDetails";
import update from "../../../rest/update";
import {BadRequestException, ForbiddenException} from "../../../rest";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorMessage from "../../Form/ErrorMessage";
import IsGranted from "../../IsGranted";
import BranchSelect from "@/components/Branch/BranchSelect";
import t from "@/i18n"

export default {
  name: "OpenAppointment",
  components: {
    BranchSelect,
    IsGranted,
    ErrorMessage,
    AppointmentDetails,
  },
  mixins: [errorsMixin],
  props: {
    id: {type: String, default: null},
    appointmentId: {type: String, required: true},
    status: {type: String, required: true},
    date: {type: Date, required: true},
    group: {type: Boolean, required: true},
    patients: {type: Array, required: true},
    patientId: {type: String, default: null},
    mainWorker: {type: Object, required: true},
    workers: {type: Array, default: () => []},
    continuousStay: {type: Boolean, default: false},
    branchId: {type: String, required: true},
    branchName: {type: String, required: true},
  },
  data() {
    return {
      loading: false,
      additionalWorkers: [],
      newBranchId: this.branchId,
      t: t,
    };
  },
  computed: {
    documentPath() {
      return {name: "viewDocumentAppointment", params: {appointmentId: this.appointmentId}};
    },
    sortedWorkers() {
      return [...this.workers].sort((worker1) => {
        return worker1.workerId === this.mainWorker.workerId ? -1: 1;
      });
    },
  },
  methods: {
    showModal() {
      this.additionalWorkers = this.workers.map((item) => ({
        treatmentTypeId: item.treatmentType || item.treatmentTypeId,
        name: item.name,
        workerId: item.workerId,
        nfzWorkerType: item.nfzWorkerType,
        isTreatmentTypeUndefined: item.isTreatmentTypeUndefined
      }))
    },
    closeModal() {
      this.$bvModal.hide("open-appointment-modal");
    },
    async open() {
      this.clearErrors();
      this.loading = true;
      const updates = {
        additionalWorkers: this.additionalWorkers,
        branchId: this.newBranchId,
      };
      try {
        await update(`/api/appointment/${this.appointmentId}/open`, updates);

        await this.$router.push(this.documentPath);
      } catch (e) {
        if (e instanceof BadRequestException) {
          this.errors = processResponseException(e);
        }
        if (e instanceof ForbiddenException) {
          //invalid status transition, already opened
          await this.$router.push(this.documentPath);
        }
      }
      this.loading = false;
    },
    clearErrors() {
      this.errors = [];
    },
    finishEmptyAppointment() {
      this.closeModal();
      this.$emit("finishEmptyAppointment");
    },
  },
}
</script>

<style lang="scss" scoped>
.collapse-visible {
  .item {
    margin-top: 5px;
  }

  & > .item {
    font-weight: 700;
    margin-top: 0;
  }
}

.margin-bottom {
  margin-bottom: 6px;
}

.first-uppercase {
  margin-left: 6px;
  display: block;
  &::first-letter {
    text-transform: uppercase;
  }
}
</style>
