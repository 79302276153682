<template>
  <div>
    <loading-icon
      v-if="loading"
      class="form-control"
      size="sm"
      text
    />
    <treeselect
      v-else
      :class="{invalid: isInvalid}"
      clear-all-text="Odznacz wszystko"
      clear-value-text="Odznacz"
      :default-expand-level="0"
      :disabled="disabled"
      disable-branch-nodes
      :flat="true"
      loading-text="Pobieranie opcji..."
      :multiple="multiple"
      :name="name"
      no-children-text="Brak rozwijanych opcji"
      :no-options-text="noOptionsText"
      no-results-text="Brak wyników wyszukiwania"
      :options="options"
      :placeholder="placeholder"
      retry-text="Pobrać ponownie?"
      retry-title="Kliknij by pobrać ponownie"
      search-prompt-text="Zacznij pisać..."
      show-count
      :value="value"
      @input="update"
      @select="select"
      @open="loadOptions"
    >
      <template #option-label="{node: {raw: node}}">
        {{ node.label }}
        <div
          v-if="node.type === 'unit' && node.address"
          class="border-bottom"
        >
          {{ `(${node.address.street}, ${node.address.zipCode} ${node.address.city})` }}
        </div>
      </template>
      <template
        v-if="displayInlineValue"
        #value-label="{node: {raw: node}}"
      >
        {{ node.label }}
        <span v-if="node.address">
          {{ `(${node.address.street}, ${node.address.zipCode} ${node.address.city})` }}
        </span>
      </template>
      <template
        v-else
        #value-label="{node: {raw: node}}"
      >
        {{ node.label }}
        <div v-if="node.address">
          {{ `(${node.address.street}, ${node.address.zipCode} ${node.address.city})` }}
        </div>
      </template>
    </treeselect>
  </div>
</template>

<script>
import read from "../../rest/read";
import Treeselect from "@hobbytowo/vue-treeselect";
import "@hobbytowo/vue-treeselect/dist/vue-treeselect.css";
import LoadingIcon from "../Loading/LoadingIcon";

export default {
  name: "BranchSelect",
  components: {LoadingIcon, Treeselect},
  props: {
    name: {default: "branch", type: String},
    value: {type: [Object, String, Array], default: null},
    disabled: {default: false, type: Boolean},
    clearable: {default: true, type: Boolean},
    placeholder: {type: String, default: "Wybierz komórkę organizacyjną"},
    multiple: {default: false, type: Boolean},
    state: {default: null, type: Boolean},
    clearOnOpen: {type: Boolean, default: false},
    checkIfAnyBranchExists: {type: Boolean, default: false},
    workerId: {type: String, default: null},
    emitSelectOnInit: {type: Boolean, default: false},
    noOptionsText: {type: String, default: "Brak opcji do wyświetlenia"},
    displayInlineValue: {type: Boolean, default: false}
  },
  data() {
    return {
      options: [],
      loading: false,
    };
  },
  computed: {
    isInvalid() {
      return false === this.state;
    },
  },
  mounted() {
    if (this.checkIfAnyBranchExists) {
      this.firstTimeLoad();
    }
  },
  methods: {
    async loadOptions() {
      const {items} = await read("/api/branches");

      this.options = items.map(item => this.branchToOption(item));
    },
    branchToOption(branch) {
      return {
        ...branch,
        label: branch.name,
        id: branch.branchId,
        children: branch.children
          ? branch.children.map(child => this.branchToOption(child))
          : undefined,
      }
    },
    async firstTimeLoad() {
      this.loading = true;
      await this.loadOptions();
      if (!this.options.length) {
        this.$emit("emptyList");
      }
      if (this.value && this.emitSelectOnInit) {
        this.checkOptionForDiagnosisResultBox()
      }
      this.loading = false;
    },
    checkOptionForDiagnosisResultBox(value) {
      // find selected branch to check diagnosis box visibility
      const flatten = (items) => items.reduce((arr, item) => {
        return item.children ? [...arr, ...flatten(item.children)] : [...arr, item]
      }, [])
      const items = flatten(this.options)

      const id = value || this.value
      const selectedOption = items.find(item => item.id === id)
      this.select(selectedOption)
    },
    update(option) {
      if(option === undefined) {
        this.$emit("input", null);
        this.select(null)
      } else {
        this.checkOptionForDiagnosisResultBox(option)
        this.$emit("input", option);
      }
    },
    select(option) {
      this.$emit("select", option);
    },
  },
}
</script>
