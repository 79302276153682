<template>
  <div>
    <card
      :loading="loading"
      title="Kontynuacja nauki"
    >
      <template #control>
        <is-granted
          :privileges="['CHANGE_PATIENT_CONTINUING_EDUCATION']"
        >
          <b-btn
            v-b-modal.ChangeContinuingEducation
            :disabled="loading"
            variant="primary"
            class="btn-sm"
          >
            <i class="fa fa-pencil-alt" />
          </b-btn>
        </is-granted>
      </template>
      <b-modal
        id="notification"
        no-close-on-backdrop
        title="Powiadomienie"
        title-tag="h3"
      >
        Jeśli dokonałeś nowego wpisu, pamiętaj o dodaniu załącznika
        <template #modal-footer>
          <button
            type="button"
            class="btn btn-secondary"
            @click="closeNotification()"
          >
            Zamknij
          </button>
        </template>
      </b-modal>
      <b-modal
        id="ChangeContinuingEducation"
        no-close-on-backdrop
        title="Edycja Kontynuacji nauki"
        title-tag="h3"
      >
        <template #default>
          <b-form-group label="Kontynuuje naukę">
            <patient-card-continuing-education-active-select
              v-model="active"
              :disabled="editTable"
              :state="state('active')"
            />
            <error-message
              :errors="errors"
              field="active"
            />
          </b-form-group>
          <b-form-group label="Data rozpoczęcia">
            <date-picker
              v-model="startDate"
              :disabled="loading"
              :state="state('startDate')"
            />
            <error-message
              :errors="errors"
              field="startDate"
            />
          </b-form-group>
          <b-form-group label="Data zakończenia">
            <date-picker
              v-model="endDate"
              :disabled="loading"
              :state="state('endDate')"
            />
            <error-message
              :errors="errors"
              field="endDate"
            />
          </b-form-group>
          <div
            v-b-toggle="'continuingEducationsHistory'"
            class="text-center m-2 cursor-pointer"
          >
            ---
            <span class="when-closed">Pokaż historię</span>
            <span class="when-opened">Ukryj historię</span>
            ---
          </div>
          <b-collapse id="continuingEducationsHistory">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>Data rozpoczęcia</th>
                  <th>Data zakończenia</th>
                  <th>Data utworzenia</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(entry, index) in continuingEducationsHistory"
                  :key="index"
                  class="p-3"
                >
                  <td>{{ formatDate(entry.startDate) }}</td>
                  <td>{{ formatDate(entry.endDate) }}</td>
                  <td>{{ formatDate(entry.createdAt) }}</td>
                  <td>
                    <b-btn
                      :disabled="loading"
                      variant="outline-secondary"
                      class="mr-1"
                      size="sm"
                      @click="edit(entry)"
                    >
                      <i class="fas fa-pencil-alt" />
                    </b-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-collapse>
        </template>
        <template #modal-footer>
          <button
            type="button"
            class="btn btn-secondary"
            @click="close()"
          >
            Anuluj
          </button>
          <button
            class="btn btn-primary"
            @click="submit"
          >
            <i
              class="fa"
            />
            Zapisz
          </button>
        </template>
      </b-modal>
      <p>Kontynuuje naukę: {{ validDate() ? "Tak" : "Nie" }}</p>
      <div v-if="validDate()">
        <p>Data ważności :  {{ showDate() }}</p>
      </div>
    </card>
  </div>
</template>

<script>
import PatientCardContinuingEducationActiveSelect from "../../Form/Select/PatientCardContinuingEducationActiveSelect"
import ErrorMessage from "../../Form/ErrorMessage";
import Card from "../../Card";
import IsGranted from "../../IsGranted";
import {errorsMixin} from "../../../mixins/errorsMixin";
import read from "../../../rest/read";
import DatePicker from "../../Form/DatePicker/DatePicker"
import processResponseException from "../../../utils/errors/processResponseException";
import update from "../../../rest/update";
import stringifyDate from "../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
export default {
  name: "PatientCardContinuingEducation",
  components: {
    Card,
    IsGranted,
    PatientCardContinuingEducationActiveSelect,
    ErrorMessage,
    DatePicker
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      loading : false,
      active  : true,
      endDate : null,
      startDate : null,
      continuingEducationsHistory : [],
      latestContinuingEducationsHistoryStartDate : null,
      latestContinuingEducationsHistoryEndDate : null,
      continuingEducationsHistoryId : null,
      editTable : true,
      actionType: "add"
    }
  },
  watch: {
    active() {
      if(!this.active){
        this.endDate = null
        this.startDate = null
      }
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    showDate() {
      if (this.latestContinuingEducationsHistoryStartDate && this.latestContinuingEducationsHistoryEndDate) {
        return `${stringifyDate(new Date(this.latestContinuingEducationsHistoryStartDate), DATE_FORMAT.DATE)} - ${stringifyDate(new Date(this.latestContinuingEducationsHistoryEndDate), DATE_FORMAT.DATE)}`;
      }
    },
    validDate(){
      return new Date() >= new Date(this.latestContinuingEducationsHistoryStartDate) && new Date() <= new Date(this.latestContinuingEducationsHistoryEndDate)
    },
    formatDate(data) {
      return stringifyDate(new Date(data), DATE_FORMAT.DATE);
    },
    closeNotification(){
      this.$bvModal.hide("notification");
    },
    edit(item) {
      this.editTable = true;
      this.startDate = new Date(item.startDate);
      this.endDate = new Date(item.endDate);
      this.continuingEducationsHistoryId = item.id
      this.actionType = "update"
    },
    close(){
      this.errors     =  [];
      this.endDate    =  null;
      this.startDate  =  null;
      this.$bvModal.hide("ChangeContinuingEducation");
      this.actionType = "add"
      this.continuingEducationsHistoryId = null
    },
    setActive(active, historyLength){
      return (historyLength > 0) ? active : true;
    },

    async loadData() {
      this.loading = true;
      try {
        const response = await read(`/api/patients/${this.patientId}/continuing_educations_list`)
        this.active = this.setActive(response.active,response.items.length);
        this.continuingEducationsHistory = response.items;
        this.latestContinuingEducationsHistoryStartDate = response.items[0].startDate;
        this.latestContinuingEducationsHistoryEndDate = response.items[0].endDate;
      } catch (exception) {
        this.errors = processResponseException(exception)
      }
      this.loading = false
    },
    async submit(){
      this.loading = true;
      try {
        let data={
          active: this.active,
          startDate: (this.startDate !== null) ? stringifyDate(new Date(this.startDate), DATE_FORMAT.DATE) : null,
          endDate: (this.endDate !== null) ? stringifyDate(new Date(this.endDate), DATE_FORMAT.DATE) : null,
          actionType: this.actionType
        }

        if(this.editTable)  {
          data.continuingEducationHistoryId = this.continuingEducationsHistoryId
        }

        await update(`/api/patients/${this.patientId}/continuing_educations`, data);
        await this.loadData();
        this.$bvModal.show("notification");
        this.close();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
