import {isGranted} from "./security/isGranted";

const menuItems = [
  {
    icon: "fa fa-home",
    link: "/",
    name: "Strona główna",
    access() {return true},
    id: "home-page",
  },
  {
    icon: "fas fa-notes-medical",
    link: "/registration",
    name: "Rejestracja",
    async access() {return isGranted(["REGISTRATION_CALENDAR"])},
    id: "registration",
  },
  {
    icon: "fas fa-search",
    link: "/registration-search",
    name: "Wolne terminy",
    async access() {return isGranted(["REGISTRATION_SEARCH"])},
    id: "registration_search",
  },
  {
    icon: "fa fa-sticky-note",
    link: "/internal-referrals",
    name: "Skierowania wewnętrzne",
    async access() {return isGranted("INTERNAL_REFERRAL_LIST")},
    id: "internal-referrals",
  },
  {
    icon: "fas fa-headset",
    link: "/intervention-notifications",
    name: "Interwencje",
    async access() {return isGranted("INTERVENTION_NOTIFICATION_LIST")},
    id: "intervention-notifications",
  },
  {
    icon: "fas fa-calendar-plus",
    link: "/appointments/list",
    name: "Plan wizyt",
    async access() {return isGranted("APPOINTMENT_LIST")},
    id: "appointment-list",
  },
  {
    icon: "fas fa-bed",
    link: "/continuous-stays-list",
    name: "Plan pobytów ciągłych",
    async access() {return isGranted("GET_CONTINUOUS_STAY_LIST")},
    id: "continuous-stay-list",
  },
  {
    icon: "fa fa-calendar",
    link: "/calendar",
    name: "Kalendarz",
    async access() {return isGranted([
      "SCHEDULE_APPOINTMENT",
      "SCHEDULE_APPOINTMENT_SPECIALIST",
      "SCHEDULE_APPOINTMENT_RECOVERY_ASSISTANT"
    ])},
    id: "calendar",
  },
  {
    icon: "fa fa-clock",
    link: "/weekly-schedule",
    name: "Plan tygodnia",
    async access() {return isGranted("SHOW_WEEKLY_SCHEDULE")},
    id: "weekly-schedule",
  },
  {
    icon: "fa fa-users",
    link: "/patients",
    name: "Lista pacjentów",
    async access() {
      const patientList = await isGranted("PATIENT_LIST");
      const patientCard = await isGranted("PATIENT_CARD");
      return patientList && patientCard;
    },
    id: "patient-list",
  },
  {
    icon: "fa fa-heartbeat",
    link: "/ipzs/list",
    name: "IPZ pacjentów",
    async access() {return isGranted("CREATE_IPZ")},
    id: "ipz-list",
  },
  {
    icon: "fa fa-heartbeat",
    link: "/support-network-patient-list",
    name: "Wspierani pacjenci",
    async access() {return isGranted("SUPPORT_NETWORK_PATIENT_LIST")},
    id: "support-network-patient-list",
  },
  {
    icon: "fa fa-user-tie",
    link: "/worker/list",
    name: "Pracownicy",
    async access() {
      const isRoleOps = await isGranted("ROLE_SPECIALIST_OPS");
      const isRoleStatistics = await isGranted("ROLE_STATISTICS");
      const workerList = await isGranted("WORKER_LIST")

      if (isRoleOps || isRoleStatistics) {
        return false;
      }

      return workerList;
    },
    id: "worker-list",
  },
  {
    icon: "fa fa-child",
    link: "#",
    name: "Profilaktyka",
    async access() {return async () => {return true}},
    "dropdownItems": [
      {
        link: "/courses",
        name: "Kursy",
        async access() {return isGranted("COURSE_LIST")},
        id: "course-list",
      },
      {
        link: "/institutions",
        name: "Instytucje",
        async access() {return isGranted("INSTITUTION_LIST")},
        id: "institution-list",
      }
    ]
  },
  {
    icon: "fas fa-calendar-check",
    link: "/reports/main-view",
    name: "Raporty",
    async access() {
      const reportAppointmentMonthlyStats = await isGranted("REPORT_APPOINTMENT_MONTHLY_STATS");
      const getOwnSchedulerReport = await isGranted("GET_OWN_SCHEDULER_REPORT");
      const getScheduleSpecialist = await isGranted("GET_SCHEDULE_SPECIALIST");
      const createRequestIKUPChildrenRecords = await isGranted("CREATE_REQUEST_IKUP_CHILDREN_RECORDS");
      const createRequestIKUPAdultsRecords = await isGranted("CREATE_REQUEST_IKUP_ADULTS_RECORDS");
      const ikupChildren = await isGranted("IKUP_CHILDREN");
      const ikupAdults = await isGranted("IKUP_ADULTS");
      const reportSpecialistList = await isGranted("REPORT_SPECIALIST_LIST");
      const patientMedicalCareReport = await isGranted("PATIENT_MEDICAL_CARE_REPORT");
      const interventionsReport = await isGranted("INTERVENTIONS_REPORT");
      const anonymousServicesReport = await isGranted("ANONYMOUS_SERVICES_REPORT");
      const getCancelReasonReport = await isGranted("GET_CANCEL_REASON_REPORT");
      const getChildIPZMonitoringReport = await isGranted("GET_CHILD_IPZ_MONITORING_REPORT");
      const getPatientListReport = await isGranted("GET_PATIENT_LIST_REPORT");
      return reportAppointmentMonthlyStats ||
        getOwnSchedulerReport ||
        createRequestIKUPAdultsRecords ||
        createRequestIKUPChildrenRecords ||
        ikupChildren ||
        ikupAdults ||
        reportSpecialistList ||
        getScheduleSpecialist ||
        patientMedicalCareReport ||
        interventionsReport ||
        getCancelReasonReport ||
        getChildIPZMonitoringReport ||
        getPatientListReport ||
        anonymousServicesReport;
    },
    id: "reports",
  },
  {
    icon: "fa fa-user-cog",
    link: "/patients/data-unification",
    name: "Unifikacja danych",
    async access() {return isGranted("PATIENTS_UNIFICATION")},
    id: "data-unification",
  },
  {
    icon: "fa fa-list",
    link: "/treatment-types-list",
    name: "Lista usług",
    async access() {
      const roleStatistic = await isGranted("ROLE_STATISTICS");
      return !roleStatistic
    },
    id: "treatment-types-list",
  },
  {
    icon: "fas fa-hand-pointer",
    link: "/request-list",
    name: "Wnioski",
    async access() {
      return isGranted("CHANGE_REQUEST_LIST");
    },
    id: "request-list",
  },
  // {
  //   icon: "fas fa-user-lock",
  //   link: "/director/dashboard",
  //   name: "Panel dyrektora",
  //   async access() {return isGranted("SHOW_DIRECTOR_DASHBOARD")},
  //   id: "director-dashboard",
  // },


  {
    icon: "fas fa-th-list",
    link: "#",
    name: "Ustawienia",
    async access() {return async () => {return true}},
    "dropdownItems": [
      {
        icon: "fa fa-users",
        link: "/patients-without-permissions",
        name: "Lista pacjentów bez zgód",
        async access() {
          const patientListPerm = await isGranted("PATIENT_LIST_WITHOUT_PERMISSION");
          const roleStatistics = await isGranted("ROLE_STATISTICS");
          return patientListPerm && !roleStatistics;
        },
        id: "patient-list-without-permissions",
      },
      {
        icon: "fa fa-calendar-alt",
        link: "/workers-working-hours",
        name: "Harmonogramy",
        async access() {
          return isGranted("WORKING_HOURS_OF_WORKERS_LIST")
        },
        id: "working-hours",
      },
      {
        icon: "fa fa-users-cog",
        link: "/cooperating-units",
        name: "Jednostki współpracujące",
        async access() {
          return isGranted("FIND_COOPERATING_UNITS")
        },
        id: "cooperating-units",
      },
      {
        icon: "fas fa-city",
        link: "/establishments",
        name: "Lista podmiotów",
        async access() {
          return isGranted("CREATE_BRANCH")
        },
        id: "establishments",
      },
      {
        icon: "fa fa-envelope",
        link: "/system-notifications",
        name: "Zdarzenia systemowe",
        async access() {
          return isGranted("GET_SYSTEM_NOTIFICATION_LIST")
        },
        id: "system-notifications",
      },
      {
        icon: "fa fa-history",
        link: "/audit",
        name: "Audyt log",
        async access() {
          return isGranted("FIND_AUDIT_LOG")
        },
        id: "audit-list",
      },
    ],
  },
];

export default menuItems;
