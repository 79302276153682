



















































import CreateReferralModal from "../../Referrals/CreateReferralModal.vue";
import t from "../../../i18n";
import read from "../../../rest/read";
import {Component, Mixins, Prop, Watch} from "vue-property-decorator"
import ReferralsTable from "../../Referrals/ReferralsTable.vue";
import LoadingMask from "../../Loading/LoadingMask.vue";
import {isGranted} from "../../../security/isGranted";
import type {Patient, Referral} from "../../../types/Referrals";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorsMixin from "../../../mixins/ErrorsMixin";

interface Module {
  type: string;
  referrals: Referral[];
}

interface ResponseData {
  items: Record<string, any>[],
}

@Component({
  name: "ReferralsModule",
  components: {
    LoadingMask,
    ReferralsTable,
    CreateReferralModal,
  },
})
export default class ReferralsModule extends Mixins(ErrorsMixin) {
  @Prop({required: true, type: Number}) readonly idx!: number;
  @Prop({required: true, type: Object}) readonly module!: Module;
  @Prop({required: true, type: Boolean}) readonly readOnly!: boolean;
  @Prop({required: true, type: String}) readonly patientId!: string;
  @Prop({required: true, type: String}) readonly appointmentId!: string;
  @Prop({required: true, type: String}) readonly appointmentDocumentId!: string;

  public t = t;
  private loading: boolean = false;
  private referrals: Referral[] = [];
  private hasP1Access: boolean = true;
  private patient: Patient|null = null;

  @Watch("patientId", {immediate: true}) async onPatientIdChange(): Promise<void> {
    await this.fetchPatientData();
    await this.getReferralsList()
  }

  async mounted() {
    this.hasP1Access = await isGranted("ACCESS_P1_CLIENT");
  }

  async fetchPatientData(): Promise<void> {
    this.loading = true;
    try {
      this.patient = await read<Patient>(`/api/patient/${this.patientId}/details`);
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private async getReferralsList() {
    this.loading = true;
    try {
      const {items} = await read<ResponseData>(`/api/patients/${this.patientId}/referrals`);
      this.referrals = items;
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }
}
