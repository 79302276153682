<template>
  <div ref="patientCardTop">
    <div class="page-title-box">
      <div class="row">
        <h4 class="my-0 col-6">
          {{ $route.meta.title }}
          <template v-if="patient.displayName">
            - {{ patient.displayName }}
            <patient-bmi-alert
              class="ml-1"
              :patient-id="patientId"
            />
          </template>
          <i
            v-else
            class="fas fa-spin fa-spinner ml-2"
          />
        </h4>
        <div class="text-right col-6">
          <is-granted
            component="span"
            class="d-block-inline mx-1"
            :privileges="['IPZ_DETAILS']"
          >
            <patient-card-ipz-link :patient-id="patientId" />
          </is-granted>
          <a
            v-if="patient && patient.archivalUrl"
            :href="patient.archivalUrl"
            target="_blank"
            class="btn btn-sm btn-primary"
          >
            Dane archiwalne
          </a>
        </div>
      </div>
    </div>

    <is-granted
      :privileges="['GET_PATIENT_DATA_GAPS_INFORMATION']"
      component="fragment"
    >
      <DataGapsInformation
        ref="dataGapsInformation"
        :patient-id="patientId"
      />
    </is-granted>

    <last-edited-appointment />
    <div class="d-flex flex-wrap m-b-20">
      <is-granted
        :privileges="['CHANGE_PATIENT_LABOR_MARKET_STATUS']"
      >
        <patient-card-labor-market-status
          :patient-id="patientId"
          class="m-1"
          @patientUpdated="reloadPatientGapInformationData"
        />
      </is-granted>
      <div class="m-1">
        <is-granted
          :privileges="['UPDATE_PATIENT_HOSPITALIZATIONS_DATA']"
        >
          <b-btn
            v-b-modal.hospitalizations-data
            variant="primary"
            class="btn-sm"
          >
            Hospitalizacje
          </b-btn>
          <patient-card-hospitalizations-data :patient-id="patientId" />
        </is-granted>
      </div>
      <is-granted
        :privileges="['CHANGE_PATIENT_PARTICIPANT_STATUS']"
      >
        <patient-card-participant-status
          :patient-id="patientId"
          class="m-1"
        />
      </is-granted>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-3">
        <patient-card-personal-data
          :patient-id="patientId"
          @patientUpdated="loadData"
        />
        <patient-card-living-address
          v-model="livingAddress"
          :patient-id="patientId"
          @livingAddressUpdated="livingAddressUpdated"
        />
        <is-granted :privileges="['PATIENT_CORRESPONDENCE_ADDRESS']">
          <patient-card-correspondence-address
            :patient-id="patientId"
            :living-address="livingAddress"
          />
        </is-granted>
        <is-granted :privileges="['PATIENT_REGISTERED_ADDRESS']">
          <patient-card-registered-address
            :patient-id="patientId"
            :living-address="livingAddress"
          />
        </is-granted>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-3">
        <patient-card-additional-data
          :patient-id="patientId"
          @patientUpdated="reloadPatientGapInformationData"
        />
        <identification-document-data
          v-if="nfzConfiguration"
          :patient-id="patientId"
        />
        <patient-card-treatment-status
          :patient-id="patientId"
        />
      </div>

      <div class="col-sm-12 col-md-6 col-lg-3">
        <patient-card-case-manager
          :patient-id="patientId"
        />
        <is-granted
          :privileges="['PATIENT_CONTINUING_EDUCATION_LIST']"
        >
          <patient-card-continuing-education :patient-id="patientId" />
        </is-granted>
        <is-granted
          :privileges="['GET_PATIENT_ADULT_TYPE']"
          component="fragment"
        >
          <patient-card-adult-type
            :patient-id="patientId"
          />
        </is-granted>
        <is-granted
          :privileges="['GET_PATIENT_MOBILE_TEAM']"
          component="fragment"
        >
          <patient-card-mobile-team
            :patient-id="patientId"
          />
        </is-granted>

        <is-granted
          :privileges="['GET_SUPPORT_NETWORK_LIST']"
          component="fragment"
        >
          <patient-card-support-network
            :patient-id="patientId"
          />
        </is-granted>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-3">
        <is-granted
          :privileges="['GET_INSURANCE_BY_PATIENT_ID']"
          component="fragment"
        >
          <patient-insurance
            :patient-id="patientId"
          />
        </is-granted>

        <patient-card-permissions
          v-if="!nfzConfiguration"
          :patient="patient"
        />

        <is-granted
          :privileges="['GET_PATIENT_INDICATORS_LIST']"
          component="fragment"
        >
          <patient-card-indicators
            :patient-id="patientId"
          />
        </is-granted>

        <patient-card-nfz-branch
          ref="patientNfzBranch"
          :patient-id="patientId"
        />

        <patient-card-leading-specialist
          :patient-id="patientId"
        />

        <is-granted
          :privileges="['GET_RECOVERY_ASSISTANTS_LIST']"
          component="fragment"
        >
          <patient-card-recovery-assistants
            :patient-id="patientId"
          />
        </is-granted>
      </div>
    </div>

    <div
      v-if="nfzConfiguration"
      class="row"
    >
      <div class="col-8">
        <is-granted
          :privileges="['MEDICAL_DATA_AUTHORIZATIONS_LIST']"
          component="fragment"
        >
          <patient-card-medical-data-authorizations
            :patient-id="patientId"
            class="col-12"
          />
        </is-granted>
      </div>
      <div class="col-4">
        <patient-card-permissions
          v-if="nfzConfiguration"
          :patient="patient"
        />
      </div>
    </div>
    <div class="row">
      <is-granted
        :privileges="['PROCEDURE_LIST']"
        component="fragment"
      >
        <patient-card-procedures
          :patient-id="patientId"
          class="col-12"
        />
      </is-granted>

      <is-granted
        :privileges="['PATIENT_IPZ_LIST']"
        :subject="patientId"
        component="fragment"
      >
        <patientCardIpz
          ref="patientCardIpz"
          :patient-id="patientId"
          class="col-12"
        />
      </is-granted>

      <appointment-tabs-card
        ref="appointmentTabsCard"
        :filters="{
          patientId,
          statuses: appointmentStatuses,
          workerIds: null,
          orderDesc: true,
          continuousStay: null
        }"
        :columns="['hour','date', 'insurance', 'specialistTypeStatus','options']"
        :patient-id="patientId"
        :query="{
          orderDesc: atcDesc,
          page: atcPage,
          perPage: atcPerPage,
          statuses: atcStatuses,
          continuousStay: atcContinuousStay
        }"
        class="col-12"
        title="Terminarz wizyt"
      >
        <template #control>
          <appointment-tabs-card-header-buttons
            :patient-id="patientId"
          />
        </template>
      </appointment-tabs-card>

      <is-granted
        :privileges="['FIND_PERSONS_ELIGIBLE_BY_PATIENT_ID']"
        component="fragment"
      >
        <patient-card-persons-eligible
          :patient-id="patientId"
          class="col-12"
        />
      </is-granted>

      <is-granted
        v-if="!nfzConfiguration"
        :privileges="['MEDICAL_DATA_AUTHORIZATIONS_LIST']"
        component="fragment"
      >
        <patient-card-medical-data-authorizations
          :patient-id="patientId"
          class="col-12"
        />
      </is-granted>

      <is-granted
        :privileges="['PATIENT_CONTINUOUS_STAY_LIST']"
        component="fragment"
      >
        <patient-card-continuous-stays
          :patient-id="patientId"
          class="col-12"
        />
      </is-granted>

      <is-granted
        :privileges="['GET_TEST_RESULTS_LIST']"
        component="fragment"
      >
        <test-results-card
          class="col-12"
          :patient-id="patientId"
          :status="[TestResultStatus.OPENED, TestResultStatus.FINISHED]"
        />
      </is-granted>

      <is-granted
        :privileges="['CREATE_REQUEST_MEDICAL_RECORDS']"
        component="fragment"
      >
        <request-medical-records-list
          :patient="patient"
          class="col-12"
        />
      </is-granted>

      <patient-card-chronic-diseases
        :patient-id="patientId"
        class="col-12"
      />

      <is-granted
        :privileges="['GET_PSYCHOLOGICAL_DIAGNOSIS_RESULT_LIST']"
        component="fragment"
      >
        <patient-card-psychological-diagnosis
          :patient-id="patientId"
          class="col-12"
        />
      </is-granted>

      <is-granted
        :privileges="['FIND_PRESCRIPTIONS_BY_PATIENT_ID']"
        component="fragment"
      >
        <prescriptions
          :patient-id="patientId"
          class="col-12"
        />
      </is-granted>

      <is-granted
        :privileges="['PATIENT_REFERRALS_LIST']"
        component="fragment"
      >
        <patient-card-referrals
          :patient-id="patientId"
          class="col-12"
        />
      </is-granted>

      <is-granted
        :privileges="['FIND_ACCEPTED_REFERRAL']"
        component="fragment"
      >
        <patient-card-accepted-referrals
          :patient-id="patientId"
          class="col-12"
        />
      </is-granted>

      <is-granted
        :privileges="['GET_PATIENT_SURVEYS_LIST']"
        component="fragment"
      >
        <patient-card-surveys
          ref="patientCardSurveys"
          :patient-id="patientId"
          class="col-12"
        />
      </is-granted>

      <patient-psychiatric-hospitalizations
        :patient-id="patientId"
        class="col-12"
      />

      <is-granted
        component="fragment"
        :privileges="['FIND_SOCIAL_ACTIVITIES']"
      >
        <social-activity-page
          class="col-12"
          :patient-id="patientId"
        />
      </is-granted>

      <patient-attachments-card
        ref="patientAttachmentsCard"
        :patient-id="patientId"
        :scope="['ambulatory']"
        class="col-12"
      />
    </div>
  </div>
</template>
<script>
import PatientCardPersonalData from "../../components/Patient/PatientCard/PatientCardPersonalData.vue";
import PatientCardLivingAddress from "../../components/Patient/PatientCard/PatientCardLivingAddress.vue";
import PatientCardCorrespondenceAddress
  from "../../components/Patient/PatientCard/PatientCardCorrespondenceAddress.vue";
import PatientCardAdditionalData from "../../components/Patient/PatientCard/PatientCardAdditionalData.vue";
import patientCardIpz from "../../components/Patient/PatientCard/PatientCardIpz.vue";
import AppointmentStatus from "../../components/Appointment/AppointmentStatus";
import PatientCardChronicDiseases from "../../components/Patient/PatientCard/ChronicDisease/PatientCardChronicDiseases";
import PatientCardMedicalDataAuthorizations
  from "../../components/Patient/PatientCard/PatientCardMedicalDataAuthorizations.vue";
import PatientCardReferrals from "../../components/Patient/PatientCard/PatientCardReferrals.vue";
import IsGranted from "../../components/IsGranted";
import PatientAttachmentsCard from "../../components/Patient/PatientCard/Attachment/PatientAttachmentsCard";
import read from "../../rest/read";
import PatientCardIndicators from "../../components/Patient/PatientCard/Indicators/PatientCardIndicators";
import PatientCardCaseManager from "../../components/Patient/PatientCard/PatientCardCaseManager";
import AppointmentTabsCard from "../../components/Appointment/AppointmentTabsCard";
import PatientCardPersonsEligible
  from "../../components/Patient/PatientCard/EligiblePersons/PatientCardEligiblePersons";
import PatientCardNfzBranch from "../../components/Patient/PatientCard/PatientCardNfzBranch";
import {mapActions, mapState} from "vuex";
import RequestMedicalRecordsList
  from "../../components/Patient/PatientCard/RequestMedicalRecords/RequestMedicalRecordsList";
import PatientCardContinuousStays from "../../components/Patient/PatientCard/ContinuousStay/PatientCardContinuousStays";
import PatientCardSurveys from "../../components/Patient/PatientCard/Surveys/PatientCardSurveys.vue";
import PatientCardMobileTeam from "../../components/Patient/PatientCard/PatientCardMobileTeam";
import PatientCardSupportNetwork from "../../components/Patient/PatientCard/PatientCardSupportNetwork";
import PatientCardLaborMarketStatus from "../../components/Patient/PatientCard/PatientCardLaborMarketStatus";
import PatientCardParticipantStatus from "../../components/Patient/PatientCard/PatientCardParticipantStatus";
import LastEditedAppointment from "../../components/Appointment/LastEditedAppointment";
import PatientCardLeadingSpecialist from "../../components/Patient/PatientCard/PatientCardLeadingSpecialist";
import Prescriptions from "../../components/Patient/PatientCard/Prescriptions";
import PatientCardProcedures from "../../components/Patient/PatientCard/Procedures/PatientCardProcedures";
import PatientPsychiatricHospitalizations
  from "../../components/Patient/PatientCard/PsychiatricHospitalizations/PatientPsychiatricHospitalizations";
import PatientInsurance from "../../components/Patient/Insurance/PatientInsurance"
import PatientCardHospitalizationsData from "../../components/Patient/PatientCard/PatientCardHospitalizationsData";
import DataGapsInformation from "../../components/Patient/DataGapsInformation";
import PatientCardRecoveryAssistants from "../../components/Patient/PatientCard/PatientCardRecoveryAssitants";
import PatientCardIpzLink from "../../components/Patient/PatientCard/PatientCardIpzLink";
import AppointmentTabsCardHeaderButtons from "../../components/Appointment/AppointmentTabsCardHeaderButtons";
import SocialActivityPage from "@/views/SocialActivity/SocialActivityPage";
import PatientCardPermissions from "@/components/Patient/PatientCard/Permissions/PatientCardPermissions";
import TestResultsCard from "@/components/TestResults/TestResultsCard";
import {TestResultStatus} from "@/types/TestResult";
import PatientBmiAlert from "@/components/Patient/PatientBmiAlert";
import IdentificationDocumentData from "../../components/Patient/PatientCard/IdentificationDocumentData";
import PatientCardRegisteredAddress from "../../components/Patient/PatientCard/PatientCardRegisteredAddress";
import PatientCardAcceptedReferrals from "@/components/Patient/PatientCard/PatientCardAcceptedReferrals";
import PatientCardTreatmentStatus from "../../components/Patient/PatientCard/PatientCardTreatmentStatus.vue";
import PatientCardPsychologicalDiagnosis from "../../components/Patient/PatientCard/PatientCardPsychologicalDiagnosis.vue";
import PatientCardAdultType from "@/components/Patient/PatientCard/PatientCardAdultType";
import PatientCardContinuingEducation from "../../components/Patient/PatientCard/PatientCardContinuingEducation";

export default {
  name: "PatientCard",
  components: {
    PatientCardAdultType,
    PatientCardPsychologicalDiagnosis,
    PatientCardAcceptedReferrals,
    PatientCardRegisteredAddress,
    IdentificationDocumentData,
    PatientBmiAlert,
    TestResultsCard,
    PatientCardPermissions,
    SocialActivityPage,
    AppointmentTabsCardHeaderButtons,
    PatientCardIpzLink,
    PatientCardRecoveryAssistants,
    DataGapsInformation,
    PatientCardProcedures,
    PatientCardLeadingSpecialist,
    LastEditedAppointment,
    PatientCardParticipantStatus,
    PatientCardLaborMarketStatus,
    PatientCardHospitalizationsData,
    PatientCardContinuousStays,
    RequestMedicalRecordsList,
    PatientCardPersonsEligible,
    AppointmentTabsCard,
    PatientAttachmentsCard,
    PatientCardPersonalData,
    PatientCardLivingAddress,
    PatientCardCorrespondenceAddress,
    PatientCardAdditionalData,
    patientCardIpz,
    PatientCardChronicDiseases,
    PatientCardMedicalDataAuthorizations,
    PatientCardIndicators,
    PatientCardReferrals,
    IsGranted,
    PatientCardCaseManager,
    PatientCardNfzBranch,
    PatientCardSurveys,
    PatientCardMobileTeam,
    PatientCardSupportNetwork,
    Prescriptions,
    PatientPsychiatricHospitalizations,
    PatientInsurance,
    PatientCardTreatmentStatus,
    PatientCardContinuingEducation
  },
  props: {
    patientId: {type: String, required: true},
    atcDesc: {type: Boolean, default: null},
    atcPage: {type: Number, default: null},
    atcPerPage: {type: Number, default: null},
    atcStatuses: {type: Array, default: null},
    atcContinuousStay: {type: Boolean, default: null},
  },
  data() {
    const patient = {patientId: this.patientId, displayName: null, archivalUrl: null};
    return {
      patient,
      livingAddress: {},
      TestResultStatus,
    };
  },
  computed: {
    ...mapState({
      patientAnonymous: state => state.clinicParameters.parameters.patientAnonymous,
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration
    }),
    appointmentStatuses() {
      return AppointmentStatus.getNames();
    },
    hash() {
      return this.$route.hash.substring(1);
    },
  },
  watch: {
    hash: {
      immediate: true,
      handler(val) {
        if (val.length) {
          this.scrollToElement(val);
        }
      },
    },
  },
  async mounted() {
    await this.loadData();
    this.scrollToElement(this.hash);
  },
  methods: {
    ...mapActions({
      setStickyPatient: "stickyPatient/set",
    }),
    async loadData() {
      const {patientId} = this;
      const {
        displayName,
        fileNumber,
        pesel,
        archivalUrl,
      } = await read(`/api/patient/${patientId}/details`);

      this.patient = {
        patientId,
        displayName,
        archivalUrl,
      };

      const displayNameWithPesel = pesel ? `${displayName} (${pesel})` : displayName;
      const stickyDisplayName = (!this.patientAnonymous && fileNumber)
        ? `${displayNameWithPesel} | ${fileNumber}`
        : displayNameWithPesel;
      this.setStickyPatient({
        patientId,
        displayName: stickyDisplayName,
      });
    },
    scrollToElement(ref = null){
      if(ref) {
        setTimeout(() => {
          if(this.$refs[ref]) {
            this.scrollIntoElement(this.$refs[ref].$el);
          } else {
            setTimeout(
              () => this.scrollToElement(ref),
              500
            );
          }
        }, 1000);
        return;
      }

      setTimeout(() => window.scrollTo(0, 0), 500);
    },
    scrollIntoElement(element){
      element.scrollIntoView({behavior: "smooth", block: "start", inline: "center"});
    },
    reloadPatientGapInformationData(){
      this.$refs.dataGapsInformation.loadData();
    },
    livingAddressUpdated(){
      this.$refs.dataGapsInformation.loadData();
      if(this.nfzConfiguration){
        this.$refs.patientNfzBranch.fetchData();
      }
    }
  },
}
</script>
