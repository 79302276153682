<template>
  <ul class="list-group">
    <is-granted
      :privileges="['APPOINTMENT_DETAILS']"
      :subject="appointmentId"
      class="d-block mr-0"
      component="li"
    >
      <b-btn
        block
        class="m-1"
        size="sm"
        variant="info"
        @click="openAppointmentInfoModal"
      >
        <i class="fas fa-info-circle" />
        Szczegóły wizyty
      </b-btn>
    </is-granted>
    <is-granted
      :privileges="['OPEN_APPOINTMENT']"
      :subject="appointmentId"
      class="d-block mr-0"
      component="li"
    >
      <b-btn
        block
        class="m-1"
        size="sm"
        variant="success"
        @click="openOpenAppointmentModal"
      >
        <i class="fa fa-door-open" />
        Otwórz wizytę
      </b-btn>
    </is-granted>
    <is-granted
      :privileges="['REOPEN_CANCELED_APPOINTMENT']"
      :subject="appointmentId"
      class="d-block mr-0"
      component="li"
    >
      <b-btn
        block
        class="m-1"
        size="sm"
        variant="success"
        @click="openReopenCanceledAppointmentModal"
      >
        <i class="fas fa-reply" />
        Otwórz ponownie
      </b-btn>
    </is-granted>
    <is-granted
      :privileges="['SHOW_APPOINTMENT_DOCUMENT']"
      :subject="appointmentId"
      class="d-block mr-0"
      component="li"
    >
      <b-btn
        block
        class="m-1 mr-0"
        size="sm"
        :variant="'opened' === status ? 'success':'secondary'"
        @click="showDocument"
      >
        <i class="fa fa-eye" />
        {{ status === 'opened' ? 'Otwórz wizytę' : 'Pokaż dokument' }}
      </b-btn>
    </is-granted>
    <is-granted
      :privileges="['CHECK_IN_APPOINTMENT', 'WITHDRAW_APPOINTMENT_CHECK_IN_COMMAND', 'RESCHEDULE_APPOINTMENT',
                    'UPDATE_APPOINTMENT_PARAMETERS', 'GET_APPOINTMENT_WORKERS_WITH_TREATMENT',
                    'DELETE_APPOINTMENT_PATIENTS', 'ADD_PATIENTS_TO_APPOINTMENT', 'PATIENT_CARD',
                    'CANCEL_APPOINTMENT_DEFERRED', 'CANCEL_APPOINTMENT']"
      :subject="appointmentId"
      component="fragment"
    >
      <b-dd
        block
        class="mt-1 ml-1 w-100"
        size="sm"
        text="Więcej"
        variant="primary"
      >
        <is-granted
          :privileges="['CHECK_IN_APPOINTMENT']"
          :subject="appointmentId"
          component="fragment"
        >
          <b-dd-item-btn @click="openCheckInModal">
            <i class="fa fa-address-card text-primary" />
            Potwierdź wizytę
          </b-dd-item-btn>
        </is-granted>
        <is-granted
          :privileges="['WITHDRAW_APPOINTMENT_CHECK_IN_COMMAND']"
          :subject="appointmentId"
          component="fragment"
        >
          <b-dd-item-btn @click="openCheckInWithdrawalModal">
            <i class="fa fa-history" />
            Cofnij potwierdzenie wizyty
          </b-dd-item-btn>
        </is-granted>
        <is-granted
          :privileges="['RESCHEDULE_APPOINTMENT']"
          :subject="appointmentId"
          component="fragment"
        >
          <b-dd-item-btn
            :disabled="scheduleDataLoading"
            @click="editAppointment"
          >
            <i
              :class="scheduleDataLoading ? 'fa-spin fa-spinner' : 'fa-pencil-alt'"
              class="fas"
            />
            Edytuj wizytę
          </b-dd-item-btn>
        </is-granted>
        <is-granted
          :privileges="['UPDATE_APPOINTMENT_PARAMETERS']"
          :subject="appointmentId"
          component="fragment"
        >
          <b-dd-item-btn @click="openChangeAppointmentParametersModal">
            <i class="fa fa-cog text-pink" />
            Określ parametry wizyty
          </b-dd-item-btn>
        </is-granted>
        <is-granted
          v-if="!continuousStay"
          :privileges="['GET_APPOINTMENT_WORKERS_WITH_TREATMENT']"
          :subject="appointmentId"
          component="fragment"
        >
          <b-dd-item-btn @click="openReportTreatmentTypeModal">
            <i class="fa fa-flag text-primary" />
            Raportuj typ usługi
          </b-dd-item-btn>
        </is-granted>
        <is-granted
          :privileges="['CHANGE_APPOINTMENT_DATE']"
          :subject="appointmentId"
          component="fragment"
        >
          <b-dd-item-btn @click="openEditAppointmentDateModal">
            <i class="fas fa-clock text-primary" />
            Zmień datę wizyty
          </b-dd-item-btn>
        </is-granted>
        <is-granted
          v-if="removePatientAllowedFromGroup"
          :privileges="['DELETE_APPOINTMENT_PATIENTS']"
          :subject="appointmentId"
          component="fragment"
        >
          <b-dd-item-btn @click="openRemovePatientFromAppointmentModal">
            <i class="fa fa-user-minus text-pink" />
            Wypisz pacjenta
          </b-dd-item-btn>
        </is-granted>
        <is-granted
          v-if="changePatientsAllowedFromGroup"
          :privileges="['ADD_PATIENTS_TO_APPOINTMENT']"
          :subject="appointmentId"
          component="fragment"
        >
          <b-dd-item-btn @click="openChangePatientsModal">
            <i class="fa fa-user-cog text-success" />
            Dodaj/Usuń pacjentów
          </b-dd-item-btn>
        </is-granted>
        <is-granted
          v-if="!patientId && patients.length === 1"
          :privileges="['PATIENT_CARD']"
          :subject="patients[0].patientId"
          component="fragment"
        >
          <b-dd-text>
            <router-link
              class="dropdown-item"
              :to="{name: 'patientCard', params: {patientId: patients[0].patientId}}"
            >
              <i class="fa fa-user" />
              Karta pacjenta
            </router-link>
          </b-dd-text>
        </is-granted>
        <template v-if="!group || cancelAllowedFromGroup">
          <is-granted
            :privileges="['CANCEL_APPOINTMENT_DEFERRED']"
            :subject="appointmentId"
            component="fragment"
          >
            <b-dd-item-btn @click="openCancelAppointmentDeferredModal">
              <i class="fa fa-ban text-pink" />
              Odwołaj wizytę
            </b-dd-item-btn>
          </is-granted>
          <is-granted
            :privileges="['CANCEL_APPOINTMENT']"
            :subject="appointmentId"
            component="fragment"
          >
            <b-dd-item-btn @click="openCancelAppointmentModal">
              <i class="fa fa-ban text-pink" />
              Odwołaj wizytę
            </b-dd-item-btn>
          </is-granted>
        </template>
      </b-dd>
    </is-granted>
  </ul>
</template>
<script>

import IsGranted from "../../IsGranted";
import {appointmentItemActionsMixin} from "../../../mixins/appointmentItemActionsMixin";

export default {
  name: "AppointmentItemActionsDropdown",
  components: {IsGranted,},
  mixins: [appointmentItemActionsMixin],
};
</script>
<style scoped lang="scss">
  @import "../../../styles/variables";

  ul li {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;

    &:empty {
      margin-right: 0;
    }
  }

  .btn-pink {
    background-color: $pink;
    color: $white;

    &:focus, &.focus,
    &:active, &.active,
    &:hover, &.hover {
      background-color: darken($pink, 5%);
    }
  }

  .appointment-action {
    white-space: normal;
  }
</style>
