<template>
  <div class="card-box">
    <form
      class="form-row"
      @submit.prevent="submit"
    >
      <b-form-group
        class="col-lg-6 col-xl-3"
        label="Specjalista"
      >
        <worker-select
          v-model="selectedWorker"
          :disabled="userCannotChooseWorker"
        />
      </b-form-group>

      <b-form-group
        class="col-lg-6 col-xl-3"
        label="Wybrany tydzień"
      >
        <week-date-picker
          v-model="startDate"
        />
      </b-form-group>

      <b-form-group
        class="col-lg-6 col-xl-3"
        label="Pokaż godziny"
      >
        <button
          class="btn m-r-10"
          :class="dayView ? 'btn-secondary' : 'btn-outline-dark'"
          type="button"
          :disabled="!selectedWorker"
          :style="{pointerEvents: (selectedWorker && !dayView) ? 'auto' : 'none'}"
          @click="setDayView(true)"
        >
          08-20
        </button>

        <button
          class="btn"
          :class="!dayView ? 'btn-secondary' : 'btn-outline-dark'"
          type="button"
          :disabled="!selectedWorker"
          :style="{pointerEvents: (selectedWorker && dayView) ? 'auto' : 'none'}"
          @click="setDayView(false)"
        >
          00-24
        </button>
      </b-form-group>

      <div class="col-lg-12 col-xl-3" />

      <b-form-group
        label="Typ personelu"
        class="col-lg-6 col-xl-3"
      >
        <nfz-worker-type-select
          v-model="nfzWorkerType"
          :worker-id="selectedWorker ? selectedWorker.workerId : null"
          :disabled="!selectedWorker"
        />
      </b-form-group>

      <b-form-group
        class="col-lg-6 col-xl-5"
        label="Komórka organizacyjna"
      >
        <branch-select
          v-model="branchId"
          :check-if-any-branch-exists="true"
        />
      </b-form-group>

      <div class="col text-right no-label mb-2">
        <button
          class="btn btn-danger mx-1"
          type="button"
          @click="clearFilters"
        >
          <i class="fa fa-ban" />
          Wyczyść
        </button>

        <button
          class="btn btn-primary mx-1"
          :disabled="!selectedWorker"
        >
          <i class="fa fa-search" /> Szukaj
        </button>
      </div>
    </form>

    <div class="d-flex justify-content-between align-items-start flex-nowrap mb-3">
      <button
        class="btn btn-secondary"
        type="button"
        :disabled="!isPrevNextWeekButtonEnabled"
        @click="previousWeek"
      >
        <i class="fas fa-backward" />
        Poprzedni tydzień
      </button>

      <button
        class="btn btn-secondary"
        type="button"
        :disabled="!isPrevNextWeekButtonEnabled"
        @click="nextWeek"
      >
        Następny tydzień
        <i class="fas fa-forward" />
      </button>
    </div>
  </div>
</template>

<script>
import WorkerSelect from "../Worker/WorkerSelect.vue";
import WeekDatePicker from "../Form/DatePicker/WeekDatePicker.vue";
import parseDate from "../../utils/date/parseDate";
import moveDateByDays from "../../utils/moveDateByDays";
import stringifyDate from "../../utils/date/stringifyDate";
import {changePageQuery, updatePageQuery} from "../../utils/pageUrl/handlePageQuery";
import isEqual from "lodash/isEqual";
import {mapMutations, mapState} from "vuex";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import {isGranted} from "../../security/isGranted";
import {specialistRoles} from "../../utils/roles/specialistRoles";
import NfzWorkerTypeSelect from "@/components/Form/Select/NfzWorkerTypeSelect";
import BranchSelect from "@/components/Branch/BranchSelect";

export default {
  name: "RegistrationFilters",
  components: {
    WorkerSelect,
    WeekDatePicker,
    NfzWorkerTypeSelect,
    BranchSelect,
  },
  props: {
    filters: {type: Object, default: ()=>({})},
  },
  data() {
    const date = this.filters.start ? parseDate(this.filters.start) : this.getStartDate();

    return {
      startDate: date,
      selectedWorker: this.filters.selectedWorkerId,
      dayView: this.filters.dayView,
      nfzWorkerType: this.filters.nfzWorkerType,
      branchId: this.filters.branchId,
      currentUserIsSpecialist: true,
      userCannotChooseWorker: true
    };
  },
  computed: {
    ...mapState("currentUser", ["user"]),
    ...mapState("registrationFilters", ["savedFilters",]),
    startDateString() {
      return stringifyDate(this.startDate);
    },
    isPrevNextWeekButtonEnabled () {
      return this.selectedWorker && this.savedFilters && this.savedFilters.selectedWorkerId
    }
  },
  watch: {
    "filters.start" (val) {
      this.startDate = val ? parseDate(val) : this.getStartDate()
    },
    "filters.selectedWorkerId" (val) {
      this.selectedWorker = val
    },
    "filters.dayView" (val) {
      this.dayView = val
    },
    "filters.nfzWorkerType" (val) {
      this.nfzWorkerType = val
    },
    "filters.branchId" (val) {
      this.branchId = val
    },
    selectedWorker(val1, val2) {
      if (val1.workerId && val1.workerId === val2) return // on created
      this.nfzWorkerType = null
    }
  },
  async mounted () {
    this.currentUserIsSpecialist = await isGranted(specialistRoles);
    this.userCannotChooseWorker = await isGranted(specialistRoles.filter(spec => spec !== "ROLE_SPECIALIST_MANAGER"))
    if (!this.savedFilters && this.currentUserIsSpecialist) {
      this.selectedWorker = this.user
      this.submit()
    }
  },
  methods: {
    ...mapMutations("registrationFilters", ["saveRegistrationFilters",]),
    submit() {
      this.updateSchedule();
    },
    async updateSchedule() {
      const query = {
        start: this.startDateString,
        selectedWorkerId: this.selectedWorker.workerId || undefined,
        nfzWorkerType: this.nfzWorkerType || undefined,
        branchId: this.branchId || undefined,
        dayView: this.dayView
      }

      if (isEqual(this.$route.query, query)) {
        this.$emit("reload")
        return
      }
      await updatePageQuery(query)
      this.saveRegistrationFilters(this.filters);
    },
    async previousWeek() {
      this.startDate = moveDateByDays(this.startDate, -7);
      await this.updateSchedule()
    },
    async nextWeek() {
      this.startDate = moveDateByDays(this.startDate, 7);
      await this.updateSchedule()
    },
    setDayView (val) {
      this.dayView = val;
    },
    getStartDate(reset = false) {
      const date = (!reset && this.startDateString)
        ? parseDate(this.startDateString, DATE_FORMAT.DATE_TIME)
        : new Date();

      const day = date.getDay() || 7;
      date.setDate(date.getDate() + 1 - day);
      date.setHours(0, 0, 0, 0);
      return date;
    },
    async clearFilters() {
      this.selectedWorker = null
      this.nfzWorkerType = null
      this.branchId = null
      this.dayView = true
      this.startDate = this.getStartDate(true)

      let query = {
        start: this.startDateString,
      }

      if (this.currentUserIsSpecialist) {
        this.selectedWorker = this.user.workerId
        query = {
          ...query,
          selectedWorkerId: this.user.workerId,
        }
      }

      if (isEqual(this.$route.query, query)) {
        return
      }

      this.saveRegistrationFilters(query);
      await changePageQuery(query);
    }
  }
}
</script>
