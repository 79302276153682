<template>
  <div class="card-box">
    <div class="row">
      <div class="col-12">
        <b-form-group
          label="Komórka organizacyjna"
        >
          <branch-select
            v-model="branchIdForm"
            :disabled="readOnly"
            :check-if-any-branch-exists="true"
            :state="state('branchId')"
            display-inline-value
            emit-select-on-init
            @input="changeDocumentBranch"
            @select="selectDocumentBranch"
          />
          <error-message
            :errors="errors"
            field="branchId"
          />
        </b-form-group>
      </div>

      <div class="col-6">
        <b-form-group
          label="Typ personelu"
        >
          <nfz-worker-type-select
            v-model="nfzWorkerTypesForm"
            :state="state('nfzWorkerTypes')"
            :worker-id="workerId"
            :disabled="readOnly"
            @input="changeNfzWorkerTypes"
          />
          <error-message
            :errors="errorsData"
            field="nfzWorkerTypes"
          />
        </b-form-group>
      </div>

      <div class="col-6">
        <b-form-group label="Usługa (przed wybraniem usługi, proszę wybrać typ personelu)">
          <treatment-type-select
            v-model="treatmentTypeIdForm"
            :worker-id="workerId"
            :state="state('treatmentType')"
            :disabled="disableTreatmentType || readOnly"
            :nfz-worker-types="[nfzWorkerTypesForm]"
            :branch-id="branchIdForm"
            :patient-id="patient.patientId"
            check-if-any-treatment-type-exists
            no-options-text="Specjalista nie wykonuje żadnych usług"
            preselect-undefined
            undefined-on-null
            @input="changeTreatmentType"
          />
          <error-message
            :errors="errorsData"
            field="treatmentType"
          />
        </b-form-group>
      </div>
      <div
        v-if="treatmentTypeIntervention"
        class="col-6"
      >
        <b-form-group label="Alternatywna usługa">
          <nfz-alternative-treatment-type-select
            v-model="alternativeTreatmentTypeForm"
            :multiple="true"
            :state="state('alternativeTreatmentType')"
            :disabled="readOnly"
            @input="changeAlternativeTreatmentType"
          />
          <error-message
            :errors="errorsData"
            field="alternativeTreatmentType"
          />
        </b-form-group>
      </div>
      <div
        v-else-if="false === treatmentTypeIntervention"
        class="col-6"
      >
        <b-form-group label="ICD9">
          <icd9-code-select
            v-model="icd9Form"
            :multiple="true"
            :state="state('icd9')"
            :disabled="readOnly"
            disable-not-choosable
            @input="changeIcd9"
          />
          <error-message
            :errors="errorsData"
            field="icd9"
          />
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group label="Typ wizyty">
          <appointment-type-select
            v-model="appointmentTypeForm"
            :state="state('appointmentType')"
            :disabled="readOnly"
            @input="changeAppointmentType"
          />
          <error-message
            :errors="errorsData"
            field="appointmentType"
          />
        </b-form-group>
      </div>

      <div class="col-6">
        <b-form-group label="Tryb przyjęcia">
          <admission-mode-select
            v-model="admissionModeForm"
            :state="state('admissionMode')"
            :disabled="readOnly"
            @input="changeAdmissionMode"
          />
          <error-message
            :errors="errorsData"
            field="admissionMode"
          />
        </b-form-group>
      </div>

      <div class="col-6">
        <b-form-group label="Kategoria świadczeniobiorcy">
          <beneficiary-category-select
            v-model="beneficiaryCategoryForm"
            :state="state('beneficiaryCategory')"
            :disabled="readOnly"
            @input="changeBeneficiaryCategory"
          />
          <error-message
            :errors="errorsData"
            field="beneficiaryCategory"
          />
        </b-form-group>
      </div>

      <div class="col-6">
        <b-form-group label="Sposób dalszej opieki nad pacjentem">
          <treatment-follow-up-select
            v-model="treatmentFollowUpForm"
            :state="state('treatmentFollowUp')"
            :disabled="readOnly"
            @input="changeTreatmentFollowUp"
          />
          <error-message
            :errors="errorsData"
            field="treatmentFollowUp"
          />
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>

import TreatmentTypeSelect from "../../components/TreatmentType/TreatmentTypeSelect";
import NfzWorkerTypeSelect from "../../components/Form/Select/NfzWorkerTypeSelect";
import Icd9CodeSelect from "../../components/Referrals/Icd9CodeSelect";
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import read from "../../rest/read";
import NfzAlternativeTreatmentTypeSelect from "../../components/Form/Select/NfzAlternativeTreatmentTypeSelect";
import AppointmentTypeSelect from "../../components/Appointment/AppointmentType/AppointmentTypeSelect";
import AdmissionModeSelect from "../../components/Appointment/AdmissionMode/AdmissionModeSelect";
import BeneficiaryCategorySelect from "../../components/Appointment/BeneficiaryCategory/BeneficiaryCategorySelect";
import BranchSelect from "../../components/Branch/BranchSelect";
import TreatmentFollowUpSelect from "@/components/Appointment/TreatmentFollowUp/TreatmentFollowUpSelect";

export default {
  name: "AppointmentNfzConfigurationBox",
  components: {
    TreatmentFollowUpSelect,
    BranchSelect,
    BeneficiaryCategorySelect,
    AdmissionModeSelect,
    AppointmentTypeSelect,
    NfzAlternativeTreatmentTypeSelect,
    Icd9CodeSelect,
    TreatmentTypeSelect,
    NfzWorkerTypeSelect,
    ErrorMessage
  },
  props: {
    treatmentTypeId: {type: String, default: null},
    workerId: {type: String, default: null},
    icd9: {type: Array, default: () => []},
    nfzWorkerTypes: {type: Array, default: () => []},
    errors: {type: Array,  default: () => []},
    readOnly: {type: Boolean, required: true},
    patient: {type: Object, default: null},
    alternativeTreatmentType: {type: Array, default: () => []},
    appointmentType: {type: String, default: null},
    admissionMode: {type: Number, default: null},
    beneficiaryCategory: {type: String, default: null},
    treatmentFollowUp: {type: String, default: null},
    branchId: {type: String, default: null}
  },
  data() {
    return {
      nfzWorkerTypesForm: this.nfzWorkerTypes[0],
      icd9Form: this.icd9,
      treatmentTypeIdForm: this.treatmentTypeId,
      errorsData: [],
      interventionTreatmentTypes: [],
      treatmentTypeIntervention: undefined,
      alternativeTreatmentTypeForm: this.alternativeTreatmentType,
      appointmentTypeForm: this.appointmentType,
      admissionModeForm: this.admissionMode,
      beneficiaryCategoryForm: this.beneficiaryCategory,
      treatmentFollowUpForm: this.treatmentFollowUp,
      branchIdForm: this.branchId,
    }
  },
  computed: {
    disableTreatmentType() {
      return this.nfzWorkerTypesForm.length === 0;
    }
  },
  watch:{
    workerId(){
      this.resetData();
    },
    errors(){
      this.errorsData = this.errors;
    },
    patient(){
      this.resetData();
    },
  },
  async mounted() {
    await this.fetchInterventionTreatmentTypes();
    this.isTreatmentTypeIntervention(this.treatmentTypeId);
  },
  methods: {
    async fetchInterventionTreatmentTypes(){
      const {items} = await read("/api/treatment-types", {
        active: 1,
        withoutUndefined: true,
        flatNames: true,
        tags: ["nfz-intervention"]
      });
      this.interventionTreatmentTypes = items;
    },
    isTreatmentTypeIntervention(treatmentTypeId){
      const isIntervention = this.interventionTreatmentTypes.filter(tt => tt.value === treatmentTypeId);
      this.treatmentTypeIntervention = isIntervention.length > 0;
    },
    async changeTreatmentType(value){
      this.isTreatmentTypeIntervention(value);
      this.$emit("changeTreatmentType", value);
    },
    changeDocumentBranch(value){
      this.treatmentTypeIdForm = null;
      this.$emit("changeTreatmentType", null);
      this.$emit("changeDocumentBranch", value);
    },
    selectDocumentBranch(option) {
      const codeForHasPsychologicalDiagnosis = "1791" // https://gitlab.edgeteq.com/auxilio/mgmt/-/issues/346
      this.$emit("togglePsychologicalDiagnosisModuleVisibility", option && option.codeEight === codeForHasPsychologicalDiagnosis)
    },
    changeNfzWorkerTypes(value){
      this.treatmentTypeIdForm = null;
      this.$emit("changeTreatmentType", null);
      this.$emit("changeNfzWorkerTypes", [value]);
    },
    changeIcd9(value){
      this.$emit("changeIcd9", value.map(item => item.code));
    },
    changeAlternativeTreatmentType(value){
      this.$emit("changeAlternativeTreatmentType", value);
    },
    changeAppointmentType(value) {
      this.$emit("changeAppointmentType", value);
    },
    changeAdmissionMode(value) {
      this.$emit("changeAdmissionMode", value)
    },
    changeBeneficiaryCategory(value) {
      this.$emit("changeBeneficiaryCategory", value)
    },
    changeTreatmentFollowUp(value) {
      this.$emit("changeTreatmentFollowUp", value)
    },
    state(field) {
      return this.errorsData.find((error) => error.field === field) ? false : null;
    },
    resetData(){
      this.nfzWorkerTypesForm = this.nfzWorkerTypes[0];
      this.icd9Form = this.icd9;
      this.treatmentTypeIdForm = this.treatmentTypeId;
      this.alternativeTreatmentTypeForm = this.alternativeTreatmentType;
      this.branchIdForm = this.branchId;
      this.beneficiaryCategoryForm = this.beneficiaryCategory;
      this.treatmentFollowUpForm = this.treatmentFollowUp;
      this.admissionModeForm = this.admissionMode;
      this.appointmentTypeForm = this.appointmentType;
    }
  }
}
</script>

<style scoped>

</style>
