






























import read from "../../rest/read";
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import AppointmentDocumentStatisticsFilters
  from "../../components/Appointment/AppointmentDocumentStatistics/AppointmentDocumentStatisticsFilters.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import AppointmentDocumentStatisticsTabsCard
  from "../../components/Appointment/AppointmentDocumentStatistics/AppointmentDocumentStatisticsTabsCard.vue";
import {Pagination} from "../../types/Pagination";
import {
  AppointmentDocumentStatisticsTabStatus,
  StatisticAppointmentItem
} from "../../types/AppointmentDocumentStatistics";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../../components/Form/ErrorMessage.vue";

interface StatisticAppointmentData {
  items: StatisticAppointmentItem[],
  pagination: Pagination,
}

@Component({
  name: "AppointmentDocumentStatistics",
  components: {
    AppointmentDocumentStatisticsTabsCard,
    AppointmentDocumentStatisticsFilters,
    ErrorMessage,
  },
})

export default class AppointmentDocumentStatistics extends mixins(ErrorsMixin) {
  @Prop({type: Number, default: 1}) readonly adsPage!: number;
  @Prop({type: Number, default: 50}) readonly adsPerPage!: number;
  @Prop({
    type: String,
    default: AppointmentDocumentStatisticsTabStatus.to_send
  }) readonly status!: AppointmentDocumentStatisticsTabStatus;
  @Prop({type: String, default: null}) readonly fromDate!: string;
  @Prop({type: String, default: null}) readonly toDate!: string;
  @Prop({type: Array, default: null}) readonly treatmentTypes!: string[];
  @Prop({type: String, default: null}) readonly patientId!: string;
  @Prop({type: Array, default: null}) readonly workers!: string[];
  @Prop({type: Array, default: null}) readonly branches!: string[];
  @Prop({type: Number, default: null}) readonly visitId!: number;
  @Prop({type: Number, default: null}) readonly sessionId!: number;

  private DEFAULT_ADS_PER_PAGE = 50;
  private isLoading: boolean = true;
  private errorMessage: string|null = null;

  public pagination: Pagination = {
    perPage: this.adsPerPage || this.DEFAULT_ADS_PER_PAGE,
    currentPage: this.adsPage || 1,
    totalRows: 0,
  };
  public items: StatisticAppointmentItem[] = [];

  @Watch("$route") async onFiltersChange(): Promise<void> {
    this.pagination = {
      ...this.pagination,
      perPage: this.adsPerPage || this.DEFAULT_ADS_PER_PAGE,
      currentPage: this.adsPage || 1,
    }
    await this.fetchEntries()
  }

  async searchByErrorName(value){
    this.errorMessage = value
    await this.fetchEntries()
  }

  async fetchEntries() {
    this.isLoading = true;
    this.$refs.appointmentDocumentStatisticsTabsCard.deselectAll();

    try {
      const response = await read<StatisticAppointmentData>("/api/appointment/document/statistics", {
        status: this.status || AppointmentDocumentStatisticsTabStatus.to_send,
        page: this.pagination.currentPage,
        perPage: this.pagination.perPage,
        fromDate: this.fromDate,
        toDate: this.toDate,
        treatmentTypes: this.treatmentTypes,
        patientId: this.patientId,
        workers: this.workers,
        branches: this.branches,
        visitId: this.visitId,
        sessionId: this.sessionId,
        errorMessage: this.errorMessage
      });

      this.pagination = response.pagination;
      this.items = response.items;
    } catch (e) {
      this.errors = processResponseException(e)
    }

    this.isLoading = false;
  }
}

