<template>
  <b-modal
    :id="`updateContinuousStayDailyDescriptionModal-${description.continuousStayDailyDescriptionId}`"
    title="Edycja opisu dziennego"
    hide-footer
    no-close-on-backdrop
    title-tag="h3"
    size="lg"
    @hide="resetModalData"
  >
    <continuous-stay-specialists-description-form
      :description="description"
      :errors="errors"
      :date-edit="true"
      @update="updateForm"
    />
    <b-form-group
      id="updateReason"
      label="Powód edycji"
      label-for="updateReason"
    >
      <textarea-counter
        v-model="updateReason"
        :max-characters-count="1000"
        :state="state('updateReason')"
        rows="3"
      />
      <error-message
        :errors="errors"
        field="updateReason"
      />
    </b-form-group>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Zamknij
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary"
        @click="save"
      >
        <i
          v-show="!loading"
          class="fas fa-save"
        />
        <i
          v-show="loading"
          class="fas fa-spin fa-spinner"
        />
        Zapisz
      </button>
    </div>
  </b-modal>
</template>

<script>
import ContinuousStaySpecialistsDescriptionForm from "./ContinuousStaySpecialistsDescriptionForm";
import stringifyDate from "../../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";
import processResponseException from "../../../../utils/errors/processResponseException";
import modify from "../../../../rest/modify";
import ErrorMessage from "../../../Form/ErrorMessage";
import TextareaCounter from "../../../Form/Textarea/TextareaCounter";

export default {
  name: "ContinuousStayUpdateDailySpecialistsDescriptionModal",
  components: {
    ContinuousStaySpecialistsDescriptionForm,
    ErrorMessage,
    TextareaCounter
  },
  props: {
    description: {type: Object, required: true},
    continuousStayId: {type: String, required: true}
  },
  data() {
    return {
      formData: {
        ...this.description,
        patientNotShowUp: !this.description.patientPresence
      },
      errors: [],
      loading: false,
      updateReason: null,
      allowToSave: false
    }
  },
  methods: {
    async save(){
      this.loading = true;
      this.errors = [];
      try {
        const data = {
          continuousStayId: this.continuousStayId,
          workerCategory: this.formData.workerCategory,
          updateReason: null !== this.updateReason
            ? this.updateReason.trim()
            : this.updateReason,
          patientPresence: !this.formData.patientNotShowUp,
          stayDescription: this.formData.stayDescription,
          icd9codes: this.formData.icd9codes ? this.formData.icd9codes.map(item => item.code) : [],
          date: stringifyDate(this.formData.date)
        };
        const url = `/api/continuous-stays/${this.description.continuousStayDailyDescriptionId}/daily-descriptions`;
        await modify(url, data);
        this.$emit("updateList");
        this.closeModal();
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    updateForm(value){
      this.formData = value;
    },
    resetModalData(){
      this.formData = {
        ...this.description,
        patientNotShowUp: !this.description.patientPresence
      };
      this.updateReason = null;
      this.errors = [];
    },
    closeModal(){
      this.$bvModal.hide(
        `updateContinuousStayDailyDescriptionModal-${this.description.continuousStayDailyDescriptionId}`
      );
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    }
  }
}
</script>

<style scoped>

</style>
