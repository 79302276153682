









































































































import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import PrescriptionPatientDetails from "./PrescriptionPatientDetails.vue";
import PrescriptionDetails from "./PrescriptionDetails.vue";
import type {
  NfzBranch,
  Package,
  PackageData,
  Patient,
  PrescriptionData,
  PrescriptionStatus,
} from "../../types/Prescription";
import {PrescriptionDrug, PrescriptionStatuses} from "../../types/Prescription";
import create from "../../rest/create";
import {generateUuid} from "../../utils/uuid/generateUuid";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import processResponseException from "../../utils/errors/processResponseException";
import PrescriptionPackage from "./PrescriptionPackage.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";
import {Error} from "../../rest";
import read from "../../rest/read";
import eventBus from "../../eventBus";
import {isEmpty} from "lodash";
import {BModal} from "bootstrap-vue";

interface Prescription {
  type: string,
  number: string,
  createdAt: string,
  name: string,
  drugs: Array<PrescriptionDrug>,
}

interface PrescriptionsResponse {
  items: Array<Prescription> | []
}

const drugsInitialState = [{}];
const warningField = "prescription-item-warning";

@Component({
  name: "CreatePrescriptionModal",
  components: {
    ErrorMessage,
    PrescriptionPackage,
    PrescriptionDetails,
    PrescriptionPatientDetails
  }
})
export default class CreatePrescriptionModal extends Mixins(ErrorsMixin) {
  @Prop({type: Object, required: true}) readonly patient!: Patient;
  @Prop({type: String, required: false}) readonly appointmentDocumentId!: string;
  @Prop({type: String, required: false}) readonly continuousStayId!: string;
  @Prop({type: Object, default: null}) readonly nfzBranch!: NfzBranch;

  @Ref("createPrescription") readonly createPrescription!: BModal;

  private latestPrescriptions: Array<Prescription> = [];
  private isLoading: boolean = false;
  private prescriptionStatus: PrescriptionStatuses|null = null;
  private code: string|null = null;
  private fileId: string|null = null;
  private password: string|null = null;
  private timeout?: number;
  private loading: boolean = false;
  private packageId: string|null = null;
  private prescription: Package = {
    drugs: drugsInitialState,
    nfzBranch: this.nfzBranch,
    additionalRights: {},
  };
  private ignoreWarnings: boolean = false;

  private get warnings(): Array<Error> {
    return this.errors.filter(error => error.field && error.field.startsWith(warningField));
  }

  private get canVerify(): boolean {
    return this.prescription.drugs.some(drug => !!drug.externalId);
  }

  private get disabled(): boolean {
    return !!this.prescriptionStatus;
  }

  @Watch("nfzBranch", {immediate: true}) onNfzBranchChange(value: NfzBranch|null): void {
    this.prescription.nfzBranch = value;
  }

  private addDrug(): void {
    this.prescription.drugs = [...this.prescription.drugs, {}];
  }

  private setPrescription(prescriptionDrugs:Array<PrescriptionDrug>){
    if (this.prescription.drugs[0].name === undefined){
      this.prescription.drugs = []
    }

    this.prescription.drugs = [...this.prescription.drugs, ...prescriptionDrugs]
  }

  private async getPatientLatestPrescriptions(): Promise<void> {
    this.isLoading = true;
    const {
      items,
    } = await read<PrescriptionsResponse>(`/api/patient/${this.patient.patientId}/prescriptions/latest`);
    this.latestPrescriptions = items;
    this.isLoading = false;
  }

  private async submit(): Promise<void> {
    this.clearErrors();
    this.loading = true;
    try {
      const packageId = generateUuid();
      this.packageId = packageId;
      const prescriptions: Array<PackageData> = this.prescription.drugs.map(drug => {
        return {
          nfzBranchId: this.prescription.nfzBranch ? this.prescription.nfzBranch.branchId : undefined,
          additionalRights: isEmpty(this.prescription.additionalRights) || !this.prescription.additionalRights.code
            ? undefined
            : this.prescription.additionalRights,
          prescriptionId: generateUuid(),
          patientId: this.patient.patientId,
          appointmentDocumentId: this.appointmentDocumentId,
          continuousStayId: this.continuousStayId,
          drug: drug.externalId
            ? {
              ...drug,
              uuid: generateUuid(),
            }
            : undefined,
          yearlyPrescription: drug.yearlyPrescription
        };
      });
      await create<PrescriptionData>(`/api/electronic-prescription/${packageId}`, {
        password: this.password || undefined,
        package: prescriptions,
        ignoreWarnings: this.ignoreWarnings,
      });
      await this.getStatus(packageId);
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private async getStatus(packageId: string): Promise<void> {
    const {code, fileId, status} = await read<PrescriptionStatus>(`/api/prescription-package-status/${packageId}`);
    this.prescriptionStatus = status;
    this.code = code || null;
    this.fileId = fileId || null;
    if (this.createPrescription.$data.isShow
      && status !== PrescriptionStatuses.COMPLETED && status !== PrescriptionStatuses.REJECTED) {
      this.timeout = window.setTimeout(async () => {
        await this.getStatus(packageId);
      }, 2000);
    }
  }

  private resetData(): void {
    this.prescription = {
      drugs: drugsInitialState,
      nfzBranch: this.nfzBranch,
      additionalRights: {},
    }
    this.password = null;
    this.code = null;
    this.fileId = null;
    this.loading = false;
    this.prescriptionStatus = null;
    this.packageId = null;
    this.ignoreWarnings = false;
    clearTimeout(this.timeout);
    this.clearErrors();
  }

  private clearErrors(): void {
    this.errors = [];
  }

  private removeDrugErrors (idToRemove: number): void {
    const removedPackageErrorRegExp = new RegExp(`^package\\[${idToRemove}]`);
    const packageErrorsRegExp = /(^package\[)(\d)(].*$)/;

    this.errors = this.errors.reduce<Array<Error>>((errorArr, currentError) => {
      if (!currentError.field) {
        return [...errorArr, currentError];
      }
      if (removedPackageErrorRegExp.test(currentError.field)) {
        return errorArr
      } else {
        const field: string = currentError.field.replace(packageErrorsRegExp, (match, p1, p2, p3) => {
          let currentIdx = Number(p2);
          if (idToRemove < currentIdx) {
            currentIdx--;
          }
          return `${p1}${currentIdx}${p3}`;
        });

        return [...errorArr, {
          ...currentError,
          field
        }];
      }
    }, []);
  }

  private onHide(): void {
    if (this.packageId
      && this.prescriptionStatus !== PrescriptionStatuses.COMPLETED
      && this.prescriptionStatus !== PrescriptionStatuses.REJECTED) {
      this.$emit("prepared");
      eventBus.on(this.packageId, ()=>{ this.$emit("created") });
    } else if (this.prescriptionStatus === PrescriptionStatuses.COMPLETED) {
      this.$emit("created");
    }
    this.resetData();
  }

  hideModal(): void {
    this.$bvModal.hide("create-prescription");
  }

  private setIgnoreWarnings(value: boolean): void {
    this.ignoreWarnings = value;
  }
}
