<template>
  <div>
    <loading-icon
      v-if="loading"
      class="form-control"
      size="sm"
      text
    />
    <treeselect
      v-else
      ref="treeSelect"
      :value="value"
      :options="options"
      :multiple="multiple"
      flat
      :disabled="isDisabled"
      :default-expand-level="0"
      :name="name"
      :placeholder="placeholder"
      :no-options-text="noOptionsText"
      :class="{invalid: isInvalid}"
      clear-all-text="Odznacz wszystko"
      clear-value-text="Odznacz"
      disable-branch-nodes
      loading-text="Pobieranie opcji..."
      no-children-text="Brak rozwijanych opcji"
      no-results-text="Brak wyników wyszukiwania"
      retry-text="Pobrać ponownie?"
      retry-title="Kliknij by pobrać ponownie"
      search-prompt-text="Zacznij pisać..."
      show-count
      @input="update"
      @select="select"
      @open="loadOptions"
    >
      <template #value-label="{node: {raw}}">
        {{ raw.customLabel }}
      </template>
    </treeselect>
  </div>
</template>

<script>
import read from "../../rest/read";
import Treeselect from "@hobbytowo/vue-treeselect";
import "@hobbytowo/vue-treeselect/dist/vue-treeselect.css";
import LoadingIcon from "../Loading/LoadingIcon";

export default {
  components: {LoadingIcon, Treeselect},
  props: {
    name: {default: "treatmentType", type: String},
    value: {type: [Object, String, Array], default: null},
    disabled: {default: false, type: Boolean},
    clearable: {default: true, type: Boolean},
    placeholder: {type: String, default: "Wybierz typ usługi"},
    multiple: {default: false, type: Boolean},
    state: {default: null, type: Boolean},
    clearOnOpen: {type: Boolean, default: false},
    checkIfAnyTreatmentTypeExists: {type: Boolean, default: false},
    workerId: {type: String, default: null},
    noOptionsText: {type: String, default: "Brak opcji do wyświetlenia"},
    withoutUndefined: {type: Boolean, default: false},
    preselectUndefined: {type: Boolean, default: false},
    disableChoiceIfDefined: {type: Boolean, default: false},
    scope: {type: Array, default: null},
    nfzWorkerTypes: {type: Array, default: () => []},
    undefinedOnNull: {type: Boolean, default: false},
    branchId: {type: String, default: null},
    patientId: {type: String, default: null},
    restrictToCurrentWorkerBranch:  {type: Boolean, default: false}
  },
  data() {
    return {
      options: [],
      loading: false,
      undefinedTreatment: null,
      initialValue: this.value,
    };
  },
  computed: {
    isDisabled() {
      return this.disabled || (this.disableChoiceIfDefined && this.initialValue !== this.undefinedTreatment);
    },
    isInvalid() {
      return false === this.state;
    },
    undefinedOption() {
      return this.options.find(option => option.value === this.undefinedTreatment)
    }
  },
  watch:{
    async workerId() {
      await this.loadOptions();
    },
    async nfzWorkerTypes() {
      await this.loadOptions();
    },
    async branchId() {
      await this.loadOptions();
    },
    value() {
      if(null === this.value && this.undefinedOnNull){
        this.selectUndefined();
      }
    }
  },
  mounted() {
    if (this.preselectUndefined || this.checkIfAnyTreatmentTypeExists || this.disableChoiceIfDefined) {
      this.firstTimeLoad();
    }
  },
  methods: {
    async loadOptions(phrase="") {
      const {items, undefinedTreatment} = await read("/api/treatment-types", {
        active: 1,
        phrase,
        treeStructure: true,
        workerId: this.workerId,
        withoutUndefined: this.withoutUndefined,
        scope: this.scope,
        nfzWorkerTypes: this.nfzWorkerTypes,
        branchId: this.branchId,
        patientId: this.patientId,
        restrictToCurrentWorkerBranch: this.restrictToCurrentWorkerBranch
      });

      this.options = items.map(item => this.treatmentTypeToOption(item));
      this.undefinedTreatment = undefinedTreatment;
    },
    treatmentTypeToOption(treatmentType, parentName="") {
      return {
        ...treatmentType,
        label: treatmentType.name,
        customLabel: (this.multiple && !treatmentType.children && parentName)
          ? `${parentName} - ${treatmentType.name}`
          : treatmentType.name,
        id: treatmentType.value,
        children: treatmentType.children
          ? treatmentType.children.map(child => this.treatmentTypeToOption(child, treatmentType.name))
          : undefined,
      }
    },
    async firstTimeLoad() {
      this.loading = true;
      await this.loadOptions();

      if (!this.value && this.preselectUndefined) {
        this.selectUndefined();
      }
      if (!this.options.length) {
        this.$emit("emptyList");
      }
      this.loading = false;
      // treeselect renders when finished loading
      setTimeout(()=> {
        // reset this.value if it doesn't match any option
        if (this.value && this.$refs.treeSelect?.selectedNodes.some(node => node.label === `${this.value} (unknown)`)) {
          this.update();
        }
      });
    },
    update(option) {
      if(option === undefined) {
        this.$emit("input", null);
        this.select(null)
      } else {
        this.$emit("input", option);
      }
    },
    select(option) {
      this.$emit("select", option);
    },
    selectUndefined() {
      this.update(this.undefinedTreatment);
      this.select(this.undefinedOption);
    },
  },
}
</script>
