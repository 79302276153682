<template>
  <div
    :class="{'border-bottom': savedItem && !savedItem.mobile}"
    class="mb-4"
  >
    <template v-if="dataLoaded">
      <appointment-info
        :appointment-id="savedItem.appointmentId"
        :patient-id="savedItem.patientId"
        :remove-patient-allowed-from-group="savedItem.removePatientAllowedFromGroup"
        :change-patients-allowed-from-group="savedItem.changePatientsAllowedFromGroup"
        :cancel-allowed="!savedItem.group || savedItem.cancelAllowedFromGroup"
        :loading-schedule-data="loadingScheduleData"
        :continuous-stay="savedItem.continuousStay"
        class="appointment-action"
        @edit="editAppointment"
        @check-in="openCheckInModal"
        @check-in-withdraw="openCheckInWithdrawalModal"
        @open-appointment="openOpenAppointmentModal"
        @show-document="showDocument"
        @remove-patient="openRemovePatientFromAppointmentModal"
        @change-patients="openChangePatientsModal"
        @cancel-appointment="openCancelAppointmentModal"
        @change-appointment-parameters="openChangeAppointmentParametersModal"
        @report-treatment-type="openReportTreatmentTypeModal"
        @changeAppointmentDate="openEditAppointmentDateModal"
      />
      <check-in-appointment
        :appointment-id="savedItem.appointmentId"
        :date="new Date(savedItem.date)"
        :group="savedItem.group"
        :patients="savedItem.patients"
        :patient-id="savedItem.patientId"
        :main-worker="savedItem.mainWorker"
        class="appointment-action"
        @checkIn="updateAppointmentList"
        @finishEmptyAppointment="openFinishEmptyAppointmentModal"
      />
      <check-in-withdrawal-modal
        :appointment-id="savedItem.appointmentId"
        :date="savedItem.date"
        :group="savedItem.group"
        :patients="savedItem.patients"
        :patient-id="savedItem.patientId"
        :main-worker="savedItem.mainWorker"
        class="appointment-action"
        title="Cofnięcie potwierdzenia wizyty"
        @appointmentCheckInWithdrawn="updateAppointmentList"
      />
      <open-appointment
        :appointment-id="savedItem.appointmentId"
        :status="savedItem.status"
        :date="savedItem.date"
        :group="savedItem.group"
        :patients="savedItem.patients"
        :patient-id="savedItem.patientId"
        :main-worker="savedItem.mainWorker"
        :workers="savedItem.workers"
        :continuous-stay="savedItem.continuousStay"
        :branch-id="savedItem.branchId"
        :branch-name="savedItem.branchName"
        class="appointment-action"
        @openAppointment="updateAppointmentList"
        @finishEmptyAppointment="openFinishEmptyAppointmentModal"
      />
      <remove-appointment-patient
        :appointment-id="savedItem.appointmentId"
        :date="savedItem.date"
        :group="savedItem.group"
        :patients="savedItem.patients"
        :patient-id="savedItem.patientId"
        :main-worker="savedItem.mainWorker"
        class="appointment-action"
        @removePatient="updateAppointmentList"
      />
      <edit-appointment-patients
        :appointment-id="savedItem.appointmentId"
        class="appointment-action"
        title="Edycja pacjentów wizyty grupowej"
        @updateAppointmentPatients="updateAppointmentList"
      />
      <cancel-reason-modal
        :appointment-id="savedItem.appointmentId"
        :date="savedItem.date"
        :group="savedItem.group"
        :patients="savedItem.patients"
        :patient-id="savedItem.patientId"
        :main-worker="savedItem.mainWorker"
        class="appointment-action"
        title="Odwołanie wizyty"
        @appointmentCanceled="updateAppointmentList"
      />
      <cancel-reason-modal
        :appointment-id="savedItem.appointmentId"
        :date="savedItem.date"
        :deferred="true"
        :group="savedItem.group"
        :patients="savedItem.patients"
        :patient-id="savedItem.patientId"
        :main-worker="savedItem.mainWorker"
        class="appointment-action"
        title="Wniosek o odwołanie wizyty"
        @appointmentCanceled="updateAppointmentList"
      />
      <finish-empty-appointment-modal
        :appointment-id="savedItem.appointmentId"
        :date="savedItem.date"
        :group="savedItem.group"
        :patients="savedItem.patients"
        :patient-id="savedItem.patientId"
        :main-worker="savedItem.mainWorker"
        class="appointment-action"
        title="Czy na pewno zakończyć wizytę?"
        @emptyAppointmentFinished="updateAppointmentList"
      />
      <change-appointment-parameters
        :appointment-id="savedItem.appointmentId"
        title="Określ parametry wizyty"
      />
      <report-treatment-type
        :appointment-id="savedItem.appointmentId"
        title="Raportowanie typu usługi"
        @reportedTreatment="updateAppointmentList"
      />
      <reopen-canceled-appointment-modal
        :appointment-id="savedItem.appointmentId"
        @reopenAppointment="updateAppointmentList"
      />
      <change-appointment-date
        :appointment-id="savedItem.appointmentId"
        :status="savedItem.status"
        with-reason
        title="Edycja daty wizyty"
        @appointmentUpdated="updateAppointmentList"
      />
    </template>
    <schedule-appointment @appointmentScheduled="appointmentScheduled" />
  </div>
</template>

<script>
import CheckInWithdrawalModal from "../AppointmentActions/CheckInWithdrawalModal";
import EditAppointmentPatients from "../AppointmentActions/EditAppointmentPatients";
import AppointmentInfo from "../AppointmentInfo";
import CheckInAppointment from "../AppointmentActions/CheckInAppointment";
import RemoveAppointmentPatient from "../AppointmentActions/RemoveAppointmentPatient";
import OpenAppointment from "../AppointmentActions/OpenAppointment";
import {mapActions, mapState} from "vuex";
import CancelReasonModal from "../AppointmentActions/CancelReasonModal";
import ChangeAppointmentParameters from "../AppointmentActions/ChangeAppointmentParameters";
import ReportTreatmentType from "../AppointmentActions/ReportTreatmentTypeModal";
import ScheduleAppointment from "../../Calendar/ScheduleAppointment";
import parseDate from "../../../utils/date/parseDate";
import FinishEmptyAppointmentModal from "../AppointmentActions/FinishEmptyAppointmentModal";
import ReopenCanceledAppointmentModal from "../AppointmentActions/ReopenCanceledAppointmentModal";
import {ModalType} from "@/store/modules/scheduleAppointment/types";
import ChangeAppointmentDate from "../AppointmentUnificationOptions/ChangeAppointmentDate";

export default {
  name: "AppointmentBoxModals",
  components: {
    ChangeAppointmentDate,
    ReopenCanceledAppointmentModal,
    FinishEmptyAppointmentModal,
    ScheduleAppointment,
    ChangeAppointmentParameters,
    CancelReasonModal,
    OpenAppointment,
    RemoveAppointmentPatient,
    CheckInAppointment,
    EditAppointmentPatients,
    CheckInWithdrawalModal,
    AppointmentInfo,
    ReportTreatmentType
  },
  data() {
    return {
      scheduleData: null,
      loadingScheduleData: false,
    };
  },
  computed: {
    dataLoaded() {
      return !!this.savedItem?.appointmentId;
    },
    ...mapState("appointmentListItem", ["savedItem"])
  },
  methods: {
    ...mapActions({
      setStickyPatient: "stickyPatient/load",
      clearStickyPatient: "stickyPatient/clear",
      setScheduleAppointmentData: "scheduleAppointment/setAppointmentData",
      openScheduleAppointmentModal: "scheduleAppointment/openModal",
    }),
    updateAppointmentList() {
      this.$emit("appointmentUpdated");
    },
    openReportTreatmentTypeModal() {
      this.$bvModal.show("report-treatment-type-modal");
    },
    openChangeAppointmentParametersModal() {
      this.$bvModal.show("change-appointment-parameters-modal");
    },
    openCancelAppointmentModal() {
      this.$bvModal.show("cancel-reason-modal");
    },
    openEditAppointmentDateModal(appointmentId) {
      this.$bvModal.show(`changeAppointmentDate-${appointmentId}`);
    },
    openCheckInModal() {
      this.$bvModal.show("check-in-appointment-modal");
    },
    async editAppointment(scheduledData) {
      this.setScheduleAppointmentData({
        ...scheduledData,
        appointmentStart: parseDate(scheduledData.appointmentStart),
        appointmentEnd: parseDate(scheduledData.appointmentEnd),
        reschedule: true,
        patientContinuousStays: this.savedItem.patientContinuousStays,
      });
      this.openScheduleAppointmentModal(this.savedItem.continuousStay ? ModalType.CONTINUOUS_STAY : ModalType.DEFAULT);
      if (this.savedItem && this.savedItem.patient) {
        await this.setStickyPatient(this.savedItem && this.savedItem.patient.patientId);
      } else {
        this.clearStickyPatient();
      }
    },
    openAppointmentInfoModal() {
      this.$bvModal.show("appointment-info-modal");
    },
    openCheckInWithdrawalModal() {
      this.$bvModal.show("check-in-withdrawal-modal");
    },
    openOpenAppointmentModal() {
      if (this.savedItem.status === "opened") {
        this.showDocument();
        return;
      }
      this.$bvModal.show("open-appointment-modal");
    },
    showDocument() {
      this.$router.push({name: "viewDocumentAppointment", params: {appointmentId: this.savedItem.appointmentId}});
    },
    showPatientCard() {
      if(!this.patientId && this.patients.length === 1) {
        this.$router.push({name: "patientCard", params: {patientId: this.patients[0].patientId}});
      }
    },
    openRemovePatientFromAppointmentModal() {
      this.$bvModal.show("remove-appointment-patient-modal");
    },
    openChangePatientsModal() {
      this.$bvModal.show("edit-appointment-patients-modal");
    },
    openFinishEmptyAppointmentModal() {
      this.$bvModal.show("finish-empty-appointment-modal");
    },
    appointmentScheduled() {
      this.$emit("appointmentScheduled");
      this.updateAppointmentList();
    }
  }
}
</script>
