<template>
  <b-modal
    :visible="visible"
    :title="modalTitle"
    no-close-on-backdrop
    size="xl"
    title-tag="h3"
    @hide="onHide"
  >
    <template v-if="visible">
      <template v-if="isStep(scheduleAppointmentEntryStep)">
        <error-message :errors="errors" />
        <loading-mask :loading="loading || fetching">
          <div class="form-group row">
            <label
              for="date"
              class="col-form-label form-control-label col-sm-2"
            >
              Termin wizyty
            </label>
            <div class="col-sm-12 col-lg-8 col-xl-6">
              <div class="form-row">
                <date-picker
                  id="date"
                  v-model="appointmentDate"
                  class="col-sm-4 m-b-20"
                  @input="getEarlierPossibilityStatus(modifiedTreatmentType)"
                />
                <hour-select
                  v-model="hourStart"
                  class="col-sm-3 m-b-20"
                  :time-span="timeSpan"
                />
                <hour-select
                  v-model="hourEnd"
                  class="col-sm-3 m-b-20"
                  :time-span="timeSpan"
                />
              </div>

              <error-message
                :errors="errors"
                field="startDate"
              />
              <error-message
                :errors="errors"
                field="endDate"
              />
            </div>
            <div class="mx-2">
              <meeting-type-radio
                v-model="meetingType"
                :available="meetingTypeAvailability"
                label=""
              />
            </div>
          </div>

          <FormRow
            :errors="errors"
            label="Pacjent"
            field="patientIds"
          >
            <patient-select
              v-model="patients"
              :state="state('patientIds')"
              multiple
            />
          </FormRow>

          <FormRow label="Specjalista">
            <div class="treatment-worker-select">
              <div class="worker-nfz">
                <worker-select
                  v-model="mainWorker"
                  :disabled="!reschedule"
                  :clearable="false"
                  placeholder="Wybierz specjalistę"
                  :state="state('mainWorker.worker')"
                  @input="updateWorker"
                />
                <error-message
                  :errors="errors"
                  field="mainWorker.worker"
                  root
                />
              </div>

              <div class="visually-hidden">
                <treatment-type-select
                  ref="treatmentTypeSelect"
                  :value="modifiedTreatmentType"
                  :worker-id="mainWorker.workerId"
                  :state="state('mainWorker.treatmentTypeId')"
                  check-if-any-treatment-type-exists
                  no-options-text="Specjalista nie wykonuje żadnych usług"
                  preselect-undefined
                  :scope="['ambulatory']"
                  @select="changeTreatmentType"
                />
                <error-message
                  :errors="errors"
                  field="mainWorker.treatmentTypeId"
                />
              </div>
            </div>
          </FormRow>

          <FormRow label="Specjalista - Typ personelu">
            <nfz-worker-type-select
              v-model="nfzWorkerType"
              :worker-id="mainWorker ? mainWorker.workerId : null"
              :disabled="!mainWorker.workerId"
              :state="state('mainWorker.nfzWorkerType')"
            />
            <error-message
              :errors="errors"
              field="mainWorker.nfzWorkerType"
            />
          </FormRow>

          <FormRow
            v-if="null == appointmentId || appointmentId === oldAppointmentId"
            :errors="errors"
            field="additionalWorkers"
            label="Specjaliści dodatkowi"
            root
          >
            <worker-type-treatment-worker-select
              v-model="additionalWorkers"
              :errors="errors"
              :exclude-worker-ids="[worker.workerId]"
              :scope="['ambulatory']"
            />
          </FormRow>

          <transition name="fade">
            <div
              v-if="meetingType === appointmentLocationTypes.REMOTE"
              class="form-group row"
            >
              <div class="col-sm-2">
                <p class="col-form-label form-control-label">
                  Adres wizyty
                </p>
                <p class="text-muted">
                  Domyślny adres jest pobierany zawsze dla pierwszego pacjenta na liście.
                </p>
              </div>
              <div class="col-sm-10">
                <address-form
                  v-model="address"
                  :errors="subErrors('address')"
                  horizontal
                  in-modal
                />
              </div>
              <error-message
                :errors="subErrors('address')"
                field="mobile"
              />
            </div>
          </transition>

          <FormRow
            field="branchId"
            :errors="errors"
            label="Komórka organizacyjna"
          >
            <branch-select
              id="branch"
              check-if-any-branch-exists
              :value="selectedBranch && selectedBranch.branchId ? selectedBranch.branchId : null"
              :state="state('branchId')"
              @select="selectedBranch = $event"
            />
          </FormRow>

          <FormRow
            :errors="errors"
            field="beneficiaryCategory"
            label="Kategoria świadczeniobiorcy"
          >
            <beneficiary-category-select
              v-model="beneficiaryCategory"
              :errors="errors"
            />
          </FormRow>

          <b-form-group
            label="Uwagi rejestracji"
            label-cols-sm="2"
          >
            <collapse-wrapper :show="!!registrationNotes">
              <textarea-counter
                v-model="registrationNotes"
                :max-characters-count="500"
                :state="state('registrationNotes')"
                rows="3"
              />
              <error-message
                :errors="errors"
                field="registrationNotes"
              />
            </collapse-wrapper>
          </b-form-group>

          <b-form-group
            v-if="patients.length === 0"
            label-cols-sm="2"
          >
            <div
              :class="{'border-danger': state('patientIds') === false}"
              class="alert alert-purple mb-0"
            >
              <p>Czy na pewno chcesz umówić wizytę bez pacjentów?</p>
              <b-checkbox
                v-model="noPatients"
              >
                Zaznacz jeżeli chcesz umówić wizytę bez pacjentów lub wypełnij pole "Pacjent".
              </b-checkbox>
            </div>
          </b-form-group>

          <b-form-group
            v-if="overtimePossible"
            label-cols-sm="2"
          >
            <div
              class="alert alert-purple mb-0"
            >
              <p>Niektórzy specjaliści mają już w tym czasie zaplanowane wizyty.</p>
              <b-checkbox
                v-model="overtime"
              >
                Zaznacz jeżeli chcesz umówić wizytę dodatkową.
              </b-checkbox>
            </div>
          </b-form-group>

          <b-form-group
            v-if="allowWrongNfzWorkerTypesToggleBoxVisible"
            label-cols-sm="2"
          >
            <div
              class="alert alert-purple mb-0"
            >
              <p>Niektórzy specjaliści nie pracują w wybranej roli w wybranych godzinach.</p>
              <b-checkbox
                v-model="allowWrongNfzWorkerTypes"
              >
                Zaznacz jeżeli mimo to chcesz umówić wizytę.
              </b-checkbox>
            </div>
          </b-form-group>

          <b-form-group label-cols-sm="2">
            <div class="form-row">
              <is-granted
                class="col"
                component="div"
                :privileges="['CREATE_RECURRENT_APPOINTMENTS']"
              >
                <div
                  v-if="!oldAppointmentId && !copyId"
                  class="form-group col-sm"
                >
                  <div class="d-md-flex">
                    <span class="d-block col-form-label mr-2">Cykliczność</span>
                    <div>
                      <div class="form-group">
                        <recurrence-type-radio
                          v-model="recurrenceType"
                          :state="state('recurrenceType')"
                        />
                        <error-message
                          :errors="errors"
                          field="recurrenceType"
                        />
                      </div>
                      <div
                        v-if="recurrence"
                        class="form-group"
                      >
                        <date-picker
                          id="recurrenceEndDate"
                          v-model="recurrenceEndDate"
                          placeholder="Data zakończenia reguły"
                          :disabled-date="disabledDateRecurrenceBoundaries"
                          :state="state('endDate')"
                          style="max-width: 300px"
                        />
                        <error-message
                          :errors="errors"
                          field="endDate"
                        />
                      </div>
                    </div>
                    <error-message
                      :errors="errors"
                      field="recurrence"
                      root
                    />
                  </div>
                </div>
              </is-granted>
            </div>
          </b-form-group>

          <FormRow
            v-if="forbidden"
            label=""
          >
            <div class="alert alert-danger mb-0">
              <p>Nie można umówić wizyty dla innego specjalisty</p>
            </div>
          </FormRow>
          <FormRow
            v-if="errors.length > 0"
            label=""
          >
            <b-alert
              :show="true"
              variant="danger"
            >
              Formularz nie został wypełniony prawidłowo.
            </b-alert>
          </FormRow>
        </loading-mask>
      </template>

      <template v-if="isStep(scheduleAppointmentVerifyRecurrentStep)">
        <loading-mask :loading="loading">
          <error-message :errors="errors" />
          <table class="table table-bordered">
            <thead class="thead-light">
              <tr>
                <th class="w-40">
                  Data
                </th>
                <th class="w-40">
                  Uwagi
                </th>
                <th class="w-20">
                  Opcje
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in appointmentsPlan"
                :key="item.appointmentId"
              >
                <td>
                  {{ prettifyDate(item.startDate, item.endDate) }}
                </td>
                <td>
                  <ErrorMessage
                    :errors="verifyAppointmentsErrors"
                    :field="`appointments[${appointmentsPlan.findIndex(a => a.appointmentId === item.appointmentId)}]`"
                  />
                </td>
                <td>
                  <button
                    class="btn btn-danger btn-sm"
                    type="button"
                    @click="removeFromPlan(item.appointmentId)"
                  >
                    <i class="fa fa-times" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <FormRow
            v-if="showRecurrenceEarlierPossibility"
            :errors="errors"
            field="recurrenceEarlierPossibility"
            label=""
          >
            <div
              :class="{'border-danger': state('earlierPossibility') === false}"
              class="alert alert-purple mb-0"
            >
              <earlier-possibility-reason-radio
                :value="recurrenceEarlierPossibilityReason"
                @input="updateRecurrentEarlierPossibilityReason"
              />
            </div>
          </FormRow>

          <FormRow
            v-if="overtimePossible"
            label=""
          >
            <div class="alert alert-purple mb-0">
              <p>Niektórzy specjaliści mają już w tym czasie zaplanowane wizyty.</p>
              <b-checkbox
                v-model="overtime"
              >
                Zaznacz jeżeli chcesz umówić wizytę dodatkową.
              </b-checkbox>
            </div>
          </FormRow>

          <FormRow
            v-if="forbidden"
            label=""
          >
            <div class="alert alert-danger mb-0">
              <p>Nie można umówić wizyty dla innego specjalisty</p>
            </div>
          </FormRow>
          <error-message
            :errors="errors"
            :root="true"
            field="appointments"
          />
        </loading-mask>
      </template>

      <template v-if="isStep(scheduleAppointmentConfirmStep)">
        <div
          class="text-center"
        >
          <p class="text-success">
            <i class="far fa-check-circle fa-4x" />
          </p>
          <p>Wybrane wizyty zostały umówione.</p>
        </div>
      </template>
    </template>

    <template #modal-footer>
      <template v-if="isStep(scheduleAppointmentEntryStep)">
        <button
          :disabled="loading"
          class="btn btn-secondary"
          @click="onHide"
        >
          Anuluj
        </button>
        <button
          v-if="recurrence"
          :disabled="loading"
          class="btn btn-success"
          @click="previewRecurrentAppointments"
        >
          <i
            :class="loading ? 'fa-spinner fa-spin' : 'fa-check'"
            class="fa"
          />
          Pokaż plan wizyt
        </button>
        <button
          v-else
          :disabled="loading"
          class="btn btn-primary"
          @click="save"
        >
          <i
            :class="loading ? 'fa-spinner fa-spin' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
      </template>
      <template v-if="isStep(scheduleAppointmentVerifyRecurrentStep)">
        <button
          :disabled="loading"
          class="btn btn-secondary"
          @click="stepBackAndResetOvertime"
        >
          Wróć
        </button>
        <button
          :disabled="loading"
          class="btn btn-primary"
          @click="saveRecurring(false)"
        >
          <i
            :class="loading ? 'fa-spinner fa-spin' : 'fa-check'"
            class="fa"
          />
          Stwórz wizyty z powyższego planu
        </button>
      </template>
      <template v-if="isStep(scheduleAppointmentConfirmStep)">
        <button
          class="btn btn-secondary"
          @click="onHide"
        >
          Zamknij
        </button>
      </template>
    </template>
  </b-modal>
</template>

<script>
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import stringifyDate from "../../utils/date/stringifyDate";
import PatientSelect from "../Patient/PatientSelect";
import AddressForm from "../Address/AddressForm";
import read from "../../rest/read";
import create from "../../rest/create";
import {generateUuid} from "../../utils/uuid/generateUuid";
import FormRow from "../Form/FormRow";
import {BadRequestException, ForbiddenException} from "../../rest";
import ErrorMessage from "../Form/ErrorMessage";
import TextareaCounter from "../Form/Textarea/TextareaCounter";
import update from "../../rest/update";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import processResponseException from "../../utils/errors/processResponseException";
import WorkerSelect from "../Worker/WorkerSelect";
import IsGranted from "../IsGranted";
import TreatmentTypeSelect from "../TreatmentType/TreatmentTypeSelect";
import BeneficiaryCategorySelect from "../Appointment/BeneficiaryCategory/BeneficiaryCategorySelect"
import {mapActions, mapMutations} from "vuex";
import MeetingTypeRadio from "../Form/Radio/MeetingTypeRadio";
import getSubErrors from "../../utils/errors/subErrors";
import LoadingMask from "../Loading/LoadingMask";
import EarlierPossibilityReasonRadio from "@/components/Form/Radio/EarlierPossibilityReasonRadio";
import {isEmpty} from "lodash";
import HourSelect from "../Form/Select/HourSelect";
import DatePicker from "../Form/DatePicker/DatePicker";
import RecurrenceTypeRadio from "../Form/Radio/RecurrenceTypeRadio";
import generateDates, {RecurrenceType} from "../../utils/recurrence/generateDates";
import BranchSelect from "../Branch/BranchSelect";
import CollapseWrapper from "@/components/Collapse/CollapseWrapper";
import {AppointmentLocationType} from "../../types/Appointment";
import {guessMeetingType} from "../../utils/appointment/meetingType";
import NfzWorkerTypeSelect from "@/components/Form/Select/NfzWorkerTypeSelect";
import WorkerTypeTreatmentWorkerSelect from "@/components/Worker/WorkerTypeTreatmentWorkerSelect";

const earlierPossibilityReasonInitialData = {
  value: null,
  description: null,
};

export default {
  name: "ScheduleAppointmentModal",
  components: {
    CollapseWrapper,
    HourSelect,
    LoadingMask,
    MeetingTypeRadio,
    RecurrenceTypeRadio,
    TreatmentTypeSelect,
    TextareaCounter,
    ErrorMessage,
    FormRow,
    AddressForm,
    PatientSelect,
    IsGranted,
    WorkerTypeTreatmentWorkerSelect,
    EarlierPossibilityReasonRadio,
    WorkerSelect,
    DatePicker,
    BranchSelect,
    NfzWorkerTypeSelect,
    BeneficiaryCategorySelect,
  },
  mixins: [errorsMixin],
  props: {
    visible: {type: Boolean, default: true},
    oldAppointmentId: {type: String, default: null},
    copyId: {type: String, default: null},
    appointmentId: {type: String, default: null},
    worker: {type: Object, required: true},
    mainWorkerType: {type: String, default: null},
    startDate: {type: Date, required: true},
    endDate: {type: Date, required: true},
    branchId: {type: String, default: null},
    branchName: {type: String, default: null},
    treatmentType: {type: Object, default: null},
    preferredPatient: {type: Object, default: null},
    reschedule: {type: Boolean, default: false},
    scheduleStep: {type: Number, required: true},
  },
  data() {
    const modifiedTreatmentType = this.treatmentType && this.treatmentType.value
      ? this.treatmentType.value
      : this.worker && this.worker.treatmentTypeDefaultId
        ? this.worker.treatmentTypeDefaultId
        : null;

    const hourStart = {
      HH: stringifyDate(this.startDate, DATE_FORMAT.HOUR),
      mm: stringifyDate(this.startDate, DATE_FORMAT.MINUTE)
    }

    const hourEnd = {
      HH: stringifyDate(this.endDate, DATE_FORMAT.HOUR),
      mm: stringifyDate(this.endDate, DATE_FORMAT.MINUTE)
    }

    return {
      loading: false,
      fetching: false,
      mainWorker: this.worker.workerId,
      nfzWorkerType: this.mainWorkerType, // main worker type
      additionalWorkers: [],
      patient: null,
      patients: [],
      patientPresence: true,
      address: {},
      plan: "",
      beneficiaryCategory: "",
      registrationNotes: "",
      noPatients: false,
      forbidden: false,
      overtime: false,
      confirmationRequired: false,
      modifiedTreatmentType,
      earlierPossibilityReason: {...earlierPossibilityReasonInitialData},
      recurrenceEarlierPossibilityReason: {...earlierPossibilityReasonInitialData},
      showEarlierPossibility: false,
      recurrenceType: RecurrenceType.NONE,
      recurrenceEndDate: null,
      appointmentsPlan: [],
      meetingType: null,
      hourStart,
      hourEnd,
      appointmentDate: this.startDate,
      selectedBranch: {branchId: this.branchId, name: this.branchName} || null,
      scheduledAppointment: null,
      appointmentLocationTypes: AppointmentLocationType,
      timeSpan: 1,
      allowWrongNfzWorkerTypes: false,
    };
  },
  computed: {
    actualStartDate(){
      let actualStartDate = new Date(this.appointmentDate.getTime());
      actualStartDate.setHours(this.hourStart.HH, this.hourStart.mm)
      return actualStartDate;
    },
    actualEndDate(){
      let actualEndDate = new Date(this.appointmentDate.getTime());
      actualEndDate.setHours(this.hourEnd.HH, this.hourEnd.mm)
      if(this.hourEnd.HH === "00" && this.hourEnd.mm === "00"){
        actualEndDate.setDate(actualEndDate.getDate()+1)
      }
      return actualEndDate;
    },
    modalTitle() {
      if (null != this.oldAppointmentId) {
        return "Edycja wizyty";
      }
      if (null != this.copyId) {
        return "Kopia wizyty";
      }
      if (!this.isStep(this.scheduleAppointmentEntryStep)) {
        return "Umów wizytę cykliczną";
      }
      return "Umów wizytę";
    },
    overtimePossible() {
      if (this.overtime) {
        return true;
      }

      if (this.errors.length === 0) {
        return false;
      }

      return this.errors.some((error) => {
        if(error.field) {
          return error.field === "mainWorker.worker" ||
            error.field.match(/^appointments\[\d+]\.mainWorker.worker$/) ||
            error.field.match(/^additionalWorkers\[\d+]\.worker$/) ||
            error.field.match(/^appointments\[\d+]\.additionalWorkers\[\d+]\.worker$/);
        }
      });
    },
    allowWrongNfzWorkerTypesToggleBoxVisible() {
      if (this.allowWrongNfzWorkerTypes) {
        return true;
      }

      if (this.errors.length === 0) {
        return false;
      }

      return this.errors.some((error) => {
        if(error.field) {
          return error.field === "mainWorker.nfzWorkerType" ||
            error.field.match(/^appointments\[\d+]\.mainWorker.nfzWorkerType$/) ||
            error.field.match(/^additionalWorkers\[\d+]\.nfzWorkerType$/) ||
            error.field.match(/^appointments\[\d+]\.additionalWorkers\[\d+]\.nfzWorkerType$/);
        }
      });
    },
    meetingTypeAvailability() {
      return {
        local: true,
        remote: true,
        telephone: true
      };
    },
    recurrence() {
      return this.recurrenceType === RecurrenceType.WEEKLY || this.recurrenceType === RecurrenceType.MONTHLY
        || this.recurrenceType === RecurrenceType.TWO_WEEKS;
    },
    recurrenceEndOfDayDate() {
      const actualDate = this.recurrenceEndDate;

      return new Date(actualDate.getFullYear()
        ,actualDate.getMonth()
        ,actualDate.getDate()
        ,23,59,59);
    },
    showRecurrenceEarlierPossibility() {
      return !!this.errors.find((error) => !!error.field &&
        "earlierPossibility" === error.field.split(".").pop());
    },
    verifyAppointmentsErrors() {
      return this.errors.filter(error => !!error.field &&
        this.verifyAppointmentsErrorNames.includes(error.field.split(".").pop()));
    },
    verifyAppointmentsErrorNames() {
      return [
        "mainWorker",
        "worker",
      ];
    },
    scheduleAppointmentEntryStep() {
      return 0;
    },
    scheduleAppointmentVerifyRecurrentStep() {
      return 1;
    },
    scheduleAppointmentConfirmStep() {
      return 2;
    },
  },
  watch: {
    modifiedTreatmentType: {
      immediate: true,
      async handler(treatmentType) {
        if (treatmentType) {
          await this.getEarlierPossibilityStatus(treatmentType);
        }
      },
    },
    preferredPatient: {
      immediate: true,
      handler(patient) {
        if (!this.oldAppointmentId) {
          this.patients = patient ? [patient] : [];
          if(null !== patient) {
            this.loadAddress(patient.patientId);
            this.setBeneficiaryCategory(patient.patientId);
          }
        }
      }
    },
    oldAppointmentId() {
      this.loadOldAppointmentData();
    },
    async patients(newVal, oldVal) {
      if (!newVal.length) {
        this.address = {};
        this.beneficiaryCategory = null;
      }
      else if (isEmpty(this.address)
        && (!oldVal.length
          || (newVal.length && newVal[0].patientId !== oldVal[0].patientId))) {
        await this.loadAddress(newVal[0].patientId);
      }

      if(newVal.length===1) {
        await this.setBeneficiaryCategory(newVal[0].patientId);
      }else if(newVal.length>1){
        this.beneficiaryCategory = "continuation";
      }
    },
  },
  async mounted() {
    if (this.oldAppointmentId) {
      await this.loadOldAppointmentData();
    } else if (this.copyId) {
      await this.loadCopyAppointmentData();
    } else if (!this.modifiedTreatmentType) {
      setTimeout(() => {
        this.$refs.treatmentTypeSelect.selectUndefined();
      });
    }
  },
  destroyed() {
    this.resetAppointmentData();
  },
  methods: {
    ...mapActions("scheduleAppointment", [
      "resetAppointmentData",
      "step",
      "stepBack",
      "stepReset",
    ]),
    ...mapMutations("appointmentListItem", ["saveAppointmentListItem",]),
    onHide() {
      this.$emit("cancel");
      this.appointmentsPlan = [];
      this.errors = [];
      if (this.isStep(this.scheduleAppointmentConfirmStep)) {
        this.$emit("appointmentScheduled");
      }
      this.stepReset();
    },
    async loadOldAppointmentData() {
      this.loading = true;

      try {
        const [{items}, appointment] = await Promise.all([
          read("/api/patients", {appointmentId: this.oldAppointmentId}),
          read(`/api/appointments/${this.oldAppointmentId}`),
        ]);

        this.fillAppointmentData(items, appointment);
        this.registrationNotes = appointment.registrationNotes;
        this.earlierPossibilityReason = appointment.earlierPossibility || {...earlierPossibilityReasonInitialData};
        this.showEarlierPossibility = !!appointment.earlierPossibility;
        this.beneficiaryCategory = appointment.beneficiaryCategory;
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    async loadCopyAppointmentData() {
      this.loading = true;

      try {
        const [{items}, appointment] = await Promise.all([
          read("/api/patients", {appointmentId: this.copyId}),
          read(`/api/appointments/${this.copyId}`),
        ]);

        this.fillAppointmentData(items, appointment);

      } catch(e) {
        this.errors = processResponseException(e);
      }

      this.loading = false;
    },
    mapWorkerType(worker, workersTypes) {
      return ({
        ...worker,
        nfzWorkerType: workersTypes.find(workerType => workerType.workerId === worker.workerId)?.nfzWorkerType
      })
    },
    fillAppointmentData(items, appointment) {
      if (this.worker.workerId === appointment.mainSpecialist.workerId) {
        this.additionalWorkers = appointment.additionalSpecialists
          .map(w => this.mapWorkerType(w, appointment.nfzWorkerTypes));
      } else {
        this.additionalWorkers = appointment.additionalSpecialists
          .filter((worker) => worker.workerId !== this.worker.workerId)
          .concat([appointment.mainSpecialist])
          .map(w => this.mapWorkerType(w, appointment.nfzWorkerTypes))
      }

      this.nfzWorkerType = appointment.nfzWorkerTypes.find(workerType => workerType.workerId === this.mainWorker)?.nfzWorkerType

      if (!this.treatmentType) {
        if (this.worker.workerId === appointment.mainSpecialist.workerId) {
          this.modifiedTreatmentType = appointment.mainSpecialist.treatmentTypeId;
        } else {
          const newMainWorker = appointment.additionalSpecialists
            .find(worker => worker.workerId === this.worker.workerId);
          this.modifiedTreatmentType = newMainWorker
            ? newMainWorker.treatmentTypeId
            : this.modifiedTreatmentType;
        }
      }

      if (appointment.location.address) {
        this.address = appointment.location.address;
      }
      this.meetingType = guessMeetingType(appointment.location)
      this.noPatients = appointment.patients.length === 0;
      this.patientPresence = appointment.patientPresence;
      this.patients = items;
      this.plan = appointment.plan;
    },
    async createAppointment(appointmentId) {
      const data = this.buildAppointmentData();
      await create(`/api/appointments/${appointmentId}`, data);
    },
    async rescheduleAppointment() {
      const dataUpdate = {
        startDate: stringifyDate(this.actualStartDate),
        endDate: stringifyDate(this.actualEndDate),
        mainWorker: {
          workerId: this.mainWorker.workerId,
          treatmentTypeId: this.modifiedTreatmentType,
        },
        additionalWorkers: this.additionalWorkers
          ? this.additionalWorkers.map(({nfzWorkerType, ...rest}) => rest)
          : [],
        nfzWorkerTypes: [
          {workerId: this.worker.workerId, nfzWorkerType: this.nfzWorkerType}, // mainWorker
          ...this.additionalWorkers.map(additionalWorker => ({ // additionalWorkers
            workerId: additionalWorker.workerId,
            nfzWorkerType: additionalWorker.nfzWorkerType,
          }))
        ],
        patientIds: this.patients.map(patient => patient.patientId),
        patientPresence: this.patientPresence,
        locationType: this.meetingType === AppointmentLocationType.REMOTE ? "remote" : "local",
        plan: this.plan,
        registrationNotes: this.registrationNotes ? this.registrationNotes: null,
        branchId: this.selectedBranch?.branchId,
        beneficiaryCategory: this.beneficiaryCategory,
        address:this.meetingType === AppointmentLocationType.REMOTE
          ? {...this.address, countryCode: this.address.country} : null,
        telephone: this.meetingType === AppointmentLocationType.PHONE,
        overtime: this.overtime,
        noPatients: this.noPatients,
        confirmationRequired: this.confirmationRequired ? this.confirmationRequired : false,
        earlierPossibilityReason: this.earlierPossibilityReason.value ? this.earlierPossibilityReason: undefined,
        nfzConfiguration: true,
        allowWrongNfzWorkerTypes: this.allowWrongNfzWorkerTypes
      };

      await update(`/api/appointments/${this.oldAppointmentId}/reschedule`, dataUpdate);
    },
    async save() {
      this.loading = true;
      this.forbidden = false;

      try {
        let appointmentId;
        if (null != this.oldAppointmentId) {
          await this.rescheduleAppointment();
          appointmentId = this.oldAppointmentId;
        } else {
          appointmentId = generateUuid();
          await this.createAppointment(appointmentId);
        }
        await this.saveAppointment(appointmentId, this.actualStartDate)
      } catch (exception) {
        if (exception instanceof ForbiddenException) {
          this.forbidden = true;
        }
        const errors = processResponseException(exception);
        this.errors = errors.map(err => {
          if (err.field.startsWith("nfzWorkerTypes")) {
            // map nfzWorkerTypes errors to proper fields - trytytka
            const indexNumber = parseFloat(err.field.match(/[\d\.]+/))

            return {
              ...err,
              field: indexNumber // from nfzWorkerTypes indexes in request payload
                ? `additionalWorkers[${indexNumber - 1}].nfzWorkerType`
                : "mainWorker.nfzWorkerType"
            }
          }
          return err
        })

        this.overtime = false;
        this.allowWrongNfzWorkerTypes = false;
        this.noPatients = false;
      }
      this.loading = false;
    },
    async loadAddress(patientId){
      this.address = await read(`/api/patients/${patientId}/living_address`);
    },
    async setBeneficiaryCategory(patientId){
      const item = await read(`/api/beneficiary-category-default-value/${patientId}`);
      this.beneficiaryCategory = item.defaultValue
    },
    subErrors(field) {
      return getSubErrors(this.errors, field);
    },
    changeTreatmentType(treatmentType) {
      this.modifiedTreatmentType = treatmentType ? treatmentType.value : null;
    },
    updateWorker(worker) {
      if (worker.workerId !== this.worker.workerId) {
        this.modifiedTreatmentType = null;
      }
    },
    async getEarlierPossibilityStatus(treatmentType) {
      if (!treatmentType) {
        return;
      }
      this.fetching = true;
      try {
        const {items} = await read("/api/appointment/earlier-possibility", {
          treatmentTypeId: treatmentType,
          startDate: stringifyDate(this.actualStartDate, DATE_FORMAT.DATE_TIME),
        });
        this.showEarlierPossibility = !!items.length;
      } catch(e) {
        console.error(e);
      }
      this.fetching = false;
    },
    isStep(step) {
      return step === this.scheduleStep;
    },
    prettifyDate(startDate, endDate) {
      const date = stringifyDate(startDate, DATE_FORMAT.DATE);
      const startTime = stringifyDate(startDate, DATE_FORMAT.TIME);
      const endTime = stringifyDate(endDate, DATE_FORMAT.TIME);

      return `${date} ${startTime} - ${endTime}`;
    },
    buildAppointmentData() {
      return {
        startDate: stringifyDate(this.actualStartDate),
        endDate: stringifyDate(this.actualEndDate),
        mainWorker: {
          workerId: this.worker.workerId,
          treatmentTypeId: this.modifiedTreatmentType,
        },
        additionalWorkers: this.additionalWorkers
          ? this.additionalWorkers.map(({nfzWorkerType, ...rest}) => rest)
          : [],
        nfzWorkerTypes: [
          {workerId: this.worker.workerId, nfzWorkerType: this.nfzWorkerType}, // mainWorker
          ...this.additionalWorkers.map(additionalWorker => ({ // additionalWorkers
            workerId: additionalWorker.workerId,
            nfzWorkerType: additionalWorker.nfzWorkerType,
          }))
        ],
        patientIds: (!this.patients.length) ? null : this.patients.map(patient => patient.patientId),
        patientPresence: this.patients.length ? this.patientPresence : null,
        locationType: this.meetingType === AppointmentLocationType.REMOTE ? "remote" : "local",
        plan: this.plan,
        registrationNotes: this.registrationNotes ? this.registrationNotes: null,
        branchId: this.selectedBranch?.branchId,
        beneficiaryCategory: this.beneficiaryCategory,
        mobileTeamId: null,
        address:this.meetingType === AppointmentLocationType.REMOTE
          ? {...this.address, countryCode: this.address.country} : null,
        telephone: this.meetingType === AppointmentLocationType.PHONE,
        overtime: this.overtime,
        noPatients: this.noPatients,
        confirmationRequired: this.confirmationRequired ? this.confirmationRequired : false,
        earlierPossibilityReason: this.earlierPossibilityReason.value ? this.earlierPossibilityReason: undefined,
        nfzConfiguration: true,
        allowWrongNfzWorkerTypes: this.allowWrongNfzWorkerTypes
      };
    },
    async previewRecurrentAppointments() {
      this.loading = true;
      try {
        this.overtime = false;
        this.allowWrongNfzWorkerTypes = false;

        await this.buildRecurring();
        this.step();
        this.loading = false;
        await this.saveRecurring(true);
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    buildRecurring() {
      this.appointmentsPlan = [];
      this.errors = [];

      const tempErrors = [];
      if (!this.patients.length && !this.noPatients) {
        tempErrors.push({
          message: "Brak pacjentów",
          field: "patientIds"
        });
      }
      if (!this.recurrenceType) {
        tempErrors.push({
          message: "Nie wybrano powtarzalności",
          field: "recurrenceType"
        });
      }
      if (!this.recurrenceEndDate) {
        tempErrors.push({
          message: "Brak daty zakończenia reguły",
          field: "endDate"
        });
      }
      if (this.recurrenceEndDate && this.actualStartDate >= this.recurrenceEndOfDayDate) {
        tempErrors.push({
          message: "Data wizyty nie powinna być większa niż data zakończenia reguły.",
          field: "endDate",
        });
      }
      if (this.actualStartDate >= this.actualEndDate) {
        tempErrors.push({
          message: "Data zakończenia musi być późniejsza niż data rozpoczęcia",
          field: "endDate",
        });
      }
      this.additionalWorkers.forEach((additionalWorker) => {
        if (!additionalWorker.treatmentTypeId) {
          tempErrors.push({
            message: "Nie wybrano usługi",
            field: `additionalWorkers[${this.additionalWorkers.indexOf(additionalWorker)}].treatmentTypeId`,
          })
        }
      });
      if (tempErrors.length > 0) {
        throw new BadRequestException({errors: tempErrors});
      }

      const appointmentData = this.buildAppointmentData();
      const dateDiff = this.actualEndDate - this.actualStartDate;

      generateDates(
        this.actualStartDate,
        this.recurrenceEndOfDayDate,
        this.recurrenceType,
      ).forEach(date => {
        const endDate = new Date(date);
        endDate.setMilliseconds(endDate.getMilliseconds() + dateDiff);

        this.appointmentsPlan.push({
          ...appointmentData,
          startDate: date,
          endDate: endDate,
          appointmentId: generateUuid(),
        });
      });
    },
    disabledDateRecurrenceBoundaries(date) {
      const startDate = new Date(this.actualStartDate);

      startDate.setHours(0,0,0,0);

      return (date < startDate);
    },
    async saveRecurring(verify) {
      this.loading = true;
      this.forbidden = false;
      this.errors = [];
      const recurrenceId = generateUuid();

      try {
        const appointments = this.appointmentsPlan.map((a) => ({
          ...a,
          startDate: stringifyDate(a.startDate),
          endDate: stringifyDate(a.endDate),
          overtime: this.overtime,
          allowWrongNfzWorkerTypes: this.allowWrongNfzWorkerTypes,
        }));

        await create(`/api/recurrent_appointments/${recurrenceId}`, {
          recurrenceType: this.recurrenceType,
          endDate: stringifyDate(this.recurrenceEndOfDayDate),
          verifyOnly: !!verify,
          appointments,
        });
        if (!verify) {
          this.step();
        }
      } catch (exception) {
        if (exception instanceof ForbiddenException) {
          this.forbidden = true;
        }
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    async removeFromPlan(appointmentId) {
      this.appointmentsPlan = this.appointmentsPlan.filter((a) => a.appointmentId !== appointmentId);
      await this.saveRecurring(true);
    },
    updateRecurrentEarlierPossibilityReason(reason) {
      this.recurrenceEarlierPossibilityReason = reason;
      this.appointmentsPlan = this.appointmentsPlan.map((a) => ({
        ...a,
        earlierPossibilityReason: reason.value ? reason: undefined,
      }));
    },
    stepBackAndResetOvertime() {
      this.overtime = false;
      this.allowWrongNfzWorkerTypes = false
      this.errors = [];
      this.stepBack();
    },
    async saveAppointment(appointmentId, appointmentStart) {
      this.scheduledAppointment  = await read(`/api/appointments/${appointmentId}`)

      this.$emit("save", {
        appointmentId,
        appointmentStart: appointmentStart,
        treatmentType: this.modifiedTreatmentType,
        beneficiaryCategory: this.beneficiaryCategory,
        patients: this.scheduledAppointment.patients,
        patientPresence: this.patientPresence,
        worker: {name: `${this.mainWorker.name} ${this.mainWorker.surname}`},
        branchName: this.scheduledAppointment ? this.scheduledAppointment.branch.name : this.selectedBranch?.name,
        address: this.address,
        mobile: this.meetingType === AppointmentLocationType.REMOTE,
      });
      const mainWorker = {
        name: this.worker.name,
        workerId: this.worker.workerId,
        treatmentTypeId: this.modifiedTreatmentType,
        nfzWorkerType: this.nfzWorkerType,
      };

      this.saveAppointmentListItem({
        patients: this.patients,
        mobile: this.meetingType === AppointmentLocationType.REMOTE,
        appointmentId: appointmentId,
        date: appointmentStart,
        group: this.patients.length > 1,
        removePatientAllowedFromGroup: false,
        cancelAllowedFromGroup: false,
        changePatientsAllowedFromGroup: false,
        status: "created",
        mainWorkerId: this.worker.workerId,
        mainWorker,
        workers: [mainWorker, ...this.additionalWorkers],
        branchId: this.scheduledAppointment ? this.scheduledAppointment.branch.branchId : this.selectedBranch?.branchId,
        branchName: this.scheduledAppointment ? this.scheduledAppointment.branch.name : this.selectedBranch?.name,
        beneficiaryCategory: this.beneficiaryCategory,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/variables";

  ::v-deep .modal-dialog.modal-xl {
    max-width: 95vw;
  }

  $select-margin: 5px;

  .treatment-worker-select {
    display: flex;
    align-content: center;
    margin: -$select-margin;
  }

  .worker {
    width: 35%;
    margin: $select-margin;
  }
  .worker-nfz {
    width: 100%;
    margin: $select-margin;
  }

  .treatment-type {
    width: 65%;
    margin: $select-margin;
    border-collapse: initial;
  }

  .alert-purple {
    border: 2px $primary solid;
  }

  .w-40 {
    width:40%;
  }
  .w-5 {
    width:5%;
  }
  .w-15 {
    width: 15%;
  }
  .w-20 {
    width: 20%;
  }
  .visually-hidden {
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0;
  }
</style>
