<template>
  <div class="row">
    <div class="col-12">
      <div class="card-box">
        <h4>Osoba dorosła</h4>
        <hr>
        <b-form-group
          label-for="adultType"
        >
          <adult-type-select
            v-model="adultType"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="adultType"
          />
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import AdultTypeSelect from "../Form/Select/AdultTypeSelect"
import ErrorMessage from "../Form/ErrorMessage";

export default {
  name: "PatientCreateAdultType",
  components: {
    AdultTypeSelect,
    ErrorMessage
  },
  props: {
    errors: {type: Array, default: () => []}
  },
  data() {
    return {
      adultType : null,
    }
  },
  methods: {
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    updateModel() {
      this.$emit("input", this.adultType);
    }
  }
}
</script>

<style scoped>

</style>
