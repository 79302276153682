import {Address} from "./SurveyTypes";

export enum InsuranceType {
  EWUS = "ewus",
  PATIENT_STATEMENT = "patient_statement",
  MEDICAL_CERTIFICATE = "medical_certificate",
}

export interface InsuranceHistory {
  id: string;
  status: boolean | null;
  validFrom: string;
  validTo: string | null;
  addedAt: string;
  addedBy: string | null;
  additionalInformation: string | null;
  documentTitle: string | null;
  documentNumber: string | null;
  institution: string | null;
  author: string | null;
  onlineConsultation: boolean | null;
  statementNumber: number | null;
  insuranceSource: string | null;
  identityConfirmationMethod: string | null;
  statementAuthor: string | null;
  insuranceType: InsuranceType | null;
  guardian: null | {
    name:string;
    surname:string;
    type:string;
    address: Address;
  }

  identificationDocument: null | {
    type: string;
    number: string;
    patientId: string;
  }
}
