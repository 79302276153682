<template>
  <div>
    <div v-if="value.length">
      <div class="treatment-worker-select font-weight-bold">
        <div class="worker">
          Specjalista
        </div>

        <div class="treatment-type">
          Typ personelu
        </div>

        <div
          v-if="allowRemove"
          class="remove"
        >
          Usuń
        </div>
      </div>

      <div
        v-for="(item, index) in value"
        :key="item.workerId"
        class="treatment-worker-select"
      >
        <div class="worker">
          <b-input
            :state="state(`additionalWorkers[${index}].worker`)"
            :value="item.name"
            readonly
          />
          <error-message
            :errors="getSubErrors(`additionalWorkers[${index}]`)"
            ignore-dot-case
            field="worker"
          />
        </div>

        <div class="treatment-type">
          <nfz-worker-type-select
            :value="item.nfzWorkerType"
            :worker-id="item.workerId"
            :state="state(`additionalWorkers[${index}].nfzWorkerType`)"
            @input="changeWorkerType(item.workerId, $event)"
          />
          <error-message
            :errors="getSubErrors(`additionalWorkers[${index}]`)"
            field="nfzWorkerType"
          />
        </div>

        <div
          v-if="allowRemove"
          class="remove text-center"
        >
          <button
            class="btn btn-danger btn-sm"
            type="button"
            @click="removeWorker(item.workerId)"
          >
            <i class="fa fa-times" />
          </button>
        </div>
      </div>
    </div>

    <worker-select
      v-if="allowAdd"
      :exclude-worker-ids="excludeWorkerIds"
      class="mt-2"
      placeholder="Wybierz specjalistę"
      @input="selectWorker"
    />
  </div>
</template>

<script>
import WorkerSelect from "./WorkerSelect";
import ErrorMessage from "../Form/ErrorMessage";
import subErrors from "../../utils/errors/subErrors";
import NfzWorkerTypeSelect from "@/components/Form/Select/NfzWorkerTypeSelect";

export default {
  name: "WorkerTypeTreatmentWorkerSelect",
  components: {
    ErrorMessage,
    NfzWorkerTypeSelect,
    WorkerSelect
  },
  props: {
    value: {type: Array, required: true},
    excludeWorkerIds: {type: Array, default: ()=>[]},
    errors: {type: Array, default: ()=>[]},
    allowRemove: {type: Boolean, default: true},
    allowAdd: {type: Boolean, default: true},
    disableChoiceIfDefined: {type: Boolean, default: false},
    scope: {type: Array, default: null},
    useDefaultTreatmentName: {type: Boolean, default: true},
  },
  data() {
    return {
      workerTypes: [],
      componentErrors: this.errors,
    };
  },
  watch: {
    errors() {
      this.componentErrors = this.errors;
    },
  },
  methods: {
    getSubErrors(field) {
      return subErrors(this.componentErrors, field);
    },
    selectWorker({workerId, name, surname}) {
      const worker = {
        workerId,
        name: `${surname} ${name}`,
        treatmentTypeId: "28da2804-1d84-4e7a-bce6-99381fefdc78", // treatment-id for nfz-configuration
        nfzWorkerType: null,
      };
      const old = this.value.find((item) => item.workerId === workerId);

      if (!old) {
        const value = this.value.concat([worker]);
        this.$emit("input", value);
      }
    },
    changeWorkerType(workerId, workerType) {
      const input = this.value.map((item) => item.workerId === workerId ? ({
        workerId,
        name: item.name,
        treatmentTypeId: item.treatmentTypeId,
        nfzWorkerType: workerType
      }) : item);

      this.$emit("input", input);
    },
    removeWorker(selectedWorkerId) {
      const value = this.value.filter(({workerId}) => workerId !== selectedWorkerId);
      this.componentErrors = [];
      this.$emit("input", value);
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
  },
};
</script>

<style scoped lang="scss">
  $remove-width: 36px;
  $select-margin: 5px;

  .treatment-worker-select {
    display: flex;
    align-content: center;
    margin: -$select-margin;
  }

  .worker {
    width: 35%;
    margin: $select-margin;
  }

  .worker-nfz {
    width: calc(100% - #{$remove-width});
    margin: $select-margin;
  }

  .treatment-type {
    width: calc(65% - #{$remove-width});
    margin: $select-margin;
    border-collapse: initial;
  }

  .remove {
    width: $remove-width;
    margin: $select-margin;
  }
</style>
