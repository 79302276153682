<template>
  <object-select
    :value="value"
    :options="options"
    placeholder="Wybierz typ zleceniodawcy"
    :disabled="false"
    :state="state"
    :multiple="false"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "./ObjectSelect";
import t from "../../../i18n";
import {PaperReferralType} from "../../../types/PaperReferral";

export default {
  name: "PaperReferralTypeSelect",
  components: {
    ObjectSelect,
  },
  props:{
    value: {type: Object, default: null},
    state: ObjectSelect.props.state,
  },

  computed:{
    options() {
      return Object.values(PaperReferralType).map((value) => ({value, name: t(`@paper_referral_type.${value}`)}));
    },
    selectedValue() {
      return this.options.filter((option) => (this.value.includes(option.value)));
    }
  },
  methods: {
    update(option) {
      this.$emit("input", option);
    },
  }
}
</script>
