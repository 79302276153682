





















































































































































import DateTime from "../../DateTime.vue";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import parseDate from "../../../utils/date/parseDate";
import {Component, Mixins, Prop} from "vue-property-decorator";
import {
  AppointmentDocumentStatisticsTabStatus,
  StatisticAppointmentItem
} from "../../../types/AppointmentDocumentStatistics";
import update from "../../../rest/update";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorsMixin from "../../../mixins/ErrorsMixin";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import {isGranted} from "../../../security/isGranted";
import {AppointmentTreatmentBenefitCode} from "../../../types/Appointment";
import AppointmentDocumentStatisticItemErrorMessage from "./AppointmentDocumentStatisticItemErrorMessage.vue";

@Component({
  name: "AppointmentDocumentStatisticsTable",
  components: {
    AppointmentDocumentStatisticItemErrorMessage,
    DateTime,
    ErrorMessage,
  },
})

export default class AppointmentDocumentStatisticsTable extends Mixins(ErrorsMixin) {
  @Prop({type: String, default: null}) readonly status!: AppointmentDocumentStatisticsTabStatus;
  @Prop({type: Array, required: true}) readonly items!: StatisticAppointmentItem[];
  @Prop({type: Array, required: true}) readonly selectedDocumentsId!: string[];

  parseDate = parseDate;
  loading = false;
  dateTimeFormat = DATE_FORMAT.DATE_TIME;
  AppointmentDocumentStatisticsTabStatus = AppointmentDocumentStatisticsTabStatus;
  hasAccessToPatientCard = false;

  get fieldsDefinitions() {
    return [
      {key: "select", label: ""},
      {key: "visitId", label: "ID wizyty (ID sesji)"},
      {key: "patient", label: "Pacjent (teczka)"},
      {key: "workerName", label: "Specjalista"},
      {key: "treatmentType", label: "Typ wizyty"},
      {key: "appointmentDate", label: "Termin wizyty"},
      {key: "branchData", label: "Komórka organizacynja"},
      {key: "errorMessage", label: "Opis błędu"},
      {key: "options", label: ""},
    ].filter(col => {
      return !(this.status !== AppointmentDocumentStatisticsTabStatus.rejected_by_nfz && col.key === "errorMessage")
    });
  }

  patientName(name: string, fileNumber?: string) {
    const fileNumberName = fileNumber ? `(${fileNumber })` : ""

    return `${name} ${fileNumberName}`
  }

  isSessionTreatmentType(treatmentBenefitCode: AppointmentTreatmentBenefitCode) {
    return [
      AppointmentTreatmentBenefitCode.SESSION,
    ].includes(treatmentBenefitCode)
  }

  async mounted() {
    try {
      this.hasAccessToPatientCard = await isGranted(["PATIENT_CARD"]);
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
  }

  async changeStatus(
    changeToStatus: AppointmentDocumentStatisticsTabStatus,
    id: StatisticAppointmentItem["appointmentDocumentId"]
  ) {
    this.$emit("changeStatusRequestLoading", true)
    this.errors = []

    try {
      await update("/api/appointment/document/update-statistics-status", {
        documentsStatuses: [{
          status: changeToStatus,
          documentId: id,
        }]
      });

      this.$emit("updateList");
    } catch (exception) {
      this.errors = processResponseException(exception);
    }

    this.$emit("changeStatusRequestLoading", false)
  }

  onCheckboxChange(data) {
    const {item: {appointmentDocumentId}} = data
    this.$emit("onCheckboxChange", appointmentDocumentId)
  }
}
