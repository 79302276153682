<template>
  <form>
    <div class="form-row">
      <div
        v-if="!hideAutocomplete"
        class="col-12"
      >
        <b-form-group label="Wyszukaj miejscowość/gminę (podaj nazwę gminy)">
          <address-autocomplete
            exclude-street
            :disabled="disabled"
            @address="updateAddress($event)"
          />
        </b-form-group>
      </div>
      <div :class="cityClass">
        <b-form-group label="Miejscowość">
          <b-form-input
            :value="address.city"
            :state="state('city')"
            :disabled="true"
          />
          <error-message
            :errors="errors"
            field="city"
          />
        </b-form-group>
      </div>

      <div :class="zipCodeClass">
        <b-form-group label="Kod pocztowy">
          <b-form-input
            :value="address.zipCode"
            :state="state('zipCode')"
            :disabled="disabled"
            @input="updateValue({zipCode: null !== $event ? $event.trim() : null})"
          />
          <error-message
            :errors="errors"
            field="zipCode"
          />
        </b-form-group>
      </div>

      <div :class="streetClass">
        <b-form-group label="Adres (w przypadku miejscowości w której nie ma ulic wpisz ponownie nazwę miejscowości)">
          <b-form-input
            :value="address.street"
            :state="state('street')"
            :disabled="disabled"
            @input="updateValue({street: null !== $event ? $event.trim() : null})"
          />
          <error-message
            :errors="errors"
            field="street"
          />
        </b-form-group>
      </div>

      <div :class="buildingNumberClass">
        <b-form-group label="Numer domu">
          <b-form-input
            :value="address.buildingNumber"
            :state="state('buildingNumber')"
            :disabled="disabled"
            @input="updateValue({buildingNumber: null !== $event ? $event.trim() : null})"
          />
          <error-message
            :errors="errors"
            field="buildingNumber"
          />
        </b-form-group>
      </div>

      <div :class="apartmentNumberClass">
        <b-form-group label="Numer mieszkania">
          <b-form-input
            :value="address.apartmentNumber"
            :state="state('apartmentNumber')"
            :disabled="disabled"
            @input="updateValue({apartmentNumber: null !== $event ? $event.trim() : null})"
          />
          <error-message
            :errors="errors"
            field="apartmentNumber"
          />
        </b-form-group>
      </div>

      <div :class="provinceClass">
        <b-form-group label="Województwo">
          <b-form-input
            :value="address.province"
            :state="state('province')"
            :disabled="true"
          />
          <error-message
            :errors="errors"
            field="province"
          />
        </b-form-group>
      </div>

      <div :class="districtClass">
        <b-form-group label="Dzielnica">
          <b-form-input
            :value="address.district"
            :state="state('district')"
            :disabled="true"
          />
          <error-message
            :errors="errors"
            field="district"
          />
        </b-form-group>
      </div>

      <div :class="communeClass">
        <b-form-group label="Gmina">
          <b-form-input
            :value="address.commune"
            :state="state('commune')"
            :disabled="true"
          />
          <error-message
            :errors="errors"
            field="commune"
          />
        </b-form-group>
      </div>
    </div>
  </form>
</template>

<script>
import AddressAutocomplete from "./AddressAutocomplete";
import ErrorMessage from "../Form/ErrorMessage";
export default {
  components: {AddressAutocomplete, ErrorMessage},
  props: {
    value: {type: Object, required: true},
    errors: {type: Array, default: () => []},
    inModal: {type: Boolean, default: false},
    horizontal: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false},
    hideAutocomplete: {type: Boolean, default: false}
  },
  data(){
    return {
      address: {...this.value},
    }
  },
  computed: {
    cityClass() {
      let city = this.horizontal ? "col-sm-8" : "col-12";
      if (!this.inModal) {
        city += " col-lg-6";
      }
      return city;
    },
    zipCodeClass() {
      let zipCode = this.horizontal ? "col-sm-4" : "col-12";
      if (!this.inModal) {
        zipCode += " col-lg-2";
      }
      return zipCode;
    },
    streetClass(){
      let street = this.horizontal ? "col-sm-12" : "col-12";
      if (!this.inModal) {
        street += " col-lg-8";
      }
      return street;
    },
    buildingNumberClass(){
      let buildingNumber = this.horizontal ? "col-sm-6" : "col-12";
      if (!this.inModal) {
        buildingNumber += " col-lg-2 d-flex align-items-end";
      }
      return buildingNumber;
    },
    apartmentNumberClass(){
      let apartmentNumber = this.horizontal ? "col-sm-6" : "col-12";
      if (!this.inModal) {
        apartmentNumber += " col-lg-2 d-flex align-items-end";
      }
      return apartmentNumber;
    },
    provinceClass(){
      let province = this.horizontal ? "col-sm-12" : "col-12";
      if (!this.inModal) {
        province += " col-lg-4";
      }
      return province;
    },
    districtClass(){
      let district = this.horizontal ? "col-sm-6" : "col-12";
      if (!this.inModal) {
        district += " col-lg-4";
      }
      return district;
    },
    communeClass(){
      let commune = this.horizontal ? "col-sm-6" : "col-12";
      if (!this.inModal) {
        commune += " col-lg-4";
      }
      return commune;
    }
  },
  watch: {
    value() {
      this.address = {...this.value};
    },
  },
  methods: {
    async updateValue(diff) {
      Object.entries(diff).forEach(([key,value]) => diff[key] = null !== value ? value.trim() : null);
      this.$emit("input", {...this.value, ...diff});
    },
    updateAddress(address){
      this.address = address;
      this.$emit("input", {
        ...address,
        country: "PL"
      });
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
  }
}
</script>
