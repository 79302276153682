<template>
  <ul class="m-0 p-0 list-unstyled">
    <is-granted
      :privileges="['APPOINTMENT_DETAILS']"
      :subject="appointmentId"
      component="li"
    >
      <button
        class="btn btn-sm btn-info m-b-5"
        @click="openAppointmentInfoModal"
      >
        <i class="fas fa-info-circle" />
        Szczegóły wizyty
      </button>
    </is-granted>
    <is-granted
      :privileges="['CHECK_IN_APPOINTMENT']"
      :subject="appointmentId"
      component="li"
    >
      <button
        class="btn btn-sm btn-primary m-b-5"
        @click="openCheckInModal"
      >
        <i class="fa fa-address-card" />
        Potwierdź wizytę
      </button>
    </is-granted>
    <is-granted
      :privileges="['OPEN_APPOINTMENT']"
      :subject="appointmentId"
      component="li"
    >
      <button
        class="btn btn-sm btn-success m-b-5"
        @click="openOpenAppointmentModal"
      >
        <i class="fa fa-door-open" />
        Otwórz wizytę
      </button>
    </is-granted>
    <is-granted
      :privileges="['REOPEN_CANCELED_APPOINTMENT']"
      :subject="appointmentId"
      component="li"
    >
      <button
        class="btn btn-sm btn-success m-b-5"
        @click="openReopenCanceledAppointmentModal"
      >
        <i class="fas fa-reply" />
        Otwórz ponownie
      </button>
    </is-granted>
    <is-granted
      :privileges="['SHOW_APPOINTMENT_DOCUMENT']"
      :subject="appointmentId"
      component="li"
    >
      <button
        :class="`btn btn-sm btn-${status === 'opened' ? 'success':'secondary'} m-b-5`"
        @click="showDocument"
      >
        <i class="fa fa-eye" />
        {{ status === 'opened' ? 'Otwórz wizytę' : 'Pokaż dokument' }}
      </button>
    </is-granted>
    <is-granted
      :privileges="['WITHDRAW_APPOINTMENT_CHECK_IN_COMMAND']"
      :subject="appointmentId"
      component="li"
    >
      <button
        class="btn btn-sm btn-secondary m-b-5"
        @click="openCheckInWithdrawalModal"
      >
        <i class="fa fa-history" />
        Cofnij potwierdzenie wizyty
      </button>
    </is-granted>
    <is-granted
      :privileges="['RESCHEDULE_APPOINTMENT']"
      :subject="appointmentId"
      component="li"
    >
      <button
        class="btn btn-sm btn-secondary m-b-5"
        :disabled="scheduleDataLoading"
        @click="editAppointment"
      >
        <i
          :class="scheduleDataLoading ? 'fa-spin fa-spinner' : 'fa-pencil-alt'"
          class="fas"
        />
        Edytuj wizytę
      </button>
    </is-granted>
    <is-granted
      v-if="removePatientAllowedFromGroup"
      :privileges="['DELETE_APPOINTMENT_PATIENTS']"
      :subject="appointmentId"
      component="li"
    >
      <button
        class="btn btn-sm btn-pink m-b-5"
        @click="openRemovePatientFromAppointmentModal"
      >
        <i class="fa fa-user-minus" />
        Wypisz pacjenta
      </button>
    </is-granted>
    <is-granted
      v-if="changePatientsAllowedFromGroup"
      :privileges="['ADD_PATIENTS_TO_APPOINTMENT']"
      :subject="appointmentId"
      component="li"
    >
      <button
        class="btn btn-sm btn-success m-b-5"
        @click="openChangePatientsModal"
      >
        <i class="fa fa-user-cog" />
        Dodaj/Usuń pacjentów
      </button>
    </is-granted>
    <template v-if="!group || cancelAllowedFromGroup">
      <is-granted
        :privileges="['CANCEL_APPOINTMENT_DEFERRED']"
        :subject="appointmentId"
        component="li"
      >
        <button
          class="btn btn-sm btn-pink m-b-5"
          @click="openCancelAppointmentDeferredModal"
        >
          <i class="fa fa-ban" />
          Wniosek o odwołanie wizyty
        </button>
      </is-granted>
      <is-granted
        :privileges="['CANCEL_APPOINTMENT']"
        :subject="appointmentId"
        component="li"
      >
        <button
          class="btn btn-sm btn-pink m-b-5"
          @click="openCancelAppointmentModal"
        >
          <i class="fa fa-ban" />
          Odwołaj wizytę
        </button>
      </is-granted>
    </template>
    <is-granted
      v-if="!patientId && patients.length === 1"
      :privileges="['PATIENT_CARD']"
      component="li"
      :subject="patients[0].patientId"
    >
      <router-link
        v-if="!patientId && patients.length === 1"
        class="btn btn-sm btn-secondary m-b-5"
        :to="{name: 'patientCard', params: {patientId: patients[0].patientId}}"
      >
        <i class="fa fa-user" />
        Karta pacjenta
      </router-link>
    </is-granted>
    <is-granted
      :privileges="['UPDATE_APPOINTMENT_PARAMETERS']"
      :subject="appointmentId"
      component="li"
    >
      <button
        class="btn btn-sm btn-pink m-b-5"
        @click="openChangeAppointmentParametersModal"
      >
        <i class="fa fa-cog" />
        Określ parametry wizyty
      </button>
    </is-granted>
    <is-granted
      v-if="!continuousStay"
      :privileges="['GET_APPOINTMENT_WORKERS_WITH_TREATMENT']"
      :subject="appointmentId"
      component="li"
    >
      <button
        class="btn btn-sm btn-primary m-b-5"
        @click="openReportTreatmentTypeModal"
      >
        <i class="fa fa-flag" />
        Raportuj typ usługi
      </button>
    </is-granted>
    <is-granted
      :privileges="['CHANGE_APPOINTMENT_DATE']"
      :subject="appointmentId"
      component="li"
    >
      <button
        class="btn btn-sm btn-warning m-b-5"
        @click="openEditAppointmentDateModal"
      >
        <i class="fas fa-clock text-primary" />
        Zmień datę wizyty
      </button>
    </is-granted>
  </ul>
</template>
<script>
import IsGranted from "../../IsGranted";
import {appointmentItemActionsMixin} from "../../../mixins/appointmentItemActionsMixin";

export default {
  name: "AppointmentItemActionsButtons",
  components: {IsGranted,},
  mixins: [appointmentItemActionsMixin],
};
</script>
<style scoped lang="scss">
  @import "../../../styles/variables";

  ul li {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;

    &:empty {
      margin-right: 0;
    }
  }

  .btn-pink {
    background-color: $pink;
    color: $white;

    &:focus, &.focus,
    &:active, &.active,
    &:hover, &.hover {
      background-color: darken($pink, 5%);
    }
  }

  .appointment-action {
    white-space: normal;
  }
</style>
