
















































































































































































































































































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorMessage from "../Form/ErrorMessage.vue";
import {TheMask} from "vue-the-mask";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import update from "../../rest/update";
import processResponseException from "../../utils/errors/processResponseException";
import read from "../../rest/read";

interface RequestBenefit {
  serverSmtp: string;
  portSmtp: null|number;
  serverPop3: string;
  portPop3: null|number;
  incomingEmail: string;
  outgoingEmail: null|string;
  username: null|string;
  password: null|string;
  contractNumber: null|string;
  nrGenSWIAD: null|string;
  nrGenLIOCZ: null|string;
  nrVersionLIOCZ: null|string;
}

interface RSzoi {
  login: string
  password: string
  url: string
}

const benefitInitialState: ResponseBenefit = {
  serverSmtp: "",
  portSmtp: null,
  serverPop3: "",
  portPop3:  null,
  username: "",
  password: "",
  outgoingEmail: "",
  incomingEmail: "",
  contractNumber: "",
  validOutgoingTransport: null,
  nrGenSWIAD: "",
  nrGenLIOCZ: "",
  nrVersionLIOCZ: ""
}

const szoiInitialState: RSzoi = {
  login: "",
  password: "",
  url: ""
}

interface ResponseBenefit extends RequestBenefit {
  validOutgoingTransport: null|boolean;
}

const Tabs = {
  Benefit : "benefit_provider",
  Szoi : "szoi",
}


@Component({
  name: "EstablishmentNfzConfigurationModal",
  components: {
    ErrorMessage,
    TheMask,
  }
})
export default class EstablishmentSmtpModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly branchId!: string;
  @Prop({type: String, required: true}) readonly name!: string;

  loading: boolean = false;
  saved: boolean = true;
  benefitData: ResponseBenefit = benefitInitialState;
  szoiData: RSzoi = szoiInitialState;
  currentActiveTab: string = Tabs.Benefit;

  @Watch("currentActiveTab") async onActiveTabChanged(): Promise<void>
  {
    await this.fetchData();
  };

  get modalId(): string {
    return `establishment-nfz-configuration-${this.branchId}`
  }

  get title(): string {
    return `Edycja ustawień NFZ podmiotu ${this.name}`
  }

  get benefit(): string {
    return Tabs.Benefit;
  }

  get szoi(): string {
    return Tabs.Szoi;
  }

  closeModal(): void {
    this.$bvModal.hide(this.modalId);
  }

  changeTab(index): void{
    this.currentActiveTab = index === 0 ? Tabs.Benefit : Tabs.Szoi;
  }

  async submit(): Promise<void> {
    this.errors = [];
    this.loading = true;
    if(this.currentActiveTab === this.benefit){
      await this.submitBenefit();
    }
    if(this.currentActiveTab === this.szoi){
      await this.submitSzoi();
    }
    this.loading = false;
    if(this.errors.length === 0){
      this.closeModal();
    }
  }

  async submitBenefit(){
    try {
      await update(`/api/branches/${this.branchId}/nfz-configuration`, this.benefitData);
      this.$emit("edited");
      this.saved = true;
    } catch (e) {
      this.errors = processResponseException(e);
    }
  }

  async submitSzoi(){
    try {
      await update(`/api/branches/${this.branchId}/szoi-configuration`, this.szoiData);
      this.$emit("edited");
      this.saved = true;
    } catch (e) {
      this.errors = processResponseException(e);
    }
  }

  editBenefit() {
    this.benefitData.validOutgoingTransport = null;
    this.saved = false;
  }

  editSzoi() {
    this.saved = false;
  }

  async fetchData(test: boolean = false): Promise<void> {
    this.loading = true;
    this.errors = [];
    if(this.currentActiveTab === this.benefit){
      await this.fetchBenefit(test);
    }
    if(this.currentActiveTab === this.szoi){
      await this.fetchSzoi();
    }
    this.loading = false;
  }

  async fetchBenefit(test: boolean): Promise<void> {
    try {
      const {
        incomingEmail,
        outgoingEmail,
        serverSmtp,
        portSmtp,
        serverPop3,
        portPop3,
        username,
        password,
        contractNumber,
        validOutgoingTransport,
        nrGenSWIAD,
        nrGenLIOCZ,
        nrVersionLIOCZ
      } = await read<ResponseBenefit>(`/api/branches/${this.branchId}/nfz-configuration`, {test});
      this.benefitData.serverSmtp = serverSmtp || "";
      this.benefitData.portSmtp = portSmtp || null;
      this.benefitData.serverPop3 = serverPop3 || "";
      this.benefitData.portPop3 = portPop3 || null;
      this.benefitData.username = username || "";
      this.benefitData.password = password || "";
      this.benefitData.outgoingEmail = outgoingEmail || "";
      this.benefitData.incomingEmail = incomingEmail || "";
      this.benefitData.contractNumber = contractNumber || "";
      this.benefitData.validOutgoingTransport = validOutgoingTransport;
      this.benefitData.nrGenSWIAD = nrGenSWIAD;
      this.benefitData.nrGenLIOCZ = nrGenLIOCZ;
      this.benefitData.nrVersionLIOCZ = nrVersionLIOCZ;
      this.saved = true;
    } catch(e) {
      this.errors = processResponseException(e);
    }
  }

  async fetchSzoi(): Promise<void> {
    try {
      const {
        login,
        password,
        url,
      } = await read<RSzoi>(`/api/branches/${this.branchId}/szoi-configuration`);
      this.szoiData.login = login || "";
      this.szoiData.password = password || "";
      this.szoiData.url = url || "";
      this.saved = true;
    } catch(e) {
      this.errors = processResponseException(e);
    }
  }
}

