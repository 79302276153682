<template>
  <tooltip
    v-if="patientCreateAllowed"
    component="router-link"
    class="btn btn-primary d-inline-flex align-items-center h-100"
    tooltip-content="Zarejestruj pacjenta"
    :to="{name: 'createPatientView'}"
  >
    <i class="fas fa-user-plus" />
  </tooltip>
</template>

<script>
import Tooltip from "../../Tooltip";
import {registerPatientButtonMixin} from "../../../mixins/registerPatientButtonMixin";

export default {
  name: "RegisterPatientTooltipButton",
  components: {Tooltip},
  mixins: [registerPatientButtonMixin],
}
</script>
