<template>
  <div>
    <div
      v-if="index !== null"
      class="d-flex align-items-center justify-content-between"
    >
      <h4 class="font-weight-bold">
        {{ index +1 }})
        <i18n :msgid="`@person-eligible.${eligiblePerson.type}`" />
      </h4>
      <button
        v-if="multiple && age>18 || eligiblepersons>1"
        class="btn btn-sm btn-outline-secondary"
        @click="removeEligiblePerson"
      >
        <i class="fa fa-trash" />
        usuń osobę
      </button>
    </div>
    <div class="row">
      <b-form-group
        label="Imię"
        class="col-md-6"
      >
        <b-form-input
          v-model.trim="eligiblePerson.name"
          :disabled="disabled"
          :state="state('name')"
        />
        <error-message
          :errors="errors"
          field="name"
        />
        <router-link
          v-if="eligiblePerson.fileNumber"
          :to="{name: 'patientCard', params: {patientId: eligiblePerson.personId}}"
          tag="button"
          class="btn btn-primary m-t-10"
        >
          Numer teczki: {{ eligiblePerson.fileNumber }}
        </router-link>
      </b-form-group>
      <b-form-group
        label="Nazwisko"
        class="col-md-6"
      >
        <b-form-input
          v-model.trim="eligiblePerson.surname"
          :disabled="disabled"
          :state="state('surname')"
        />
        <error-message
          :errors="errors"
          field="surname"
        />
      </b-form-group>
      <template v-if="formExpanded">
        <b-form-group
          :label="peselLabel"
          class="col-md-6"
        >
          <b-form-input
            v-model.trim="eligiblePerson.pesel"
            :disabled="disabled || loadingDataByPesel"
            :state="state('pesel')"
            @input="getDataByPesel"
          />
          <span
            v-if="loadingDataByPesel"
            class="fa fa-spinner fa-spin fa-2x"
          />
          <error-message
            :errors="errors"
            field="pesel"
          />
        </b-form-group>
        <b-form-group
          label="Telefon kontaktowy (opcjonalnie)"
          class="col-md-6"
        >
          <b-form-input
            v-model.trim="eligiblePerson.phoneNumber"
            :disabled="disabled"
            :state="state('phoneNumber')"
          />
          <error-message
            :errors="errors"
            field="phoneNumber"
          />
        </b-form-group>
        <b-form-group
          label="Adres e-mail (opcjonalnie)"
          class="col-md-6"
        >
          <b-form-input
            v-model.trim="eligiblePerson.email"
            :disabled="disabled"
            :state="state('email')"
          />
          <error-message
            :errors="errors"
            field="email"
          />
        </b-form-group>
      </template>
    </div>
    <template
      v-if="withAddress"
    >
      <div class="border-top pt-2 text-right">
        <button
          class="mb-2 btn btn-sm btn-primary"
          @click="addPatientAddress"
        >
          <i class="fa fa-home" />
          Kopiuj adres pacjenta
        </button>
      </div>
      <address-form
        v-model="eligiblePerson.address"
        :disabled="disabled"
        :visible-country="false"
        :errors="getSubErrors('address')"
        horizontal
      />
    </template>

    <b-form-group
      v-if="formExpanded"
      label="Dodatkowy opis"
    >
      <textarea-counter
        v-model="eligiblePerson.description"
        :disabled="disabled"
        :max-characters-count="2048"
        :rows="2"
      />
    </b-form-group>
  </div>
</template>

<script>
import AddressForm from "../Address/AddressForm";
import ErrorMessage from "../Form/ErrorMessage";
import subErrors from "../../utils/errors/subErrors";
import TextareaCounter from "../Form/Textarea/TextareaCounter";
import I18n from "../i18n";
import read from "../../rest/read";

export default {
  components: {
    I18n,
    TextareaCounter,
    ErrorMessage,
    AddressForm,
  },
  props: {
    person: {type: Object, default: () => ({})},
    disabled: {type: Boolean, default: false},
    errors: {type: Array, default: () => []},
    formExpanded: {type: Boolean, default: false},
    optionalPesel: {type: Boolean, default: false},
    withAddress: {type: Boolean, default: true},
    multiple: {type: Boolean, default: true},
    index: {type: Number, required: false, default: null},
    age: {type: Number, required: true},
    eligiblepersons: {type: Number, required: true},
  },
  data() {
    return {
      eligiblePerson: this.person,
      peselLabel: this.optionalPesel ? "PESEL (opcjonalnie)" : "PESEL",
      loadingDataByPesel: false
    };
  },
  watch: {
    eligiblePerson() {
      this.$emit("updateEligiblePerson", this.eligiblePerson);
    },
    person(){
      this.eligiblePerson = this.person;
    }
  },
  methods: {
    addPatientAddress() {
      this.$emit("addPatientAddress", this.index);
    },
    removeEligiblePerson() {
      this.$emit("removeEligiblePerson", this.eligiblePerson);
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    getSubErrors(field) {
      return subErrors(this.errors, field);
    },
    async getDataByPesel(pesel){
      if(pesel && pesel.length === 11){
        this.loadingDataByPesel = true
        try {
          let personData = await read(`/api/patient/get-by-pesel/${pesel}`);
          this.eligiblePerson = {
            ...this.eligiblePerson,
            ...personData
          }
          this.loadingDataByPesel = false;
        } catch (exception) {
          this.loadingDataByPesel = false;
        }
      }
    }
  },
}
</script>
