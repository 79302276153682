var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(_vm.withLabel ? {} : {
    'width': _vm.size,
    'height': _vm.size,
  })},[_c('tooltip',{staticClass:"fas",class:{
      'text-secondary': null == _vm.status,
      'text-danger': false === _vm.status,
      'text-success': true === _vm.status,
      'fa-check-circle': true === _vm.status,
      'fa-question-circle': null == _vm.status,
      'fa-exclamation-circle': false === _vm.status,
    },style:({
      'font-size': _vm.size,
    }),attrs:{"tooltip-content":_vm.text,"component":"i"}}),(_vm.withLabel)?_c('span',[_vm._v(" "+_vm._s(_vm.shortText)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }