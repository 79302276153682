<template>
  <div>
    <div
      v-if="date"
      class="form-group row"
    >
      <i class="col-2 text-center fa fa-2x fa-clock" />
      <div class="col-10">
        <input
          :value="`${appointmentDate}  ${appointmentTime}`"
          readonly
          class="form-control"
        >
      </div>
    </div>
    <div
      v-if="patients.length"
      class="form-group row"
    >
      <i
        :class="patients.length > 1 ? 'fa-users' : 'fa-user'"
        class="col-2 text-center fa fa-2x"
      />
      <div class="col-10">
        <appointment-patients
          :items="patients"
          class="card-box disabled-data"
        />
        <b-badge
          v-if="!patientPresence"
          class="mt-1"
          variant="dark"
        >
          Wizyta bez obecności pacjenta
        </b-badge>
      </div>
    </div>

    <div
      v-if="mainWorker"
      class="form-group row"
    >
      <i class="col-2 text-center fa fa-2x fa-user-md" />
      <div class="col-10">
        <input
          :value="mainWorker.name"
          readonly
          class="form-control"
        >
      </div>
    </div>

    <div
      v-if="workers && mainWorkerId"
      class="form-group row"
    >
      <i class="col-2 text-center fa fa-2x fa-user-md" />
      <div class="col-10">
        <worker-collapse-list
          :main-worker-id="mainWorkerId"
          :workers="workers"
          class="card-box"
          style="border-color: #cfcfcf; color: rgba(0, 0, 0, 0.6)"
        />
      </div>
    </div>

    <div
      v-if="treatmentType"
      class="form-group row"
    >
      <i class="col-2 text-center fa fa-2x fa-briefcase" />
      <div class="col-10">
        <div class="card-box disabled-data">
          <treatment-type-name :value="treatmentType" />
        </div>
      </div>
    </div>
    <div
      v-if="branchName"
      class="form-group row"
    >
      <i class="col-2 text-center fa fa-2x fa-map-marker-alt" />
      <div class="col-10">
        <input
          :value="branchName"
          readonly
          class="form-control"
        >
      </div>
    </div>
    <div
      v-if="displayAddress"
      class="form-group row"
    >
      <i class="col-2 text-center fa fa-2x fa-map-marker-alt" />
      <div class="col-10">
        <div class="row px-2">
          <input
            :value="address.city"
            readonly
            class="form-control col-sm-8"
          >
          <input
            :value="address.zipCode"
            readonly
            class="form-control col-sm-4"
          >
          <input
            :value="address.street"
            readonly
            class="form-control col-12"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import stringifyDate from "../../utils/date/stringifyDate";
import AppointmentPatients from "./AppointmentPatients";
import WorkerCollapseList from "../Worker/WorkerCollapseList";
import TreatmentTypeName from "../TreatmentType/TreatmentTypeName";

export default {
  components: {TreatmentTypeName, WorkerCollapseList, AppointmentPatients},
  props: {
    date: {type: Date, default: null},
    patients: {type: Array, default: () => []},
    patientPresence: {type: Boolean, default: true},
    mainWorker: {type: Object, default: null},
    workers: {type: Array, default: null},
    mainWorkerId: {type: String, default: null},
    treatmentType: {type: String, default: null},
    address: {type: Object, default: null},
    branchName: {type: String, default: null},
  },
  computed: {
    group() {
      return this.patients.length >= 2;
    },
    appointmentTime() {
      return this.date ? stringifyDate(this.date, DATE_FORMAT.TIME) : null;
    },
    appointmentDate() {
      return this.date ? stringifyDate(this.date, DATE_FORMAT.DATE) : null;
    },
    displayAddress() {
      if (!this.address) {
        return false;
      }

      return this.address.city || this.address.street || this.address.zipCode;
    }
  }
}
</script>
