<template>
  <div>
    <b-modal
      :id="modalId"
      no-close-on-backdrop
      title="Dodaj problem w opiece psychologicznej"
      size="lg"
      title-tag="h3"
      @hide="clearVariables"
    >
      <div class="card-box">
        <div class="mb-3">
          <label class="font-bold">
            Kod kategorii problemu w opiece psychologicznej
          </label>

          <psychological-care-problem-category-select
            v-model="psychologicalCareProblemCategory"
            :suggested-codes="suggestedCodes"
            :disabled="loadingSuggestedCode"
          />
          <error-message
            :errors="errors"
            :field="'psychologicalCareProblemCategory'"
          />
        </div>

        <div>
          <label class="font-bold">
            Notatka
          </label>

          <textarea-counter
            v-model="note"
            :rows="6"
            :max-characters-count="1500"
          />
          <error-message
            :errors="errors"
            :field="'note'"
          />
        </div>
      </div>

      <template #modal-footer>
        <button
          class="btn btn-secondary"
          @click="hideModal"
        >
          Anuluj
        </button>

        <button
          :disabled="isLoading"
          class="btn btn-primary"
          @click="createPsychologicalProblem"
        >
          <i
            :class="isLoading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {generateUuid} from "@/utils/uuid/generateUuid";
import TextareaCounter from "../Form/Textarea/TextareaCounter";
import ErrorMessage from "../Form/ErrorMessage";
import subErrors from "../../utils/errors/subErrors";
import PsychologicalCareProblemCategorySelect
  from "@/components/PsychologicalDiagnosis/PsychologicalCareProblemCategory/PsychologicalCareProblemCategorySelect"
import stringifyDate from "@/utils/date/stringifyDate";
import DATE_FORMAT from "@/utils/date/DATE_FORMAT";
import read from "@/rest/read";

export default {
  components: {
    ErrorMessage,
    TextareaCounter,
    PsychologicalCareProblemCategorySelect
  },
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      psychologicalCareProblemCategory: null,
      note: null,
      isLoading: false,
      modalId: "add-psychological-diagnosis-result",
      loadingSuggestedCode: false,
      suggestedCodes: [], // string[]
      errors: [],
    };
  },
  async mounted () {
    this.loadingSuggestedCode = true
    const response = await read(`/api/patient/${this.patientId}/psychological-diagnosis-result`, {
      perPage: 9999,
      page: 1,
    });
    this.suggestedCodes = response.items.map(item => item.problemCategory);
    this.loadingSuggestedCode = false
  },
  methods: {
    createPsychologicalProblem() {
      this.errors = []
      this.isLoading = true;

      if (!this.psychologicalCareProblemCategory) {
        this.errors = [
          {
            field: "psychologicalCareProblemCategory",
            message: "To pole jest wymagane"
          }
        ]
      } else {
        const item = {
          psychologicalDiagnosisResultId: generateUuid(),
          problemCategory: this.psychologicalCareProblemCategory.value,
          note: this.note ? this.note.trim() : "",
          diagnosisDate: stringifyDate(new Date(), DATE_FORMAT.DATE)
        }

        this.$emit("psychologicalDiagnosisResultCreated", item);
        this.hideModal();
      }

      this.isLoading = false;
    },
    hideModal() {
      this.$bvModal.hide(this.modalId);
    },
    clearVariables() {
      this.errors = [];
      this.psychologicalCareProblemCategory = null;
      this.note = null;
    },
    getSubErrors(field) {
      return subErrors(this.errors, field);
    }
  },
}
</script>
