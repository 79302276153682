<template>
  <div class="row">
    <div class="col-12">
      <div class="card-box">
        <h4>Kontynuacja nauki osoby pełnoletniej</h4>
        <b-form-group label="Data rozpoczęcia">
          <date-picker
            v-model="continuingEducationStartDate"
            :state="state('continuingEducationStartDate')"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="continuingEducationStartDate"
          />
        </b-form-group>
        <b-form-group label="Data zakończenia">
          <date-picker
            v-model="continuingEducationEndDate"
            :state="state('continuingEducationEndDate')"
            @input="updateModel"
          />
          <error-message
            :errors="errors"
            field="continuingEducationEndDate"
          />
        </b-form-group>
      </div>
    </div>
    <b-modal
      id="attachmentNotification"
      no-close-on-backdrop
      title="Powiadomienie"
      title-tag="h3"
    >
      Jeżeli została dodana kontynuacja nauki, pamiętaj o dodaniu załącznika.
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="closeNotification()"
        >
          Zamknij
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ErrorMessage from "../Form/ErrorMessage";
import DatePicker from "../Form/DatePicker/DatePicker.vue"
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";

export default {
  name: "PatientCreateContinuingEducation",
  components: {
    ErrorMessage,
    DatePicker,
  },
  props: {
    errors: {type: Array, default: () => []}
  },
  data() {
    return {
      continuingEducationEndDate : null,
      continuingEducationStartDate : null
    }
  },
  methods: {
    closeNotification(){
      this.$bvModal.hide("attachmentNotification");
      this.$emit("redirectToPatientCard")
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    updateModel() {
      this.continuingEducationStartDate = (this.continuingEducationStartDate) ? new Date(this.continuingEducationStartDate) : null
      this.continuingEducationEndDate = (this.continuingEducationEndDate) ? new Date(this.continuingEducationEndDate) : null

      this.$emit("input", {
        continuingEducationStartDate: (this.continuingEducationStartDate) ? stringifyDate(this.continuingEducationStartDate, DATE_FORMAT.DATE) : null,
        continuingEducationEndDate: (this.continuingEducationEndDate) ? stringifyDate(this.continuingEducationEndDate, DATE_FORMAT.DATE) : null
      });
    }
  },
}
</script>

<style scoped>

</style>
