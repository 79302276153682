<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-xl-3">
        <b-form-group label="Pacjent">
          <patient-select
            v-model="formData.selectedPatient"
          />
        </b-form-group>
      </div>
      <div class="col-lg-6 col-xl-3">
        <b-form-group
          :label="nfzConfiguration ? 'Koordynator opieki' : 'Case Manager'"
        >
          <worker-select
            v-model="formData.selectedWorkers"
            multiple
          />
        </b-form-group>
      </div>
      <div class="col-lg-6 col-xl-3">
        <b-form-group label="Status">
          <ipz-status-select
            v-model="formData.selectedStatus"
          />
        </b-form-group>
      </div>
      <div
        v-if="showMobileTeamColumn"
        class="col-lg-6 col-xl-3"
      >
        <b-form-group label="Zespół mobilny">
          <worker-select
            v-model="formData.selectedMobileTeamMembers"
            multiple
          />
        </b-form-group>
      </div>
      <div class="col-12 text-right">
        <is-granted
          :privileges="['IPZ_LIST_EXPORT']"
          component="fragment"
        >
          <a
            :href="exportUrl"
            class="btn btn-outline-secondary m-1"
            target="_blank"
          >
            <i class="fa fa-atom" />
            Eksportuj
          </a>
        </is-granted>
        <button
          class="btn btn-primary m-1"
          style="min-width: 7rem"
          @click="submit"
        >
          <i class="fa fa-search" />
          Szukaj
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import IsGranted from "../../../components/IsGranted";
import PatientSelect from "../../Patient/PatientSelect";
import IpzStatusSelect from "./IpzStatusSelect";
import WorkerSelect from "../../Worker/WorkerSelect";
import {mapState} from "vuex";

export default {
  components: {
    PatientSelect,
    IpzStatusSelect,
    WorkerSelect,
    IsGranted
  },

  props: {
    selectedStatus: {type: String, default: null},
    selectedPatient: {type: String, default: null},
    selectedWorkers: {type: Array, default: null},
    selectedMobileTeamMembers: {type: Array, default: null},
    exportUrl: {type: String, required: true},
  },
  data() {
    return {
      formData: {
        selectedStatus: this.selectedStatus,
        selectedPatient: this.selectedPatient,
        selectedWorkers: this.selectedWorkers,
        selectedMobileTeamMembers: this.selectedMobileTeamMembers,
      }
    }
  },
  computed: {
    submittedData() {
      return {
        ...this.formData,
        selectedPatient: this.formData.selectedPatient
          ? this.formData.selectedPatient.patientId
          : null,
      };
    },
    ...mapState({
      clinicParameters: state => state.clinicParameters.parameters,
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration,
    }),
    showMobileTeamColumn() {
      return this.clinicParameters && this.clinicParameters.ageGroupTarget !== "children";
    }
  },

  watch: {
    selectedStatus() {
      this.formData.selectedStatus = this.selectedStatus;
    },
    selectedPatient() {
      this.formData.selectedPatient = this.selectedPatient;
    },
    selectedWorkers() {
      this.formData.selectedWorkers = this.selectedWorkers;
    },
    selectedMobileTeamMembers() {
      this.formData.selectedMobileTeamMembers = this.selectedMobileTeamMembers;
    }
  },

  methods: {
    submit() {
      this.$emit("submit", this.submittedData);
    },
  },
}
</script>
