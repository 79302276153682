














































import {Component, Prop, Vue} from "vue-property-decorator";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import {Error} from "../../../../rest";
import update from "../../../../rest/update";
import processResponseException from "../../../../utils/errors/processResponseException";
import BranchSelect from "../../../Branch/BranchSelect.vue";
import ErrorMessage from "../../../Form/ErrorMessage.vue";

@Component({
  name: "ChangeContinuousStayBranchModal",
  components: {BranchSelect, ErrorMessage},
  mixins: [errorsMixin],
})
export default class ChangeContinuousStayBranchModal extends Vue {
  public readonly modalId = "changeContinuousStayBranchModal";
  @Prop({required: true}) readonly stayId: string;
  @Prop({required: true}) readonly branchId: string;
  public selectedBranch: null | string = null;
  public loading: boolean = false;
  public errors: Error[] = [];

  public mounted(): void {
    this.selectedBranch = this.branchId;
  }

  public close(): void {
    this.$bvModal.hide(this.modalId);
    this.selectedBranch = null;
    this.loading = false;
  }

  public async submit(): Promise<void> {
    const branchId = this.selectedBranch;

    if (branchId === this.branchId) {
      this.close();

      return;
    }

    const resource = `/api/continuous-stay/${this.stayId}/branch`;
    try {
      await update(resource, {branchId});
      this.close();
      this.$emit("saved", {branchId});
    } catch (error) {
      this.errors = processResponseException(error);
    }
  }
}
