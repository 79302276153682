




















































import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import processResponseException from "../../utils/errors/processResponseException";
import read from "../../rest/read";
import Card from "../../components/Card.vue";
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import IsGranted from "../../components/IsGranted.vue";
import {
  EstablishmentUnitPriceNumberDiscriminant,
  EstablishmentUnitPriceNumberDiscriminantUnit
} from "../../types/Establishment";
import EstablishmentUnitPriceNumberDiscriminantTable
  from "../../components/Establishment/EstablishmentUnitPriceNumberDiscriminantTable.vue";

@Component({
  name: "EstablishmentUnitPriceNumberDiscriminantContractDataPage",
  components: {
    EstablishmentUnitPriceNumberDiscriminantTable,
    IsGranted,
    ErrorMessage,
    Card,
  },
})

export default class EstablishmentUnitPriceNumberDiscriminantContractDataPage extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly establishmentId!: string;

  private loading: boolean = true;
  public editing: boolean = false;
  public units: EstablishmentUnitPriceNumberDiscriminantUnit[] = [];
  public establishmentName: string = "";

  public availableYears: number[] = [2022, 2023, 2024, 2025]
  public selectedYear: number = new Date().getFullYear()

  async mounted():Promise<void> {
    await this.fetchData();
  }

  async fetchData(): Promise<void> {
    this.loading = true;
    try {
      const {establishmentName, units} = await read<EstablishmentUnitPriceNumberDiscriminant>(
        `/api/establishment/${this.establishmentId}/unit-price-number-discriminant`
      );
      this.establishmentName = establishmentName;
      this.units = units;
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  async update(): Promise<void> {
    await this.fetchData();
  }

  private openTableData(year: number): void {
    this.selectedYear = year;
  }

  setEditing(value: boolean) {
    this.editing = value
  }
}
