<template>
  <report-container report-id="request_medical_records">
    <div class="card-box row">
      <b-form-group
        class="col-sm-4 p-t-10"
        label="Data początkowa"
      >
        <date-picker
          v-model="startDate"
        />
        <error-message
          :errors="errors"
          field="startDate"
        />
      </b-form-group>

      <b-form-group
        class="col-sm-4 p-t-10"
        label="Data końcowa"
      >
        <date-picker
          v-model="endDate"
        />
        <error-message
          :errors="errors"
          field="endDate"
        />
      </b-form-group>
      <b-form-group
        class="col-sm-4 p-t-10"
        label="Komórki organizacyjne"
      >
        <branch-select
          v-model="branches"
          :multiple="true"
          :check-if-any-branch-exists="true"
        />
      </b-form-group>

      <div class="col-12">
        <a
          :href="reportUrl"
          class="btn btn-block btn-primary"
        >
          <i class="fas fa-file-excel" />
          Generuj
        </a>
      </div>
    </div>
  </report-container>
</template>

<script>
import ReportContainer from "../../components/Report/ReportContainer";
import ErrorMessage from "../../components/Form/ErrorMessage";
import DatePicker from "../../components/Form/DatePicker/DatePicker";
import BranchSelect from "../../components/Branch/BranchSelect";
import {createUrl} from "../../utils/pageUrl/createUrl";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";

export default {
  name: "RequestMedicalRecordsReport",
  components: {ReportContainer,ErrorMessage, DatePicker,BranchSelect},
  data() {
    return {
      startDate: null,
      endDate: null,
      branches: [],
      errors: [],
      reportUrl: "/api/request-medical-records-report"
    }
  },
  watch:{
    startDate() {
      this.setReportUrl()
    },
    endDate(){
      this.setReportUrl()
    },
    branches(){
      this.setReportUrl()
    }
  },
  methods:{
    setReportUrl() {
      this.reportUrl =  createUrl(this.reportUrl, this.filters());
    },

    filters() {
      return {
        startDate: this.startDate ? stringifyDate(this.startDate, DATE_FORMAT.DATE) : null,
        endDate: this.endDate ? stringifyDate(this.endDate, DATE_FORMAT.DATE) : null,
        branches: this.branches
      }
    }
  }
}
</script>

<style scoped>

</style>
