<template>
  <object-select
    :options="options"
    :value="selectedValue"
    :placeholder="placeholder"
    :state="state"
    :disabled="disabled"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "./ObjectSelect.vue"
import t from "@/i18n";

export default {
  name: "AdultTypeSelect",
  components: {ObjectSelect},
  props: {
    value: {type: [String, Object], default: null},
    state: ObjectSelect.props.state,
    placeholder: {type: String, default: "Wybierz"},
    disabled: {type: Boolean, default: false}
  },
  data() {
    return {
      optionValues: [
        {value: "parent"},
        {value: "support_network"},
        {value: "specialist"}
      ]
    }
  },
  computed: {
    options() {
      return this.optionValues.map(option => ({
        ...option,
        name: t(`@adult-type.${option.value}`),
      }));
    },
    selectedValue() {
      if(!this.value) {
        return null
      } else {
        return this.options.find((option) => option.value === this.value)
      }
    }
  },
  methods: {
    update(option) {
      this.$emit("input", option ? option.value : null)
    }
  }
}
</script>
