import {
  BadRequestException,
  ConflictException,
  defaults, ForbiddenException, NetworkFailureException,
  NotFoundException,
  ServiceUnavailableException, UnauthorizedException, UnknownServerException
} from "./index";
import {storage, TOKEN} from "../store/modules/session";
import {store} from "../main";

export default async function postCertificates<Model extends FormData>(
  collection: string,
  model: Model
): Promise<string> {
  const body = model;
  const method = "POST";
  let response;

  try {
    const token = storage.getItem(TOKEN);
    if (token && "null" !== token && defaults.headers instanceof Headers) {
      defaults.headers.set("x-authorization", `Bearer ${token}`);
    }

    const headers = new Headers(defaults.headers);
    headers.delete("Content-Type");

    response = await fetch(collection, {...defaults, headers, method, body,});
  } catch (e) {
    await store.dispatch("splashScreen/showNetworkFailure");
    throw new NetworkFailureException();
  }

  const location = response.headers.get("Location");
  switch (response.status) {
    case 503:
      throw new ServiceUnavailableException();
    case 409:
      throw new ConflictException();
    case 400:
      throw new BadRequestException(await response.json());
    case 404:
      throw new NotFoundException();
    case 403:
      throw new ForbiddenException();
    case 401:
      throw new UnauthorizedException();
    case 200:
      return location as string;
    case 201:
      return location as string;
    default:
      throw new UnknownServerException(response);
  }
}
