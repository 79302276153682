<template>
  <li
    class="timeline-item"
  >
    <div class="timeline-badge bg-primary">
      <i class="fas fa-circle" />
    </div>
    <div class="timeline-panel">
      <div class="text-right">
        <is-granted
          :privileges="['UPDATE_CONTINUOUS_STAY_DAILY_DESCRIPTION']"
          :subject="description.continuousStayDailyDescriptionId"
          component="fragment"
        >
          <button
            v-b-modal="`updateContinuousStayDailyDescriptionModal-${description.continuousStayDailyDescriptionId}`"
            class="btn btn-primary m-1 btn-sm"
          >
            <i class="fa fa-pencil" />
            Edytuj wpis
          </button>
        </is-granted>
        <button
          v-if="null !== description.continuousStayDailyDescriptionId"
          v-b-modal="`historyContinuousStayDailyDescriptionModal-${description.continuousStayDailyDescriptionId}`"
          class="btn btn-primary m-1 btn-sm"
        >
          <i class="fa fa-pencil" />
          Historia
        </button>
        <is-granted
          :privileges="['REMOVE_CONTINUOUS_STAY_DAILY_DESCRIPTION']"
          :subject="description.continuousStayDailyDescriptionId"
          component="fragment"
        >
          <button
            v-b-modal="`deleteContinuousStayDailyDescriptionModal-${description.continuousStayDailyDescriptionId}`"
            class="btn btn-danger m-1 btn-sm"
          >
            <i class="fa fa-pencil" />
            Usun wpis
          </button>
        </is-granted>
      </div>
      <continuous-stay-specialists-description-form
        :description="description"
        :readonly="true"
      />
      <continuous-stay-update-daily-specialists-description-modal
        v-if="null !== description.continuousStayDailyDescriptionId"
        :description="description"
        :continuous-stay-id="continuousStayId"
        @updateList="updateList"
      />
      <continuous-stay-daily-specialists-description-history-modal
        v-if="null !== description.continuousStayDailyDescriptionId"
        :history-list="description.history"
        :continuous-stay-daily-description-id="description.continuousStayDailyDescriptionId"
      />
      <continuous-stay-delete-daily-specialists-description-modal
        :description="description"
        @updateList="updateList"
      />
    </div>
  </li>
</template>

<script>
import ContinuousStaySpecialistsDescriptionForm from "./ContinuousStaySpecialistsDescriptionForm";
import ContinuousStayUpdateDailySpecialistsDescriptionModal
  from "./ContinuousStayUpdateDailySpecialistsDescriptionModal";
import ContinuousStayDailySpecialistsDescriptionHistoryModal
  from "./ContinuousStayDailySpecialistsDescriptionHistoryModal";
import IsGranted from "../../../../components/IsGranted";
import ContinuousStayDeleteDailySpecialistsDescriptionModal
  from "./ContinuousStayDeleteDailySpecialistsDescriptionModal";

export default {
  name: "ContinuousStayDisplayDailySpecialistsDescription",
  components: {
    ContinuousStayDeleteDailySpecialistsDescriptionModal,
    ContinuousStayDailySpecialistsDescriptionHistoryModal,
    ContinuousStayUpdateDailySpecialistsDescriptionModal,
    ContinuousStaySpecialistsDescriptionForm,
    IsGranted
  },
  props: {
    description: {type: Object, default: () => {}},
    continuousStayId: {type: String, required: true}
  },
  methods: {
    updateList(){
      this.$emit("updateList");
    }
  }
}
</script>
