<template>
  <router-link
    v-if="patientCreateAllowed"
    class="btn btn-success"
    :to="{name: 'createPatientView'}"
  >
    <i class="fa fa-plus" />
    <span>&nbsp;Zarejestruj pacjenta</span>
  </router-link>
</template>

<script>
import {registerPatientButtonMixin} from "../../../mixins/registerPatientButtonMixin";

export default {
  name: "RegisterPatientButton",
  mixins: [registerPatientButtonMixin],
}
</script>
