<template>
  <li
    class="timeline-item"
  >
    <button
      class="btn btn-success timeline-badge p-0"
      :disabled="enabled"
      @click="open()"
    >
      <i class="fas fa-plus" />
    </button>
    <div
      v-if="enabled"
      class="timeline-panel"
    >
      <h4>Dodaj nowy wpis</h4>
      <continuous-stay-specialists-description-form
        :description="newDescription"
        :display-creator="false"
        :date-edit="true"
        :errors="errors"
        @update="updateForm"
      />
      <div class="text-right">
        <button
          class="btn btn-default"
          @click="reset()"
        >
          Anuluj
        </button>
        <button
          :disabled="loading"
          class="btn btn-success text-right"
          @click="save"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz zmiany
        </button>
      </div>
    </div>
    <div
      v-else
      class="timeline-panel invisible"
    />
  </li>
</template>

<script>
import ContinuousStaySpecialistsDescriptionForm from "./ContinuousStaySpecialistsDescriptionForm";
import create from "../../../../rest/create";
import processResponseException from "../../../../utils/errors/processResponseException";
import {generateUuid} from "../../../../utils/uuid/generateUuid";
import stringifyDate from "../../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";

export default {
  name: "ContinuousStayAddDailySpecialistsDescription",
  components: {
    ContinuousStaySpecialistsDescriptionForm,
  },
  props: {
    continuousStayId: {type: String, required: true},
  },
  data() {
    return {
      errors: [],
      loading: false,
      enabled: false,
      newDescription: null,
    }
  },
  methods: {
    async save(){
      this.loading = true;
      this.errors = [];
      try {
        const data = {
          continuousStayDailyDescriptionId: generateUuid(),
          continuousStayId: this.continuousStayId,
          stayDescription: this.newDescription.stayDescription,
          workerCategory: this.newDescription.workerCategory,
          icd9codes: this.newDescription.icd9codes ? this.newDescription.icd9codes.map(item => item.code) : [],
          patientPresence: !this.newDescription.patientNotShowUp,
          date: stringifyDate(new Date(this.newDescription.date), DATE_FORMAT.DATE)
        };
        await create("/api/continuous-stays/daily-descriptions", data);
        this.$emit("updateList");
        this.reset();
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    reset() {
      this.loading = false;
      this.errors = [];
      this.enabled = false;
      this.newDescription = {
        date: new Date(),
        workerCategory: null,
        patientNotShowUp: false,
        stayDescription: null,
        icd9codes: [],
      };
    },
    open() {
      this.reset()
      this.enabled = true;
    },
    updateForm(value){
      this.newDescription = value;
    },
  },
}
</script>
