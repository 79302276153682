

























































































































































































import {Component, Prop, Watch} from "vue-property-decorator";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import DatePicker from "../../Form/DatePicker/DatePicker.vue";
import {mixins} from "vue-class-component";
import ErrorsMixin from "../../../mixins/ErrorsMixin";
import TreatmentTypeSelect from "../../TreatmentType/TreatmentTypeSelect.vue";
import PatientSelect from "../../Patient/PatientSelect.vue";
import WorkerSelect from "../../Worker/WorkerSelect.vue";
import BranchSelect from "../../Branch/BranchSelect.vue";
import {isEmpty, isEqual} from "lodash";
import AppointmentDocumentStatisticsSelect from "../../Form/Select/AppointmentDocumentStatisticsSelect.vue";
import {resetPageQuery, updatePageQuery} from "../../../utils/pageUrl/handlePageQuery";
import {
  AppointmentDocumentStatisticsFilterExportQuery,
  AppointmentDocumentStatisticsFiltersType,
  AppointmentDocumentStatisticsPageQuery,
  PatientAppointmentDocumentStatisticsFilter,
  WorkerAppointmentDocumentStatisticsFilter
} from "../../../types/AppointmentDocumentStatistics";
import stringifyDate from "../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import parseDate from "../../../utils/date/parseDate";
import {mapState} from "vuex";
import {isGranted} from "../../../security/isGranted";
import {specialistRoles} from "../../../utils/roles/specialistRoles";
import {createUrl} from "../../../utils/pageUrl/createUrl";

const filtersInitialState = {
  fromDate: null,
  toDate: null,
  treatmentTypes: null,
  patient: null,
  workers: null,
  branches: null,
  visitId: null,
  sessionId: null,
}

@Component({
  name: "AppointmentDocumentStatisticsFilters",
  components: {
    BranchSelect,
    WorkerSelect,
    PatientSelect,
    TreatmentTypeSelect,
    ErrorMessage,
    DatePicker,
    AppointmentDocumentStatisticsSelect
  },
  computed: {
    ...mapState("currentUser", ["user"])
  }
})

export default class AppointmentDocumentStatisticsFilters extends mixins(ErrorsMixin) {
  @Prop({type: String, required: false}) fromDate!: string;
  @Prop({type: String, required: false}) toDate!: string;
  @Prop({type: Array, required: false}) treatmentTypes!: Array<string>;
  @Prop({type: String, required: false}) patientId!: PatientAppointmentDocumentStatisticsFilter["patientId"];
  @Prop({type: Array, required: false}) workers!: Array<WorkerAppointmentDocumentStatisticsFilter["value"]>;
  @Prop({type: Array, required: false}) branches!: Array<string>;
  @Prop({type: Number, required: false}) visitId!: number;
  @Prop({type: Number, required: false}) sessionId!: number;
  @Prop({type: Boolean, required: true}) isLoading!: boolean;

  exportButton = "Eksport";
  dateFormat = DATE_FORMAT.DATE;
  specialistDataLoading: boolean = true;
  currentUserIsSpecialist = true;
  userCannotChooseWorker = true;
  exportMode = false;
  status = [];
  exportUrl = "/api/appointment/document/statistics/export"

  formatDateToQuery(date: Date | null): string | undefined {
    return date ? stringifyDate(date, this.dateFormat) : undefined
  }
  formatDateToDateInput(date: string | undefined): Date | null {
    return date ? parseDate(date, this.dateFormat) : null
  }

  filters: AppointmentDocumentStatisticsFiltersType = {
    fromDate: this.formatDateToDateInput(this.fromDate),
    toDate: this.formatDateToDateInput(this.toDate),
    treatmentTypes: this.treatmentTypes,
    patient: this.patientId ? {patientId: this.patientId} : null,
    workers: this.workers || null,
    branches: this.branches || null,
    visitId: this.visitId || null,
    sessionId: this.sessionId || null,
  }

  private async clearFilters(): Promise<void> {
    this.exportUrl = "/api/appointment/document/statistics/export"
    this.status = [];
    this.filters = {...filtersInitialState};
    if (isEmpty(this.$route.query)) {
      this.$emit("refresh");
    } else {
      await resetPageQuery();
    }
  }

  getNewQuery(): AppointmentDocumentStatisticsPageQuery {
    return {
      ...this.$route.query,
      fromDate: this.formatDateToQuery(this.filters?.fromDate),
      toDate: this.formatDateToQuery(this.filters?.toDate),
      treatmentTypes: this.filters?.treatmentTypes?.join(",") || undefined,
      patientId: this.filters?.patient?.patientId || undefined,
      workers: this.filters?.workers?.length
        ? (this.filters?.workers as WorkerAppointmentDocumentStatisticsFilter[]).map(worker  => worker.value).join(",")
        : undefined,
      branches: this.filters?.branches?.join(",") || undefined,
      visitId: this.filters.visitId || undefined,
      sessionId: this.filters.sessionId || undefined,
    }
  }

  @Watch("status")
  updateUrl(){
    this.exportUrl = createUrl("/api/appointment/document/statistics/export", this.getReportFilterQuery());
  }

  getReportFilterQuery(): AppointmentDocumentStatisticsFilterExportQuery {
    return {
      fromDate: this.formatDateToQuery(this.filters?.fromDate),
      toDate: this.formatDateToQuery(this.filters?.toDate),
      treatmentTypes: this.filters?.treatmentTypes?.map(treatmentType  => treatmentType.value) || undefined,
      patientId: this.filters?.patient?.patientId || undefined,
      workers: this.filters?.workers?.length
        ? (this.filters?.workers as WorkerAppointmentDocumentStatisticsFilter[]).map(worker  => worker.value)
        : undefined,
      branches: this.filters?.branches || undefined,
      visitId: this.filters.visitId || undefined,
      sessionId: this.filters.sessionId || undefined,
      status:this.status.map(status  => status.value)
    }
  }

  private async submit(): Promise<void> {
    const query: AppointmentDocumentStatisticsPageQuery = this.getNewQuery()
    if (isEqual(query, this.$route.query)) {
      this.$emit("refetch");
    } else {
      await updatePageQuery({
        ...query,
        adsPage: 1
      });
    }
  }

  async mounted() {
    const isSpecialist = await isGranted(specialistRoles);
    this.currentUserIsSpecialist = isSpecialist
    this.userCannotChooseWorker = await isGranted(specialistRoles.filter(spec => spec !== "ROLE_SPECIALIST_MANAGER"));

    if (isSpecialist) {
      this.filters = {
        ...this.filters,
        workers: [this.user.workerId]
      }

      const query: AppointmentDocumentStatisticsPageQuery = {
        ...this.getNewQuery(),
        workers: this.user.workerId
      }
      if (isEqual(query, this.$route.query)) {
        this.$emit("refresh");
      } else {
        await updatePageQuery({
          ...query,
          adsPage: 1
        });
      }
    } else {
      await this.submit()
    }

    this.specialistDataLoading = false;
  }

  numberFormat(value: string) {
    const numberReg = /^\d+$/;
    return numberReg.test(value) ? value : null
  }

  toggleExportMode() {
    this.exportMode = !this.exportMode;
    this.exportButton = (this.exportMode)? "Zamknij eksport" : "Eksport"
  }
}
