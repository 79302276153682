<template>
  <div>
    <ul class="timeline">
      <is-granted
        v-if="!disabled"
        :privileges="['CREATE_CONTINUOUS_STAY_DAILY_DESCRIPTION']"
        :subject="continuousStayId"
        component="div"
      >
        <continuous-stay-add-daily-specialists-description
          :continuous-stay-id="continuousStayId"
          @updateList="loadDailyDescriptions"
        />
      </is-granted>

      <continuous-stay-display-daily-specialists-description
        v-for="(description, index) in dailyDescriptionsList"
        :key="index"
        :description="description"
        :continuous-stay-id="continuousStayId"
        @updateList="loadDailyDescriptions"
      />
    </ul>
  </div>
</template>

<script>
import ContinuousStayAddDailySpecialistsDescription from "./ContinuousStayAddDailySpecialistsDescription";
import ContinuousStayDisplayDailySpecialistsDescription from "./ContinuousStayDisplayDailySpecialistsDescription";
import read from "../../../../rest/read";
import processResponseException from "../../../../utils/errors/processResponseException";
import IsGranted from "../../../../components/IsGranted";

export default {
  components: {
    ContinuousStayDisplayDailySpecialistsDescription,
    ContinuousStayAddDailySpecialistsDescription,
    IsGranted,
  },
  props: {
    disabled: {type: Boolean, default: false},
    continuousStayId: {type: String, required: true},
  },
  data() {
    return {
      dailyDescriptionsList: [],
    };
  },
  watch: {
    continuousStayId: {
      immediate: true,
      handler() {
        this.loadDailyDescriptions();
      },
    },
  },

  methods: {
    async loadDailyDescriptions() {
      this.loadingDailyDescriptions = true;
      try {
        const response = await read(
          `/api/continuous-stays/${this.continuousStayId}/daily-descriptions`);
        this.dailyDescriptionsList = response.items;
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loadingDailyDescriptions = false;
    },
  },
}
</script>

<style lang="scss">
.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;

  &:before {
    background-color: #eee;
    bottom: 0;
    content: " ";
    left: 50px;
    margin-left: -1.5px;
    position: absolute;
    top: 20px;
    width: 3px;
  }

  .timeline-item {
    margin-bottom: 20px;
    position: relative;

    &:before,
    &:after {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }

    .timeline-panel {
      border-radius: 2px;
      border: 1px solid #d4d4d4;
      box-shadow: 0 1px 2px rgba(100, 100, 100, 0.2);
      margin-left: 100px;
      padding: 20px;
      position: relative;
    }

    .timeline-badge {
      border-radius: 50%;
      color: #fff;
      font-size: 1.4em;
      height: 50px;
      left: 50px;
      line-height: 52px;
      margin-left: -25px;
      position: absolute;
      text-align: center;
      top: 16px;
      width: 50px;
      z-index: 100;

      & + .timeline-panel {
        &:before {
          border-bottom: 15px solid transparent;
          border-left: 0 solid #ccc;
          border-right: 15px solid #ccc;
          border-top: 15px solid transparent;
          content: " ";
          display: inline-block;
          position: absolute;
          left: -15px;
          right: auto;
          top: 26px;
        }

        &:after {
          border-bottom: 14px solid transparent;
          border-left: 0 solid #fff;
          border-right: 14px solid #fff;
          border-top: 14px solid transparent;
          content: " ";
          display: inline-block;
          position: absolute;
          left: -14px;
          right: auto;
          top: 27px;
        }
      }
    }
  }

  .timeline-title {
    margin-top: 0;
    color: inherit;
  }
}
</style>

