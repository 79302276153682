export interface EstablishmentItem {
  branchId: string;
  parentId?: string;
  name: string;
  code?: string;
  nip: string;
  regon: string;
  phoneNumber: string;
  codeEight: string;
  children?: Array<EstablishmentItem>;
  address?: EstablishmentAddress;
  type: EstablishmentType;
  shortName?: string;
  krs?: string;
  providerNumber?: string;
  unitTechnicalId?: string;
  parameters?: EstablishmentParameters;
  czpAreaCode?: null|string;
  unitReportToLIOCZ?: boolean;
  establishmentSystemIdentifier?: string;
  establishmentNfzCode?: string;
  serviceScopeCode?: string;
  alternativeResortCode?: string;
}

export interface EstablishmentAddress {
  street: string;
  zipCode: string;
  city: string;
  country: string;
  terytId: null|string;
}

export enum EstablishmentType {
  ESTABLISHMENT = "establishment", // podmiot medyczny
  NON_MEDICAL = "non_medical_establishment", // podmiot niemedyczny
  INSTITUTE = "institute", // zakład
  DIVISION = "division", // jednostka
  UNIT = "unit", // komórka
}

export interface EstablishmentParameters {
  patientAnonymous: boolean;
  ageGroupTarget: AgeGroupTargetType|null;
  partnershipSignature: string|null;
  filterResearchSurveysByAgeAndSex: boolean;
  ignoreBranchRestrictions: boolean;
  extendedAddress: boolean;
  nfzConfiguration: boolean;
}

export enum AgeGroupTargetType {
  CHILDREN = "children",
  ADULTS = "adults",
}

export interface EstablishmentSmtpConfig {
  host: string|null;
  port: string|null;
  encryption: string|null;
  username: string|null;
  password: string|null;
  senderName: string|null;
  senderEmail: string|null;
}

export interface EstablishmentTreatmentTypeWeights {
  establishmentName: string;
  units: EstablishmentTreatmentTypeWeightsUnit[];
}
export interface EstablishmentTreatmentTypeWeightsUnit {
  id: string;
  resortCode: string;
  codeEight: string;
  country: string;
  zipCode: string;
  city: string;
  street: string;
  technicalNumber: number;
  treatmentTypes: [
    {
      id: string;
      name: string;
      settlementCode: string;
      weights: [
        {
          year: number;
          month: number;
          weight: number;
        }
      ]
    }
  ]
}

export interface UnitTreatmentTypePatchDTO {
  units: UnitPatchDTO[]
}
export interface UnitPatchDTO {
  unitId: string;
  entries: UnitTreatmentTypeWeightsPatchDTO[]
}
export interface UnitTreatmentTypeWeightsPatchDTO {
  treatmentTypeId: string;
  entries: UnitTreatmentTypeWeightsEntryPatchDTO[]
}
export interface UnitTreatmentTypeWeightsEntryPatchDTO {
  year: number;
  month: number;
  weight: number;
}

export interface EstablishmentUnitPriceNumberDiscriminant {
  establishmentName: string;
  units: EstablishmentUnitPriceNumberDiscriminantUnit[];
}
export interface EstablishmentUnitPriceNumberDiscriminantUnit {
  id: string;
  name: string | null;
  serviceScopeCode: null | string;
  type: EstablishmentUnitPriceNumberDiscriminantUnitType;
  resortCode: string;
  codeEight: string;
  country: string;
  zipCode: string;
  city: string;
  street: string;
  technicalNumber: number;
  orderNumber: number;
  entries: [
    {
      year: number;
      month: number;
      price: number | null;
      number: number | null;
      discriminant: number | null;
    }
  ]
}
export enum EstablishmentUnitPriceNumberDiscriminantUnitType {
  UNIT = "UNIT",
  DPS = "DPS",
}

export interface UpdateUnitPriceNumberDiscriminantEntries {
  establishmentId: string;
  entries: UnitPriceNumberDiscriminantDTO[]
}
export interface UnitPriceNumberDiscriminantDTO {
  unitId?: string;
  type: EstablishmentUnitPriceNumberDiscriminantUnitType;
  serviceScopeCode?: string | null;
  entries: UnitPriceNumberDiscriminantEntryDTO[]
}
export type UnitPriceNumberDiscriminantEntryDTO = {
  year: number;
  month: number;
  price: number | null;
  number: number | null;
  discriminant: number | null;
}

export interface BenefitPerformanceReportInterface {
  establishmentName: string;
  units: UnitEntries[];
}

export interface UnitEntries {
  id: string | null;
  name: string | null;
  serviceScopeCode: null | string;
  type: EstablishmentUnitPriceNumberDiscriminantUnitType;
  resortCode: string;
  codeEight: string;
  country: string;
  zipCode: string;
  city: string;
  street: string;
  technicalNumber: number;
  orderNumber: number;
  entries: [
    {
      year: number;
      month: number;
      price: number | null;
      number: number | null;
      executionNumber: number | null;
    }
  ]
}
