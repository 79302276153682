




















import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import ObjectSelect from "../../Form/Select/ObjectSelect.vue";
import {
  getPsychologicalCareProblemCategoryLabel,
  PsychologicalCareProblemCategoryCode,
  PsychologicalCareProblemCategoryOption
} from "./PsychologicalCareProblemCategories";

interface Data {
  items: PsychologicalCareProblemCategoryOption[];
}

@Component({
  name: "PsychologicalCareProblemCategorySelect",
  components: {ObjectSelect},
})
export default class PsychologicalCareProblemCategorySelect extends Vue {
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String, default: "Zacznij wpisywać kod"}) readonly placeholder!: string;
  @Prop({type: [Object, Array], default: null}) readonly value!: PsychologicalCareProblemCategoryOption;
  @Prop({type: Array, default: []}) readonly suggestedCodes!: string[];

  @Watch("value")
  async onValueChange(): Promise<void> {
    if(Array.isArray(this.value) && this.value.length === 0){
      this.options = [];
    }
  }

  public options: PsychologicalCareProblemCategoryOption[] = Object
    .entries(PsychologicalCareProblemCategoryCode)
    .map(([code, label]) => ({
      name: `${label} - ${getPsychologicalCareProblemCategoryLabel(PsychologicalCareProblemCategoryCode[code])}`,
      value: PsychologicalCareProblemCategoryCode[code],
    }))

  /* suggested options on top */
  public suggestedOption: PsychologicalCareProblemCategoryOption[] = this.options
    .filter(opt => this.suggestedCodes.includes(opt.value))

  public notSuggestedOption: PsychologicalCareProblemCategoryOption[] = this.options
    .filter(opt => !this.suggestedCodes.includes(opt.value))

  public groupedOptions: PsychologicalCareProblemCategoryOption[] = [...this.suggestedOption, ...this.notSuggestedOption]
  /* e/o suggested options on top */

  private get selected(): PsychologicalCareProblemCategoryOption {
    return this.value;
  }

  private update(option: PsychologicalCareProblemCategoryOption): void {
    this.$emit("input", option);
  }

  private select(option: PsychologicalCareProblemCategoryOption): void {
    this.$emit("select", option);
  }
}
