<template>
  <div>
    <div class="card-box m-b-5">
      <register-patient-button
        v-if="permissionForProcessingPersonalData"
        class="text-right"
      />
      <form
        class="form-row"
        @submit.prevent="submit"
      >
        <b-form-group
          class="col-lg-6"
          label="Pacjent"
        >
          <b-input
            v-model="currentPhrase"
            :placeholder="placeholder"
            class="form-control"
            name="phrase"
          />
        </b-form-group>
        <b-form-group
          class="col-md-6 col-lg-4"
          :label="nfzConfiguration ? 'Koordynator opieki' : 'Case manager'"
        >
          <worker-select
            v-model="selectedCaseManagers"
            clearable
            multiple
            name="caseManagers"
          />
        </b-form-group>
        <b-form-group
          class="col-md-6 col-lg-4"
          label="Specjalista"
        >
          <worker-select
            v-model="selectedWorkers"
            clearable
            multiple
            name="worker"
          />
        </b-form-group>
        <b-form-group
          v-if="showMobileTeamColumn"
          class="col-md-6 col-lg-4"
          label="Zespół mobilny"
        >
          <worker-select
            v-model="selectedMobileTeam"
            clearable
            multiple
            name="mobileTeam"
          />
        </b-form-group>
        <div class="col-md-6 col-lg-2 no-label">
          <button
            type="submit"
            class="btn btn-primary"
          >
            <i class="fa fa-search" />
            <span>&nbsp;Szukaj</span>
          </button>
        </div>
      </form>
    </div>
    <card
      :loading="loading"
      title=""
    >
      <template #control>
        <a
          v-if="!loading"
          :href="exportUrl"
          class="btn btn-warning mr-2"
          target="_blank"
        >
          <i class="fa fa-atom" />
          Eksportuj
        </a>
      </template>
      <pagination-description
        :items-count="items.length"
        :page="currentPage"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      />
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th
                class="order-btn"
                @click="changeOrder('displayName')"
              >
                Pacjent
                <i
                  :class="orderedBy('displayName')"
                  class="fas icon"
                />
              </th>
              <th
                v-if="clinicParameters && !clinicParameters.patientAnonymous"
                class="order-btn"
                @click="changeOrder('fileNumber')"
              >
                Numer teczki
                <i
                  :class="orderedBy('fileNumber')"
                  class="fas icon"
                />
              </th>
              <th
                class="order-btn"
                @click="changeOrder('caseManager')"
              >
                {{ caseManagerLabel }}
                <i
                  :class="orderedBy('caseManager')"
                  class="fas icon"
                />
              </th>
              <th
                v-if="showMobileTeamColumn"
                class="order-btn"
                @click="changeOrder('mobileTeam')"
              >
                Zespół mobilny
                <i
                  :class="orderedBy('mobileTeam')"
                  class="fas icon"
                />
              </th>
              <th
                class="order-btn"
                @click="changeOrder('lastVisitDate')"
              >
                Data ostatniej wizyty
                <i
                  :class="orderedBy('lastVisitDate')"
                  class="fas icon"
                />
              </th>
              <th
                v-if="!permissionForProcessingPersonalData"
                class="order-btn"
                @click="changeOrder('permissionForProcessingPersonalDataStatusCanceledDate')"
              >
                Data wycofania zgody
                <i
                  :class="orderedBy('permissionForProcessingPersonalDataStatusCanceledDate')"
                  class="fas icon"
                />
              </th>
              <th>Dane szczegółowe</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="patient in items"
              :key="patient.patientId"
            >
              <td>{{ patient.displayName }}</td>
              <td
                v-if="clinicParameters && !clinicParameters.patientAnonymous"
              >
                {{ patient.fileNumber }}
              </td>
              <td>{{ patient.caseManagerName ? patient.caseManagerName : "brak" }}</td>
              <td
                v-if="showMobileTeamColumn"
              >
                <p
                  v-for="mobileTeamMember in patient.mobileTeam"
                  :key="mobileTeamMember.workerId"
                >
                  {{ mobileTeamMember.displayName }}
                </p>
              </td>
              <td>{{ patient.lastVisitDate }}</td>
              <td v-if="!permissionForProcessingPersonalData">
                {{ patient.permissionForProcessingPersonalDataStatusCanceledDate }}
              </td>
              <td>
                <is-granted
                  :privileges="['LIST_PATIENT_RELATIONS']"
                  component="fragment"
                >
                  <router-link
                    :to="`/patients/${patient.patientId}/relations/form`"
                    class="btn btn-success btn-sm m-1"
                  >
                    Powiązania osobowe
                  </router-link>
                </is-granted>
                <is-granted
                  :privileges="['PATIENT_CARD']"
                  :subject="patient.patientId"
                  component="fragment"
                  start-with-unsubscribe
                >
                  <router-link
                    :to="{name: 'patientCard', params: {patientId: patient.patientId}}"
                    class="btn btn-secondary btn-sm m-1"
                  >
                    Karta pacjenta
                  </router-link>
                </is-granted>
                <patient-card-change-permission-for-processing-personal-data-status
                  v-if="!permissionForProcessingPersonalData"
                  :patient="patient"
                  :permission-for-processing-personal-data-status="patient.permissionForProcessingPersonalDataStatus"
                  class="d-inline-block m-1"
                  @permissionForProcessingPersonalDataStatusChanged="fetchData"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <p
          v-if="0 === items.length"
          class="text-center"
        >
          Brak wyników do wyświetlenia
        </p>
      </div>
      <pagination-description
        :items-count="items.length"
        :page="currentPage"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      >
        <b-pagination
          :value="currentPage"
          :per-page="pagination.perPage"
          :total-rows="pagination.totalRows"
          align="right"
          class="mb-0"
          @input="changePage"
        />
      </pagination-description>
    </card>
  </div>
</template>
<script>
import IsGranted from "../../components/IsGranted";
import WorkerSelect from "../../components/Worker/WorkerSelect";
import read from "../../rest/read";
import Card from "../../components/Card";
import isEqual from "lodash/isEqual";
import {mapState} from "vuex";
import PaginationDescription from "../../components/Pagination/PaginationDescription";
import {changePageQuery} from "@/utils/pageUrl/handlePageQuery";
import RegisterPatientButton from "../../components/Patient/RegisterPatientButton/RegisterPatientButton";
import {createUrl} from "@/utils/pageUrl/createUrl";
import PatientCardChangePermissionForProcessingPersonalDataStatus
  from "@/components/Patient/PatientCard/PatientCardChangePermissionForProcessingPersonalDataStatus";

export default {
  name: "PatientList",
  components: {
    PatientCardChangePermissionForProcessingPersonalDataStatus,
    RegisterPatientButton,
    PaginationDescription,
    IsGranted,
    WorkerSelect,
    Card,
  },
  props: {
    phrase: {type: String, default: null},
    caseManagers: {type: Array, default: ()=>[]},
    workers: {type: Array, default: ()=>[]},
    mobileTeam: {type: Array, default: ()=>[]},
    page: {type: Number, default: null},
    sort: {type: String, default: null},
    permissionForProcessingPersonalData: {type: Boolean, default: true}
  },
  data() {
    return {
      currentPage: this.page || 1,
      currentPhrase: this.phrase,
      selectedCaseManagers: this.caseManagers,
      selectedWorkers: this.workers,
      selectedMobileTeam: this.mobileTeam,
      pagination: {},
      items: [],
      loading: false,
      order: this.sortToObject()
    };
  },
  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration,
      clinicParameters: state => state.clinicParameters.parameters,
    }),
    caseManagerLabel() {
      return this.nfzConfiguration ? "Koordynator opieki"  : "Case manager";
    },
    placeholder() {
      if (!this.clinicParameters) {
        return "Wyszukaj pacjenta";
      }
      return this.clinicParameters.patientAnonymous
        ? "Numer Teczki lub Numer Zewnętrzny pacjenta"
        : "Nazwisko, PESEL, Telefon, Numer Teczki lub E-mail pacjenta";
    },
    orderString() {
      return this.order.column?`${this.order.column}:${this.order.direction}` : undefined;
    },
    filters() {
      return {
        page: this.currentPage || undefined,
        phrase: this.currentPhrase || undefined,
        caseManagers: this.selectedCaseManagers ? this.getWorkersIds(this.selectedCaseManagers) : [],
        workers: this.getWorkersIds(this.selectedWorkers),
        mobileTeam: this.getWorkersIds(this.selectedMobileTeam),
        sort: this.orderString,
        permissionForProcessingPersonalData: this.permissionForProcessingPersonalData
      }
    },
    query() {
      return {
        page: this.currentPage || undefined,
        sort: this.orderString,
        phrase: this.currentPhrase || undefined,
        caseManagers: this.selectedCaseManagers ? this.getWorkersIds(this.selectedCaseManagers).join(",") : undefined,
        workers: this.getWorkersIds(this.selectedWorkers).join(",") || undefined,
        mobileTeam: this.getWorkersIds(this.selectedMobileTeam).join(",") || undefined,
      }
    },
    exportUrl() {
      return createUrl("/api/patients/export", this.filters);
    },
    showMobileTeamColumn() {
      return this.clinicParameters && this.clinicParameters.ageGroupTarget !== "children";
    }
  },
  watch: {
    page(val) {
      this.currentPage = val || 1;
    },
    phrase(val) {
      this.currentPhrase = val;
    },
    caseManagers(val) {
      this.selectedCaseManagers = val || [];
    },
    workers(val) {
      this.selectedWorkers = val || [];
    },
    mobileTeam(val) {
      this.selectedMobileTeam = val || [];
    },
    async "$route"() {
      await this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const {items, pagination} = await read("/api/patients", this.filters);
      this.items = items;
      this.pagination = pagination;
      this.loading = false;
    },
    updatePageUrl() {
      changePageQuery(this.query);
    },
    async changePage(page) {
      this.currentPage = page;
      this.updatePageUrl();
    },
    submit() {
      this.currentPage = 1;
      if (isEqual(this.$route.query, this.query)) {
        this.fetchData();
        return;
      }
      this.updatePageUrl();
    },
    getWorkersIds(selected) {
      if (selected.length && "object" === typeof selected[0]) {
        return selected.map(worker => worker.value);
      }
      return selected;
    },
    changeOrder(columnName) {
      this.order = {
        column: columnName,
        direction: this.order.direction === "asc" ? "desc" : "asc"
      };
      this.updatePageUrl();
    },
    orderedBy(columnName) {
      return {
        "fa-sort": this.order.column !== columnName,
        "fa-caret-up": this.order.column === columnName,
        "upside-down": this.order.column === columnName && this.order.direction === "asc"
      }
    },
    sortToObject() {
      const sort = {
        column: "displayName",
        direction: "asc"
      };
      if(this.sort) {
        const sortArray = this.sort.split(":");
        sort.column = sortArray[0];
        sort.direction = sortArray[1];
      }

      return sort;
    }
  }
}
</script>

<style scoped lang="scss">
  .order-btn {
    cursor: pointer;
    white-space: nowrap;
  }

  .icon {
    transition: transform 1s;
    transform: rotate(0deg);

    &.upside-down {
      transform: rotate(180deg);
    }
  }
</style>
