


















































































































































































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import Card from "../../components/Card.vue";
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import IsGranted from "../../components/IsGranted.vue";
import {
  EstablishmentUnitPriceNumberDiscriminantUnit,
  EstablishmentUnitPriceNumberDiscriminantUnitType,
  UnitPriceNumberDiscriminantDTO, UnitPriceNumberDiscriminantEntryDTO, UpdateUnitPriceNumberDiscriminantEntries,
} from "../../types/Establishment";
import update from "../../rest/update";
import processResponseException from "../../utils/errors/processResponseException";
import AddUnitPriceNumberContractDataFormModal from "./AddUnitPriceNumberContractDataFormModal.vue";
import RemoveUnitPriceNumberContractDataItemModal from "./RemoveUnitPriceNumberContractDataItemModal.vue";

interface TableEntry {
  year: number;
  month: number;
  unitOrderNumber: number;
  price?: number | string | null;
  number?: number | string | null;
  discriminant?: number | string | null;
  errorTypes?: TableEntryType[];
  updatedTypes?: TableEntryType[];
}

enum TableEntryType {
  PRICE = "price",
  NUMBER = "number",
  DISCRIMINANT = "discriminant",
}

interface ParsedUnitPriceNumberDiscriminantDTO extends UnitPriceNumberDiscriminantDTO {
  unitOrderNumber: number
}

@Component({
  name: "EstablishmentUnitPriceNumberDiscriminantTable",
  components: {
    RemoveUnitPriceNumberContractDataItemModal,
    AddUnitPriceNumberContractDataFormModal,
    IsGranted,
    ErrorMessage,
    Card,
  },
})

export default class EstablishmentUnitTreatmentTypesWeightsTable extends Mixins(ErrorsMixin) {
  @Prop({type: Number, required: true}) readonly selectedYear!: number;
  @Prop({type: Boolean, required: true}) readonly editing!: boolean;
  @Prop({type: String, required: true}) readonly establishmentId!: string;
  @Prop({type: Array, required: true}) readonly units!: EstablishmentUnitPriceNumberDiscriminantUnit[];

  $refs!: {
    tablesWrapper: HTMLDivElement
  }

  private saving: boolean = false;
  public errorsPerUnit: number[] = []; // unit order-numbers[]

  public values: TableEntry[] = []
  public TableEntryType = TableEntryType;
  public EstablishmentUnitPriceNumberDiscriminantUnitType = EstablishmentUnitPriceNumberDiscriminantUnitType;

  public colsDefinitions = [
    {key: TableEntryType.PRICE, label: "Umowa cena"},
    {key: TableEntryType.NUMBER, label: "Umowa liczba"},
    {key: TableEntryType.DISCRIMINANT, label: "Umowa wyróżnik"},
  ]

  fieldsDefinitions = [
    {key: "values", label: "Parametry komórki organizacyjnej"},
    {key: "month1", label: "01 styczeń", month: "1"},
    {key: "month2", label: "02 luty", month: "2"},
    {key: "month3", label: "03 marzec", month: "3"},
    {key: "month4", label: "04 kwiecień", month: "4"},
    {key: "month5", label: "05 maj", month: "5"},
    {key: "month6", label: "06 czerwiec", month: "6"},
    {key: "month7", label: "07 lipiec", month: "7"},
    {key: "month8", label: "08 sierpień", month: "8"},
    {key: "month9", label: "09 wrzesień", month: "9"},
    {key: "month10", label: "10 październik", month: "10"},
    {key: "month11", label: "11 listopad", month: "11"},
    {key: "month12", label: "12 grudzień", month: "12"},
  ]

  @Watch("units", {immediate: true}) onUnitsLoad(): void {
    this.getInitialValues()
  }

  getInitialValues() {
    this.values = []
    this.units.forEach(unit => {
      unit.entries.forEach(entry => {
        this.values.push({
          unitOrderNumber: unit.orderNumber,
          year: entry.year,
          month: entry.month,
          price: entry.price,
          number: entry.number,
          discriminant: entry.discriminant,
        })
      })
    })
  }

  getInvalidClass({unitOrderNumber, keyIdx, month}: {unitOrderNumber: number, keyIdx: TableEntryType, month: string}) {
    const item = this.values.find(valueItem =>
      valueItem.year === this.selectedYear
      && valueItem.month === Number(month)
      && valueItem.unitOrderNumber === unitOrderNumber
    )

    return (item && item.errorTypes?.includes(keyIdx)) ? "invalid" : ""
  }

  getInitialValue({unitOrderNumber, type, month}: {unitOrderNumber: number, type: TableEntryType, month: string}) {
    const valueItem = this.values.find(valueItem =>
      valueItem.year === this.selectedYear
      && valueItem.month === Number(month)
      && valueItem.unitOrderNumber === unitOrderNumber
      && valueItem.updatedTypes?.includes(type)
    )

    if (valueItem) {
      const val = type === TableEntryType.NUMBER
        ? valueItem["number"]
        : type === TableEntryType.PRICE
          ? valueItem["price"]
          : valueItem["discriminant"]

      return String(val)
    }

    let initialVal = ""
    this.units.forEach(unit => {
      if (unit.orderNumber === unitOrderNumber) {
        const weightItem = unit.entries.find(weight => weight.month === Number(month) && weight.year === this.selectedYear)
        if (weightItem && weightItem[type] !== null) {
          initialVal = String(weightItem[type])
        }
      }
    })

    return initialVal || ""
  }

  formatUnitAddress({zipCode, city, street}: {zipCode?: string, city?: string, street?: string}) {
    const postalCodeCity = (zipCode && city) ? `${zipCode} ${city}` : ""
    return street
      ? `ul. ${street}, ${postalCodeCity}`
      : postalCodeCity
  }

  async submit() {
    const fieldsWithErrors = this.values.filter(val => val.errorTypes ? val.errorTypes.length : false)
    const invalidUnitOrderNumber = fieldsWithErrors.map(val => val.unitOrderNumber)
    this.errorsPerUnit = invalidUnitOrderNumber

    if (fieldsWithErrors.length) {
      const firstError = this.$refs.tablesWrapper.querySelector(`#pointToScroll${invalidUnitOrderNumber[0]}`)
      firstError?.scrollIntoView({behavior: "smooth"})

      return
    }

    this.$emit("setEditing", false)

    const units = this.values.reduce((values, currentValues) => {
      const unitItem = values.find(val => val.unitOrderNumber === currentValues.unitOrderNumber)

      const parseVal = (val?: number | string | null) => (val || val === 0 || val === "0") ? Number(val) : null

      const weightEntry = {
        year: currentValues.year,
        month: currentValues.month,
        price: parseVal(currentValues.price),
        number: parseVal(currentValues.number),
        discriminant: parseVal(currentValues.discriminant),
      }

      if (unitItem) {
        unitItem.entries.push(weightEntry as UnitPriceNumberDiscriminantEntryDTO)
        return values
      }

      const unitData = this.units.find(unit => unit.orderNumber === currentValues.unitOrderNumber)!

      return [
        ...values,
        {
          unitId: unitData.id,
          unitOrderNumber: currentValues.unitOrderNumber,
          type: unitData.type,
          serviceScopeCode: unitData.serviceScopeCode,
          entries: [weightEntry as UnitPriceNumberDiscriminantEntryDTO]
        }
      ]
    }, [] as ParsedUnitPriceNumberDiscriminantDTO[])

    const payloadUnits = units.map(({unitOrderNumber, ...rest}) => rest)

    this.saving = true;
    try {
      await update("/api/unit/price-number-discriminant-entries", {
        establishmentId: this.establishmentId,
        entries: payloadUnits
      } as UpdateUnitPriceNumberDiscriminantEntries);
      await this.$emit("fetchData")
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.saving = false;
  }

  change(e: Event, {unitOrderNumber, type, month}: {
    unitOrderNumber: number,
    type: TableEntryType, // price / number / discriminant
    month: string
  }) {
    const value = (e.target as HTMLInputElement).value.replace(",", ".")

    const validationRegex = /^(?!0\d)\d*(\.\d{1,4})?$/ // number with optionally 1-4 decimal places
    const intValidationRegex = /^\d+$/ // integer number

    const isInvalidValue = value
      ? type === TableEntryType.DISCRIMINANT ? !intValidationRegex.test(value) : !validationRegex.test(value)
      : false

    const entryValue = isInvalidValue
      ? value // invalid string
      : value
        ? Number(value)
        : "";

    let newEntry: TableEntry = {
      unitOrderNumber: unitOrderNumber,
      year: this.selectedYear,
      month: Number(month),
      errorTypes: isInvalidValue ? [type] : [],
      updatedTypes: [type],
      [`${type as keyof UnitPriceNumberDiscriminantEntryDTO}`]: entryValue,
    }

    const foundEntryInValues = this.values.find(valueItem =>
      valueItem.year === newEntry.year
      && valueItem.month === newEntry.month
      && valueItem.unitOrderNumber === unitOrderNumber
    )

    if (foundEntryInValues) {
      this.values = this.values.reduce((values, currentItem) => {
        const isFoundItem = currentItem.year === newEntry.year
          && currentItem.month === newEntry.month
          && currentItem.unitOrderNumber === unitOrderNumber

        return [
          ...values,
          isFoundItem
            ? {
              ...currentItem,
              ...newEntry,
              errorTypes: isInvalidValue
                ? [...(currentItem.errorTypes || []), type]
                : currentItem.errorTypes?.filter(et => et !== type) || [],
              updatedTypes: [...(currentItem.updatedTypes || []), type]
            }
            : currentItem
        ] as TableEntry[]
      }, [] as TableEntry[])
    } else {
      this.values = [
        ...this.values,
        newEntry
      ]
    }
  }

  openAddUnitEntryModal() {
    this.$bvModal.show("addUnitPriceNumberContractDataFormModal")
  }

  openDeleteUnitEntryModal(unitOrderNumber: number) {
    this.$bvModal.show( `removeUnitPriceNumberContractDataItemModal${unitOrderNumber}`)
  }

  async fetchData() {
    await this.$emit("fetchData")
    this.$emit("setEditing", false)
  }

  cancelEditing() {
    this.getInitialValues()
    this.$emit("setEditing", false)
  }

  getTypeLabel(type: EstablishmentUnitPriceNumberDiscriminantUnitType) {
    switch(type) {
      case EstablishmentUnitPriceNumberDiscriminantUnitType.UNIT:
        return "Lista podmiotów";
      case EstablishmentUnitPriceNumberDiscriminantUnitType.DPS:
        return "DPS"
      default:
        return ""
    }
  }
}
