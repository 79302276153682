<template>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <router-link
        :to="{name: 'ipz_details_simplified_ipz', params: {ipzId: overview.ipzId}}"
        exact
        active-class="active"
        class="nav-link"
      >
        PTiZ - Uproszczony
      </router-link>
    </li>
    <is-granted
      :privileges="['CHILD_IPZ_LIST']"
      component="fragment"
    >
      <li class="nav-item">
        <router-link
          :to="{name: 'child_ipzs', params: {ipzId: overview.ipzId}}"
          exact
          active-class="active"
          class="nav-link"
        >
          PTiZ Dziecięcy
        </router-link>
      </li>
    </is-granted>
    <li class="nav-item">
      <router-link
        :to="{name: 'ipz_details_application_form', params: {ipzId: overview.ipzId}}"
        exact
        active-class="active"
        class="nav-link"
      >
        Formularz zgłoszeniowy
      </router-link>
    </li>

    <li class="nav-item">
      <router-link
        :to="{name: 'ipz_details_admission_sheet', params: {ipzId: overview.ipzId}}"
        exact
        active-class="active"
        class="nav-link"
      >
        Arkusz przyjęciowy
      </router-link>
    </li>
    <is-granted
      :privileges="['READ_FAMILY_INTERVIEW_DIAGNOSIS_DETAILS']"
      :subject="overview.ipzId"
      component="li"
      class="nav-item"
    >
      <router-link
        :to="{name: 'ipz_details_family_interview_diagnosis', params: {ipzId: overview.ipzId}}"
        exact
        active-class="active"
        class="nav-link"
      >
        Wywiad rodzinny
      </router-link>
    </is-granted>
    <li
      v-if="'child' === overview.ipzSetup"
      class="nav-item"
    >
      <router-link
        :to="{name: 'viewIpzDetailsPsychiatricDiagnosis', params: {ipzId: overview.ipzId}}"
        exact
        active-class="active"
        class="nav-link"
      >
        Badanie psychiatryczne
      </router-link>
    </li>
    <li
      v-if="'child' === overview.ipzSetup"
      class="nav-item"
    >
      <router-link
        :to="{name: 'ipz_details_pediatric_diagnosis', params: {ipzId: overview.ipzId}}"
        exact
        active-class="active"
        class="nav-link"
      >
        Badanie somatyczne
      </router-link>
    </li>
    <li
      v-if="'child' === overview.ipzSetup"
      class="nav-item"
    >
      <router-link
        :to="{name: 'ipz_details_neurological_diagnosis', params: {ipzId: overview.ipzId}}"
        exact
        active-class="active"
        class="nav-link"
      >
        Badanie neurologiczne
      </router-link>
    </li>
    <li
      class="nav-item"
    >
      <router-link
        :to="{name: 'nfz-therapeutic-and-recovery-plan', params: {ipzId:overview.ipzId}}"
        exact
        active-class="active"
        class="nav-link"
      >
        Plan Terapii i Zdrowienia
      </router-link>
    </li>
    <li class="nav-item">
      <router-link
        :to="{name: 'icf_creator', params: {ipzId:overview.ipzId}}"
        exact
        active-class="active"
        class="nav-link"
      >
        Kreator ICF
      </router-link>
    </li>
    <li class="nav-item">
      <router-link
        :to="{name: 'ipz_evaluations_list', params: {ipzId: overview.ipzId}}"
        exact
        active-class="active"
        class="nav-link"
      >
        Arkusze ICF / Ewaluacja
      </router-link>
    </li>
  </ul>
</template>

<script>
import IsGranted from "../IsGranted";
import {mapState} from "vuex";

export default {
  name: "IpzTabs",
  components: {IsGranted},
  props: {
    tab: {type: String, required: true},
    overview: {type: Object, required: true},
  },
  data(){
    return {
      showNfzTherapeuticAndRecoveryPlan: false
    }
  },
  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration
    }),
  },
}
</script>
