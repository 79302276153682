export enum PaperReferralType {
  "healing_entity" = "healing_entity",
  "professional_practice" = "professional_practice",
  "businessman" = "businessman",
  "institution" = "institution"
}

export enum PaperReferralNumber {
  "regon" = "regon",
  "bookNumber" = "bookNumber"
}

export enum PaperReferralTypeDefaultValue {
  "value" = "businessman"
}
