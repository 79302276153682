<template>
  <div class="card-box">
    <div class="row">
      <div class="col-12">
        <h4>Dane dokumentu potwierdzającego uprawnienia dodatkowe</h4>
        <b-form-group label="Tryb przyjęcia">
          <additional-entitlements-code-select
            :value="formData.additionalEntitlementCode"
            :state="state('additionalEntitlements.additionalEntitlementCode')"
            :disabled="readOnly"
            @input="input({ ...formData, additionalEntitlementCode: $event})"
          />
          <error-message
            :errors="errors"
            field="additionalEntitlements.additionalEntitlementCode"
          />
        </b-form-group>
        <b-form-group
          id="documentName"
          label="Nazwa dokumentu (max. 100 znaków)"
          label-for="documentName"
        >
          <b-form-input
            id="documentName"
            :value="formData.documentName"
            :state="state('additionalEntitlements.documentName')"
            :disabled="readOnly"
            placeholder="Wprowadź nazwe dokumentu"
            @input="input({ ...formData, documentName: $event ? $event.trim() : null})"
          />
          <error-message
            :errors="errors"
            field="additionalEntitlements.documentName"
          />
        </b-form-group>
        <b-form-group
          id="documentId"
          label="Identifikator dokumentu (max.40 znaków)"
          label-for="documentName"
        >
          <b-form-input
            id="documentName"
            :value="formData.documentId"
            :state="state('additionalEntitlements.documentId')"
            :disabled="readOnly"
            placeholder="Wprowadź identyfikator dokumentu"
            @input="input({ ...formData, documentId: $event ? $event.trim() : null})"
          />
          <error-message
            :errors="errors"
            field="additionalEntitlements.documentId"
          />
        </b-form-group>
        <div class="row">
          <b-form-group
            class="col-6"
            label="Data wystawienia dokumentu"
          >
            <date-picker
              :value="formData.createdDate"
              :state="state('additionalEntitlements.createdDate')"
              :format="'YYYY-MM-DD'"
              :disabled="readOnly"
              @input="input({ ...formData, createdDate: $event})"
            />
            <error-message
              :errors="errors"
              field="additionalEntitlements.createdDate"
            />
          </b-form-group>
          <b-form-group
            class="col-6"
            label="Data końcowa okresu ważności"
          >
            <date-picker
              v-model="formData.validDate"
              :state="state('additionalEntitlements.validDate')"
              :format="'YYYY-MM-DD'"
              :disabled="readOnly"
              @input="input({ ...formData, validDate: $event})"
            />
            <error-message
              :errors="errors"
              field="additionalEntitlements.validDate"
            />
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdditionalEntitlementsCodeSelect
  from "../../components/Appointment/AdditionalEntitlements/AdditionalEntitlementsCodeSelect";
import ErrorMessage from "../../components/Form/ErrorMessage";
import DatePicker from "../../components/Form/DatePicker/DatePicker";

export default {
  name: "AdditionalEntitlementsDocument",
  components: {
    AdditionalEntitlementsCodeSelect,
    ErrorMessage,
    DatePicker
  },
  props: {
    value: {type: Object, required: true},
    errors: {type: Array,  default: () => []},
    readOnly: {type: Boolean, required: true},
  },
  data() {
    return {
      formData: {...this.value}
    }
  },
  watch: {
    value() {
      this.formData = {...this.value}
    },
  },
  methods: {
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    input(value) {
      this.$emit("input", value);
    }
  }
}
</script>

<style scoped>

</style>
