<template>
  <object-select
    :value="selectedValue"
    :options="options"
    :placeholder="placeholder"
    :state="state"
    :disabled="disabled"
    :multiple="multiple"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "./ObjectSelect";
import t from "../../../i18n";

export default {
  name: "NfzAlternativeTreatmentType",
  components: {
    ObjectSelect,
  },
  props: {
    value: {type: Array, default: () => []},
    state: ObjectSelect.props.state, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz usługę alternatywną"},
    disabled: {type: Boolean, default: false},
    multiple: {type: Boolean, default: false}
  },
  data() {
    return {
      optionValues: [
        {value: "first_contact_visit"},
        {value: "recovery_assistant_support"},
        {value: "support_of_career_counselor"},
        {value: "participation_in_support_group"},
        {value: "coordination_activities_within_PTiZ"},
        {value: "nursing_service"},
        {value: "advice_from_community_therapist"},
        {value: "participation_in_provision_of_service"},
        {value: "psychological_support"},
        {value: "treatments_and_injections"},
      ],
    };
  },
  computed: {
    options() {
      return this.optionValues.map(option => ({
        ...option,
        name: t(`@nfz-alternative-treatment.${option.value}`),
      }));
    },
    selectedValue() {
      return this.options.filter((option) => (this.value.includes(option.value)));
    },
  },
  methods: {
    update(option) {
      if (!this.multiple) {
        this.$emit("input", [option.value]);
      } else {
        this.$emit("input", option.map((opt) => (opt.value)));
      }
    },
  }
}
</script>
