<template>
  <b-modal
    :id="`deleteContinuousStayDailyDescriptionModal-${description.continuousStayDailyDescriptionId}`"
    title="Usunięcie opisu dziennego"
    hide-footer
    no-close-on-backdrop
    title-tag="h3"
    size="lg"
  >
    <p>Czy napewno chcesz usunąć wpis?</p>
    <error-message
      :errors="errors"
    />
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary"
        @click="remove"
      >
        <i
          v-show="!loading"
          class="fas fa-trash"
        />
        <i
          v-show="loading"
          class="fas fa-spin fa-spinner"
        />
        Usuń
      </button>
    </div>
  </b-modal>
</template>

<script>
import processResponseException from "../../../../utils/errors/processResponseException";
import ErrorMessage from "../../../Form/ErrorMessage";
import remove from "../../../../rest/remove";

export default {
  name: "ContinuousStayDeleteDailySpecialistsDescriptionModal",
  components: {
    ErrorMessage,
  },
  props: {
    description: {type: Object, required: true}
  },
  data() {
    return {
      errors: [],
      loading: false,
    }
  },
  methods: {
    async remove(){
      this.loading = true;
      this.errors = [];
      try {
        const url = `/api/continuous-stays/${this.description.continuousStayDailyDescriptionId}/daily-descriptions`;
        await remove(url, {});
        this.$emit("updateList");
        this.closeModal();
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    closeModal(){
      this.$bvModal.hide(
        `deleteContinuousStayDailyDescriptionModal-${this.description.continuousStayDailyDescriptionId}`
      );
    },
  }
}
</script>

<style scoped>

</style>
