<template>
  <object-select
    :value="selectedValue"
    :options="options"
    :placeholder="placeholder"
    :state="state"
    :disabled="disabled || !loadedOptionsForWorker"
    :multiple="multiple"
    @input="update"
    @open="loadWorkerValues"
  />
</template>

<script>
import ObjectSelect from "./ObjectSelect";
import t from "../../../i18n";
import read from "../../../rest/read";

export default {
  name: "NfzWorkerTypeSelect",
  components: {
    ObjectSelect,
  },
  props: {
    value: {type: [Object, String, Array, null], default: null},
    state: ObjectSelect.props.state, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz typ personelu"},
    disabled: {type: Boolean, default: false},
    workerId: {type: String, default: null},
    multiple: {type: Boolean, default: false}
  },
  data() {
    return {
      optionValues: [
        {value: "specialist_in_psychiatry"},
        {value: "incomplete_specialist_in_psychiatry"},
        {value: "psychologist"},
        {value: "nurse"},
        {value: "environmental_therapist"},
        {value: "occupational_therapist"},
        {value: "psychotherapist"},
        {value: "recovery_assistant"},
        {value: "career_counselor"},
        {value: "addiction_therapist"},
        {value: "specialist_in_clinical_psychology"},
        {value: "specialist_in_psychotherapy_of_children_and_adolescents"},
        {value: "supervisor"},
        {value: "clinical_psychologist_or_in_the_course_of_specialization"},
        {value: "speech_therapist"},
        {value: "educator"},
        {value: "special_educator"},
        {value: "physiotherapist"},
        {value: "social_worker"},
        {value: "dietician"},
      ],
      loadedOptionsForWorker: !this.workerId
    };
  },
  computed: {
    options() {
      return this.optionValues.map(option => ({
        ...option,
        name: t(`@nfz-worker-type.${option.value}`),
      }));
    },
    selectedValue() {
      return this.options.filter((option) => this.multiple
        ? this.value.includes(option.value)
        : this.value === option.value
      );
    },
  },
  watch:{
    async workerId() {
      this.loadedOptionsForWorker = false
      await this.loadWorkerValues();
    }
  },
  async mounted() {
    await this.loadWorkerValues();
  },
  methods: {
    update(option) {
      if(!this.multiple){
        if(null === option){
          this.$emit("input", null);
        } else {
          this.$emit("input", option.value);
        }
      } else {
        this.$emit("input", option.map((opt)=> (opt.value)));
      }
    },
    async loadWorkerValues() {
      if(this.workerId && !this.loadedOptionsForWorker){
        const {types} = await read(`/api/workers/${this.workerId}/nfz-types`);
        this.optionValues = types.map(item => ({value: item}));

        const hasInitialValue = this.multiple ? this.value?.length : this.value

        if (types.length === 1 && !hasInitialValue) {
          // set default value if its only one element in list
          const defaultValue = types[0]
          this.$emit("input", this.multiple ? [defaultValue] : defaultValue);
        }

        this.loadedOptionsForWorker = true
      }
    }
  },
}
</script>
