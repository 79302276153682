<template>
  <card
    :loading="isLoading"
    :title="title"
  >
    <template #control>
      <is-granted
        :privileges="['CREATE_CHRONIC_DISEASE']"
        component="fragment"
      >
        <b-btn
          v-b-modal.create-chronic-disease-modal
          class="btn btn-primary btn-sm float-right m-b-10"
        >
          <i class="fa fa-plus" />
          Dodaj rozpoznanie
        </b-btn>
      </is-granted>
    </template>
    <template #default>
      <error-message :errors="errors" />
      <table class="table">
        <thead>
          <tr>
            <th>
              Data diagnozy
            </th>
            <th>
              Obecny status choroby
            </th>
            <th>
              Kod choroby (ICD-10)
            </th>
            <th>
              Utworzono
            </th>
            <th>
              Notatka
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="chronicDisease in chronicDiseasesList"
            :key="chronicDisease.chronicDiseaseId"
          >
            <td>{{ chronicDisease.diagnosisDate }}</td>
            <td>
              <template v-if="chronicDisease.closed">
                <b-badge
                  class="status-badge"
                  variant="success"
                >
                  wyleczona
                </b-badge>
                <div>
                  {{ chronicDisease.endOfCureDate ? '('+chronicDisease.endOfCureDate+')' : '' }}
                </div>
              </template>
              <b-badge
                v-else
                class="status-badge"
                variant="danger"
              >
                niewyleczona
              </b-badge>
            </td>
            <td :id="chronicDisease.chronicDiseaseId">
              {{ chronicDisease.icd10Code }}
              <b-tooltip
                :target="chronicDisease.chronicDiseaseId"
                boundary="viewport"
                placement="left"
              >
                {{ chronicDisease.icd10Description }}
              </b-tooltip>
            </td>
            <td>
              {{ chronicDisease.createdDate }}<br>
              {{ chronicDisease.createdBy }}
            </td>
            <td class="width-45p">
              <read-more
                :text="!chronicDisease.note? 'Brak notatki' : chronicDisease.note"
                more-str="rozwiń"
                less-str="zwiń"
                btn-class="btn-sm"
              />
            </td>
            <td class="text-nowrap text-right">
              <is-granted
                v-if="!chronicDisease.endOfCureDate"
                :privileges="['UPDATE_CHRONIC_DISEASE']"
                component="fragment"
              >
                <b-btn
                  class="m-1"
                  size="sm"
                  variant="primary"
                  @click="openModal('chronic-disease-update-modal', chronicDisease)"
                >
                  <i class="fa fa-pencil-alt" /> Edytuj
                </b-btn>
              </is-granted>
              <is-granted
                v-if="!chronicDisease.endOfCureDate"
                :privileges="['CLOSE_CHRONIC_DISEASE']"
                component="fragment"
              >
                <b-btn
                  class="m-1"
                  size="sm"
                  variant="outline-secondary"
                  @click="openModal('chronic-disease-close-modal', chronicDisease)"
                >
                  <i class="fa fa-check" /> Zamknij
                </b-btn>
              </is-granted>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination-description
        :items-count="chronicDiseasesList.length"
        :page="page"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      >
        <b-pagination
          :value="page"
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          align="right"
          class="mb-0"
          @input="changePage"
        />
      </pagination-description>
      <patient-card-create-chronic-disease
        :patient-id="patientId"
        @chronicDiseaseCreated="getChronicDiseaseList"
      />
      <patient-card-update-chronic-disease
        :chronic-disease="selectedChronicDisease"
        :patient-id="patientId"
        @chronicDiseaseUpdated="getChronicDiseaseList"
      />
      <patient-card-close-chronic-disease
        :chronic-disease-id="selectedChronicDisease ? selectedChronicDisease.chronicDiseaseId : null"
        :patient-id="patientId"
        @chronicDiseaseUpdated="getChronicDiseaseList"
      />
    </template>
  </card>
</template>

<script>
import read from "../../../../rest/read";
import PatientCardCreateChronicDisease from "./PatientCardCreateChronicDisease";
import PatientCardUpdateChronicDisease from "./PatientCardUpdateChronicDisease";
import PatientCardCloseChronicDisease from "./PatientCardCloseChronicDisease";
import ReadMore from "../../../ReadMore";
import IsGranted from "../../../IsGranted";
import Card from "../../../Card";
import PaginationDescription from "../../../Pagination/PaginationDescription";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import ErrorMessage from "@/components/Form/ErrorMessage";
import processResponseException from "@/utils/errors/processResponseException";

export default {
  name: "PatientCardChronicDiseases",
  components: {
    ErrorMessage,
    PaginationDescription,
    Card,
    ReadMore,
    PatientCardCloseChronicDisease,
    PatientCardUpdateChronicDisease,
    PatientCardCreateChronicDisease,
    IsGranted,
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
    title: {type: String, default: "Rozpoznania pacjenta"},
  },
  data() {
    return {
      isLoading: false,
      chronicDiseasesList: [],
      selectedChronicDisease: null,
      page: 1,
      pagination: {perPage: 5, totalRows: 0},
    };
  },
  watch: {
    patientId() {
      this.page = 1;
      this.getChronicDiseaseList();
    },
  },
  mounted() {
    this.getChronicDiseaseList();
  },
  methods: {
    async getChronicDiseaseList() {
      this.isLoading = true;
      this.errors = [];
      try {
        const response = await read(`/api/patient/${this.patientId}/chronic-diseases`, {
          perPage: this.pagination.perPage,
          page: this.page,
        });
        this.chronicDiseasesList = response.items;
        this.pagination = response.pagination;
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.isLoading = false;
    },
    openModal(modalId, chronicDisease) {
      this.selectedChronicDisease = chronicDisease;
      this.$bvModal.show(modalId);
    },
    changePage(page) {
      this.page = page;
      this.getChronicDiseaseList();
    }
  },
}
</script>

<style scoped lang="scss">
.status-badge {
  width: 6rem;
}
.width-45p{
  width: 45%!important;
}
</style>
