export interface SurveyItem {
  surveyId: string;
  startedAt: string;
  endDate: string|null;
  token: string|null;
  name: string;
  tag: string|null;
  tokenExpiryDate: string|null;
  eligiblePerson: EligiblePerson|null;
  requesterType: RequesterType;
  workerDisplayName: string|null;
  tokenExpired: boolean;
  recipientEmail?: string|null;
}

export enum RequesterType {
  ELIGIBLE_PERSON = "eligiblePerson",
  WORKER = "worker",
  PATIENT = "patient",
}

export interface EligiblePerson {
  type?: string;
  name?: string|null;
  surname?: string|null;
  pesel?: string|null;
  address?: Address|null;
  description?: string|null;
  phoneNumber?: string|null;
  email?: string|null;
  id?: string;
}

export interface Address {
  country: string|null;
  zipCode: string|null;
  city: string|null;
  street: string|null;
  apartmentNumber:string|null
  buildingNumber:string|null
  terytId:string|null
  province:string|null
  district:string|null
}
