<template>
  <div class="row">
    <div v-if="!nfzConfiguration">
      <appointment-counter-widget
        :remote="false"
        class="col-12 col-sm-6 col-xl-4"
      />
      <intervention-counter-widget
        class="col-12 col-sm-6 col-xl-4"
      />
      <appointment-counter-widget
        :remote="true"
        class="col-12 col-sm-6 col-xl-4"
      />
      <appointment-list-widget
        class="col-12 col-xl-8"
      />
      <is-granted
        :privileges="['PATIENT_OPS_LIST']"
        component="fragment"
      >
        <patient-ops-list
          class="col-12"
          :page="opsPage"
        />
      </is-granted>
      <web-notifications-widget
        class="col-12 col-xl-4"
      />
      <coordinated-team-load-list-widget
        class="col-12 col-xl-8"
      />
      <CourseListWidget
        class="col-12 col-xl-8"
      />
    </div>
    <is-granted
      :privileges="['LIST_OPENED_APPOINTMENTS', 'OPENED_APPOINTMENTS_WIDGET']"
      component="fragment"
      condition="AND"
    >
      <opened-appointment-list-widget
        :class="nfzConfiguration ? 'col-12 col-xl-12' : 'col-12 col-xl-8'"
      />
    </is-granted>
    <div class="col-12 col-xl-4">
      <prevention-actions-widget
        v-if="!nfzConfiguration"
      />
      <add-to-home-screen-widget />
    </div>
    <div v-if="!nfzConfiguration">
      <is-granted
        :privileges="['GET_SYSTEM_NOTIFICATION_LIST']"
        component="fragment"
      >
        <system-notifications-widget
          class="col-12"
        />
      </is-granted>
    </div>
  </div>
</template>

<script>
import AddToHomeScreenWidget from "../../components/Dashboard/AddToHomeScreenWidget";
import InterventionCounterWidget from "../../components/InterventionNotification/InterventionCounterWidget";
import AppointmentCounterWidget from "../../components/Appointment/AppointmentCounterWidget";
import CourseListWidget from "../../components/Prevention/CourseListWidget";
import PreventionActionsWidget from "../../components/Prevention/PreventionActionsWidget";
import AppointmentListWidget from "../../components/Appointment/AppointmentListWidget";
import SystemNotificationsWidget from "../../components/SystemNotifications/SystemNotificationsWidget";
import {mapMutations, mapState} from "vuex";
import {isGranted} from "../../security/isGranted";
import IsGranted from "../../components/IsGranted";
import WebNotificationsWidget from "../../components/WebNotifications/WebNotificationsWidget";
import OpenedAppointmentListWidget from "../../components/Appointment/OpenedAppointmentListWidget";
import CoordinatedTeamLoadListWidget from "../../components/CoordinatedTeamLoad/CoordinatedTeamLoadListWidget";
import PatientOpsList from "@/components/Patient/PatientOpsList";

export default {
  name: "Home",
  components: {
    CoordinatedTeamLoadListWidget,
    WebNotificationsWidget,
    IsGranted,
    SystemNotificationsWidget,
    AppointmentListWidget,
    PreventionActionsWidget,
    CourseListWidget,
    AppointmentCounterWidget,
    InterventionCounterWidget,
    AddToHomeScreenWidget,
    OpenedAppointmentListWidget,
    PatientOpsList
  },
  beforeRouteLeave(to, from, next) {
    this.saveAppointmentListItem(null);
    next();
  },
  props: {
    opsPage: {type: Number, default: null},
  },
  computed: {
    ...mapState({
      nfzConfiguration: state => state.clinicParameters.parameters.nfzConfiguration,
    }),
  },
  methods: {
    ...mapMutations("appointmentListItem", ["saveAppointmentListItem",]),
  },
};
</script>
