<template>
  <b-modal
    id="request-medical-record-edit"
    size="xs"
    no-close-on-backdrop
    title="Edycja"
    title-tag="h3"
    @hide="closeModal"
    @show="setData"
  >
    <template #default>
      <b-form-group
        label="Sposób wydania dokumentacji"
      >
        <b-form-input
          v-model="issuingMethod"
          :state="state('issuingMethod')"
          placeholder="Wpisz sposób wydania dokumentacji"
        />
        <error-message
          :errors="errors"
          field="issuingMethod"
        />
      </b-form-group>
      <hr>
      <b-form-group
        label="Notatka"
      >
        <resizable-textarea
          v-model="note"
          :state="state('note')"
          placeholder="Wpisz notakę"
          rows="3"
        />
        <error-message
          :errors="errors"
          field="note"
        />
      </b-form-group>
      <hr>
      <b-form-group
        label="Osoba która wydała dokumentację medyczną"
      >
        <worker-select
          v-model="worker"
          :multiple="false"
          :state="state('worker')"
          placeholder="Wpisz osobę która wydał dokumentację medyczną"
        />
        <error-message
          :errors="errors"
          field="worker"
        />
      </b-form-group>
      <hr>
      <b-form-group
        label="Data wydania"
        label-for="release-date"
      >
        <date-picker
          id="release-date"
          v-model="releaseDate"
          :state="state('releaseDate')"
        />
        <error-message
          :errors="errors"
          field="releaseDate"
        />
      </b-form-group>
      <hr>
      <div class="row">
        <b-form-checkbox
          v-model="inReport"
          :state="state('inReport')"
        >
          Uwzględniany w wykazie ?
        </b-form-checkbox>
        <error-message
          :errors="errors"
          field="inReport"
        />
      </div>
    </template>
    <template #modal-footer>
      <button
        class="btn btn-secondary"
        @click="closeModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary"
        @click="saveModal"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import DatePicker from "../../../Form/DatePicker/DatePicker";
import ResizableTextarea from "../../../Form/Textarea/ResizableTextarea";
import update from "../../../../rest/update";
import processResponseException from "../../../../utils/errors/processResponseException";
import ErrorMessage from "../../../Form/ErrorMessage";
import stringifyDate from "../../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";
import WorkerSelect from "../../../Worker/WorkerSelect";

export default {
  name: "RequestMedicalRecordsEdit",
  components:{
    ResizableTextarea,
    DatePicker,
    ErrorMessage,
    WorkerSelect
  },
  props: {
    record: {type: Object, default: null},
    loading:{type: Boolean,default: false}
  },
  data() {
    return {
      worker : null,
      issuingMethod : null,
      inReport: true,
      releaseDate :null,
      note: null,
      errors : []
    };
  },
  methods: {
    async setData(){
      await this.record
      if(this.record.additionalInformation){
        this.issuingMethod = this.record.additionalInformation.issuingMethod;
        this.worker = this.record.additionalInformation.worker;
        this.inReport = this.record.additionalInformation.inReport;
        this.releaseDate = new Date(this.record.additionalInformation.releaseDate);
        this.note = this.record.additionalInformation.note
      }
    },
    closeModal() {
      this.worker = null;
      this.issuingMethod = null;
      this.inReport = true;
      this.releaseDate = null;
      this.note = null;
      this.$bvModal.hide("request-medical-record-edit");
      this.errors = []
    },
    state(field){
      return this.errors.find((error) => error.field === field)? false : null;
    },
    async saveModal(){
      try{
        const data = {
          issuingMethod : this.issuingMethod,
          note : this.note,
          worker : (this.worker)? this.worker.workerId : null,
          releaseDate : (this.releaseDate)? stringifyDate(new Date(this.releaseDate), DATE_FORMAT.DATE): null,
          inReport : this.inReport
        }
        this.errors = [];
        await update(`/api/medical-record/${this.record.id}/add_medical_records_additional_information`, data);
        this.$emit("loadRequestMedicalRecordsList");
        this.closeModal()
      } catch (e) {
        this.errors = processResponseException(e);
      }

    }
  },
}
</script>

<style scoped>

</style>
