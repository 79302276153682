<template>
  <card
    :loading="loading"
    title="Wnioski o wydanie dokumentacji medycznej"
  >
    <template #control>
      <b-btn
        v-b-modal.create-request-medical-record
        class="m-1"
        size="sm"
        variant="primary"
      >
        <i class="fa fa-plus" />
        Dodaj wniosek
      </b-btn>
    </template>
    <b-table
      :fields="fields"
      :items="items"
      :responsive="true"
      show-empty
      empty-text="Brak wniosków o wydanie dokumentacji medycznej"
      hover
    >
      <template #cell(createdAt)="{item}">
        {{ formatDate(item.createdAt) }}
      </template>
      <template #cell(requester)="{item}">
        {{ item.requester }}
      </template>
      <template #cell(branches)="{item}">
        {{ branchesNames(item.branches) }}
      </template>
      <template #cell(includedContinuousStay)="{item}">
        {{ includedContinuousStay(item.type) }}
      </template>
      <template #cell(continuousStay)="{item}">
        <template v-if="item.continuousStay">
          {{ item.continuousStay.category }}
          <br>
          {{ continuousStayTime(item.continuousStay) }}
        </template>
      </template>
      <template #cell(options)="{item}">
        <b-btn
          v-if="item.status==='completed'"
          :href="item.password ? undefined : `/api/files/${item.id}`"
          size="sm"
          variant="primary"
          @click="openModal(item)"
        >
          <i class="fas fa-file-download" /> Pobierz dokumentację
        </b-btn>
        <b-btn
          variant="primary"
          class="btn-sm m-l-5"
          @click="openEdit(item)"
        >
          <i class="fa fa-pencil-alt" />
        </b-btn>
        <span
          v-if="item.status==='empty_documentation'"
          class="text-danger"
        >
          <i class="fa fa-exclamation-triangle" /> Brak dokumentacji medycznej
        </span>
        <span
          v-if="item.status==='rejected'"
          class="text-danger"
        >
          <i class="fa fa-exclamation-triangle" /> Wygenerowanie dokumentacji nie powiodło się.
        </span>
        <template v-if="item.status==='pending'">
          <p>
            <i class="fas fa-spin fa-spinner" />
            Dokumentacja w trakcie przygotowania
          </p>
          <b-btn
            size="sm"
            variant="light"
            @click="loadRequestMedicalRecordsList"
          >
            <i class="fas fa-sync-alt" />
            Odśwież status wniosku
          </b-btn>
        </template>
      </template>
    </b-table>
    <pagination-description
      :items-count="items.length"
      :page="page"
      :per-page="pagination.perPage"
      :total-count="pagination.totalRows"
    >
      <b-pagination
        :value="page"
        :total-rows="pagination.totalRows"
        :per-page="pagination.perPage"
        align="right"
        class="mb-0"
        @input="changePage"
      />
    </pagination-description>
    <create-request-medical-record
      :patient="patient"
      :request-type="requestType"
      :continuous-stay-id="continuousStayId"
      @addRequestMedicalRecord="addRequestMedicalRecord"
    />
    <request-medical-record-modal
      v-if="record"
      :id="record.id"
      :password="record.password"
    />
    <request-medical-records-edit
      :record="record"
      :loading="loading"
      @loadRequestMedicalRecordsList="loadRequestMedicalRecordsList"
    />
  </card>
</template>

<script>
import CreateRequestMedicalRecord from "./CreateRequestMedicalRecord";
import RequestMedicalRecordModal from "./RequestMedicalRecordModal";
import Card from "../../../Card";
import read from "../../../../rest/read";
import processResponseException from "../../../../utils/errors/processResponseException";
import parseDate from "../../../../utils/date/parseDate";
import stringifyDate from "../../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";
import PaginationDescription from "../../../Pagination/PaginationDescription";
import eventBus from "../../../../eventBus";
import RequestMedicalRecordsEdit from "./RequestMedicalRecordsEdit";

export default {
  components: {
    PaginationDescription,
    Card,
    CreateRequestMedicalRecord,
    RequestMedicalRecordModal,
    RequestMedicalRecordsEdit
  },
  props: {
    patient: {type: Object, required: true},
    requestType: {type: String, default: "ambulatory"},
    continuousStayId: {type: String, default: null},
    scope: {type: Array, default: null},
    columns: {type: Array, default: null},
  },
  data() {
    return {
      loading: false,
      items: [],
      errors: [],
      page: 1,
      pagination: {perPage: 5, totalRows: 0},
      record: null
    };
  },
  computed: {
    fieldsDefinitions() {
      let fields = [
        {key: "createdAt", label: "Data utworzenia"},
        {key: "requester", label: "Wydanie na wniosek"},
        {key: "branches", label: "Komórki"},
        {key: "includedContinuousStay", label: "Włączone pobyty ciągłe"}
      ];
      if (this.items.some(item => item.continuousStay)) {
        fields.push({key: "continuousStay", label: "Pobyt ciągły"});
      }
      fields.push({key: "options", label: "", class: "w-25"});
      return fields;
    },
    fields() {
      if (!this.columns) {
        return this.fieldsDefinitions;
      }

      return this.columns
        .map((column) => this.fieldsDefinitions.find((definition) => definition.key === column))
        .filter((definition) => null != definition);
    },
  },
  watch: {
    async "patient.patientId"() {
      this.page = 1;
      await this.loadRequestMedicalRecordsList();
    },
    async "continuousStayId"() {
      this.page = 1;
      await this.loadRequestMedicalRecordsList();
    },
  },
  async mounted() {
    await this.loadRequestMedicalRecordsList();
  },
  methods: {
    async loadRequestMedicalRecordsList() {
      this.loading = true;
      try {
        const {items, pagination} = await read(`/api/request-medical-records/${this.patient.patientId}`, {
          page: this.page,
          perPage: this.pagination.perPage,
          continuousStayId: this.continuousStayId,
          scope: this.scope,
        });
        this.items = items ? items : [];
        this.pagination = pagination;
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    formatDate(dateString) {
      return stringifyDate(parseDate(dateString, DATE_FORMAT.DATE_TIME_SEC), DATE_FORMAT.DATE_TIME);
    },
    async addRequestMedicalRecord(requestMedicalRecordsId) {
      await this.loadRequestMedicalRecordsList();
      eventBus.on(requestMedicalRecordsId, async () => {
        await this.loadRequestMedicalRecordsList();
      });
    },
    async changePage(page) {
      this.page = page;
      await this.loadRequestMedicalRecordsList();
    },
    openModal(data){
      if (!data.password) {
        return;
      }
      this.record = {
        id: data.id,
        password: data.password
      };
      setTimeout(() => this.$bvModal.show("request-medical-record-modal"));
    },
    openEdit(data){
      this.record = data
      this.$bvModal.show("request-medical-record-edit")
    },
    standardizeDate(dateStr) {
      const dateObj = parseDate(dateStr, DATE_FORMAT.DATE_TIME_SEC);
      return stringifyDate(dateObj, DATE_FORMAT.DATE_TIME)
    },
    continuousStayTime(continuousStay) {
      const startDate = this.standardizeDate(continuousStay.startDate);
      return continuousStay.endDate
        ? `${startDate} - ${this.standardizeDate(continuousStay.endDate)}`
        : startDate;
    },
    branchesNames(branches){
      return branches.join(", ")
    },
    includedContinuousStay(type){
      if(type === "all"){
        return "Tak"
      }
    }
  },
}
</script>
