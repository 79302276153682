<template>
  <card
    :loading="isLoading"
    title="Wynik diagnozy psychologicznej"
  >
    <template #default>
      <error-message :errors="errors" />

      <table class="table">
        <thead>
          <tr>
            <th>
              Data diagnozy
            </th>

            <th class="w-xs">
              Kod kategorii problemu
            </th>

            <th class="w-xs">
              Nazwa kategorii problemu
            </th>

            <th class="w-xs">
              Specjalista
            </th>

            <th class="w-xs">
              Komórka organizacyjna
            </th>

            <th>
              Notatka
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(diagnosisItem, id) in psychologicalDiagnosisList"
            :key="id"
          >
            <td>{{ diagnosisItem.diagnosisDate.split('T')[0] }}</td>

            <td>{{ diagnosisItem.problemCategory }}</td>

            <td>
              {{ getPsychologicalCareProblemCategoryLabel(diagnosisItem.problemCategory) }}
            </td>

            <td>
              {{ diagnosisItem.workerName }}
            </td>

            <td>
              {{ diagnosisItem.branchName }}
            </td>

            <td>
              {{ diagnosisItem.note }}
            </td>
          </tr>
        </tbody>
      </table>

      <pagination-description
        :items-count="psychologicalDiagnosisList.length"
        :page="page"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      >
        <b-pagination
          :value="page"
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          align="right"
          class="mb-0"
          @input="changePage"
        />
      </pagination-description>
    </template>
  </card>
</template>

<script>
import read from "../../../rest/read";
import Card from "../../Card";
import PaginationDescription from "../../Pagination/PaginationDescription";
import ErrorMessage from "@/components/Form/ErrorMessage";
import processResponseException from "@/utils/errors/processResponseException";
import {errorsMixin} from "@/mixins/errorsMixin";
import {getPsychologicalCareProblemCategoryLabel}
  from "@/components/PsychologicalDiagnosis/PsychologicalCareProblemCategory/PsychologicalCareProblemCategories";

export default {
  name: "PatientCardPsychologicalDiagnosis",
  components: {
    ErrorMessage,
    PaginationDescription,
    Card,
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      isLoading: false,
      psychologicalDiagnosisList: [],
      page: 1,
      pagination: {perPage: 5, totalRows: 0},
    };
  },
  watch: {
    patientId() {
      this.page = 1;
      this.getPsychologicalDiagnosisList();
    },
  },
  mounted() {
    this.getPsychologicalDiagnosisList();
  },
  methods: {
    getPsychologicalCareProblemCategoryLabel,
    async getPsychologicalDiagnosisList() {
      this.isLoading = true;
      this.errors = [];
      try {
        const response = await read(`/api/patient/${this.patientId}/psychological-diagnosis-result`, {
          perPage: this.pagination.perPage,
          page: this.page,
        });
        this.psychologicalDiagnosisList = response.items;
        this.pagination = response.pagination;
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.isLoading = false;
    },
    changePage(page) {
      this.page = page;
      this.getPsychologicalDiagnosisList();
    }
  },
}
</script>

<style scoped lang="scss">
.status-badge {
  width: 6rem;
}
.width-45p{
  width: 45%!important;
}
</style>
