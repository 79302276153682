import { render, staticRenderFns } from "./AppointmentTabsCard.vue?vue&type=template&id=50b09701&"
import script from "./AppointmentTabsCard.vue?vue&type=script&lang=js&"
export * from "./AppointmentTabsCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports