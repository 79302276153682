<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between align-items-center">
          <h4 class="page-title">
            Rejestracja pacjenta
          </h4>

          <is-granted
            :privileges="['PATIENT_ARCHIVAL_DATA']"
            component="div"
          >
            <button
              v-if="!archiveId"
              type="button"
              class="btn btn-primary"
              @click="showSearchArchiveModal"
            >
              Wyszukaj w archiwum
            </button>
            <button
              v-else
              type="button"
              class="btn btn-outline-primary"
              @click="archiveId = null"
            >
              Anuluj powiązanie z archiwum
            </button>
          </is-granted>
        </div>
      </div>
      <div class="col-12">
        <last-edited-appointment />
      </div>
    </div>
    <patient-create-personal-data
      v-model="personalData"
      :errors="errors"
    />

    <div class="card-box">
      <h4>Adres zamieszkania</h4>
      <hr>
      <address-form
        v-model="livingAddress"
        :errors="getSubErrors('livingAddress')"
      />
      <div class="row">
        <b-form-checkbox
          v-model="differentRegisteredAddress"
        >
          Adres zameldowania jest inny niż zamieszkania
        </b-form-checkbox>
      </div>
    </div>

    <div class="card-box">
      <h4>Osoby powiązane</h4>
      <hr>
      <eligible-persons
        v-model="persons"
        :disabled="loading"
        :errors="errors"
        optional-pesel
        multiple
        error-field-name="eligiblePersons"
        form-expanded
        @addPatientAddress="addPatientAddress($event)"
      />
      <error-message
        v-if="!persons.length"
        :errors="errors"
        field="eligible_persons"
      />
    </div>

    <patient-create-adult-type
      v-model="adultType"
      :errors="errors"
    />

    <patient-create-continuing-education
      v-model="continuingEducation"
      :errors="errors"
      @redirectToPatientCard="redirectToPatientCard"
    />

    <div
      v-if="differentCorrespondenceAddress"
      class="card-box"
    >
      <h4>Adres korespondencyjny</h4>
      <hr>
      <address-form
        v-model="correspondenceAddress"
        :errors="getSubErrors('correspondenceAddress')"
      />
    </div>

    <div
      v-if="differentRegisteredAddress"
      class="card-box"
    >
      <h4>Adres zameldowania</h4>
      <hr>
      <address-form
        v-model="registeredAddress"
        :errors="getSubErrors('registeredAddress')"
      />
    </div>

    <div class="card-box">
      <create-patient-permissions
        v-model="permissions"
        :errors="errors"
        :display-type="permissionType.NFZ"
      />
    </div>

    <div class="card-box">
      <create-patient-ewus-permissions
        v-model="ewus"
        :errors="getSubErrors('ewus')"
      />
    </div>

    <error-message :errors="errors" />

    <div class="text-right">
      <router-link
        :to="{name: 'listPatients'}"
        class="btn btn-secondary"
      >
        Anuluj
      </router-link>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary ml-2"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>

    <patient-archive-search-modal @selected="setDataFromArchive" />
  </div>
</template>

<script>

import patientCreatePersonalData from "../../components/Patient/PatientCreatePersonalData.vue";
import PatientCreateContinuingEducation from "../../components/Patient/PatientCreateContinuingEducation.vue";
import create from "../../rest/create";
import {generateUuid} from "@/utils/uuid/generateUuid";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import AddressForm from "../../components/Address/AddressForm";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../../components/Form/ErrorMessage";
import subErrors from "../../utils/errors/subErrors";
import {mapActions} from "vuex";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import {BadRequestException} from "@/rest";
import initHospitalizationsData from "../../utils/initHospitalizationsData";
import CreatePatientPermissions from "@/components/Patient/CreatePatientPermissions";
import {PermissionType} from "../../components/Patient/patientPermissions";
import CreatePatientEwusPermissions from "../../components/Patient/CreatePatientEwusPermissions";
import LastEditedAppointment from "@/components/Appointment/LastEditedAppointment";
import PatientArchiveSearchModal from "../../components/Patient/PatientArchiveSearchModal";
import IsGranted from "../../components/IsGranted";
import EligiblePersons from "../../components/EligiblePersons/EligiblePersons";
import PatientCreateAdultType from "../../components/Patient/PatientCreateAdultType";

export default {
  name: "PatientCreate",
  components: {
    IsGranted,
    PatientArchiveSearchModal,
    CreatePatientEwusPermissions,
    CreatePatientPermissions,
    ErrorMessage,
    LastEditedAppointment,
    AddressForm,
    patientCreatePersonalData,
    PatientCreateAdultType,
    PatientCreateContinuingEducation,
    EligiblePersons
  },
  mixins: [errorsMixin],
  data() {
    return {
      patientId: null,
      adultType: null,
      differentCorrespondenceAddress: false,
      differentRegisteredAddress: false,
      personalData: {},
      continuingEducation: null,
      socioDemographicData: {},
      patientParticipantStatusData: {},
      patientHospitalizationsData: initHospitalizationsData(),
      livingAddress: {},
      correspondenceAddress: {},
      registeredAddress: {},
      loading: false,
      archiveId: null,
      laborMarketStatus: {},
      sourceOfInformation: {},
      establishments: [],
      permissions: {"permissionsList":[],"medicalDataAuthorizationsPersons":[]},
      ewus: [],
      permissionType: PermissionType,
      persons: [],
    };
  },
  computed: {
    registeredDateString() {
      if (this.personalData && this.personalData.registeredDate) {
        return stringifyDate(this.personalData.registeredDate, DATE_FORMAT.DATE);
      }
      return null
    },
  },
  mounted() {
    this.clearStickyPatient();
  },
  methods: {
    ...mapActions({
      showToast: "toastNotification/showToast",
      clearStickyPatient: "stickyPatient/clear",
    }),
    addPatientAddress(personIndex) {
      this.persons = this.persons.map((person, index) => {
        if (index === personIndex) {
          return {
            ...person,
            address: {...this.livingAddress},
          };
        }
        return person;
      });

    },
    getSubErrors(field) {
      return subErrors(this.errors, field);
    },
    getHospitalizationDataValue(dataField) {
      const fieldValue = this.patientHospitalizationsData[dataField].value;
      const value =
        this.patientHospitalizationsData[dataField].doesRemember && (fieldValue || 0 === fieldValue)
          ? fieldValue
          : null;

      return {
        ...this.patientHospitalizationsData[dataField],
        value,
      };
    },
    async submit() {
      this.loading = true;
      this.patientId = generateUuid();
      const patientHospitalized = this.patientHospitalizationsData.wasHospitalized === "yes";
      const hospitalizationsData = {
        ...this.patientHospitalizationsData,
        whenFirstHospitalized: patientHospitalized
          ? this.getHospitalizationDataValue("whenFirstHospitalized") : null,
        whenLastHospitalized: patientHospitalized
          ? this.getHospitalizationDataValue("whenLastHospitalized") : null,
        howManyHospitalizationsLastPeriod: patientHospitalized
          ? this.getHospitalizationDataValue("howManyHospitalizationsLastPeriod") : null,
        howLongHospitalizedLastPeriod: patientHospitalized
          ? this.getHospitalizationDataValue("howLongHospitalizedLastPeriod") : null,
      };

      const data = {
        patientId: this.patientId,
        clientId: generateUuid(),
        ...this.personalData,
        ...this.continuingEducation,
        birthdate: this.birthDateString(),
        registeredDate: this.registeredDateString,
        livingAddress: {...this.livingAddress},
        socioDemographicData: this.socioDemographicData,
        patientParticipantStatusData: this.patientParticipantStatusData,
        hospitalizationsData,
        laborMarketStatus: this.laborMarketStatus,
        adultType: this.adultType,
        correspondenceAddress: this.differentCorrespondenceAddress ? this.correspondenceAddress : this.livingAddress,
        registeredAddress: this.differentRegisteredAddress ? this.registeredAddress : this.livingAddress,
        sourceOfInformation: this.sourceOfInformation,
        establishments: this.establishments ? this.establishments.map((establishment) => establishment.value): [],
        permissions: this.permissions.permissionsList,
        archiveId: this.archiveId,
        ewusPermissions: this.ewus,
        medicalDataAuthorizationsPersons: this.permissions.medicalDataAuthorizationsPersons.map(medicalDataAuthorization => ({
          authorizedPersonName: medicalDataAuthorization.authorizedPersonName,
          authorizedPersonSurname: medicalDataAuthorization.authorizedPersonSurname,
          endOfAuthorization: (medicalDataAuthorization.endOfAuthorization)? stringifyDate(medicalDataAuthorization.endOfAuthorization):null,
          authorizedPersonPhoneNumber: (medicalDataAuthorization.authorizedPersonPhoneNumber !== "")? medicalDataAuthorization.authorizedPersonPhoneNumber: null,
          authorizedPersonEmail: (medicalDataAuthorization.authorizedPersonEmail !== "")? medicalDataAuthorization.authorizedPersonEmail:null
        })),
        eligiblePersons: this.persons.map(person => ({
          name: person.name,
          surname: person.surname,
          pesel: person.pesel,
          address: person.address,
          type: person.type,
          description: person.description,
          phoneNumber: person.phoneNumber,
          email: person.email,
        })),
      };
      try {
        await create("/api/nfz/patient/create", data);
        this.showToast({
          message: "Dane zostały zapisane",
          variant: "success",
        });

        if(this.continuingEducation) {
          this.$bvModal.show("attachmentNotification");
        }else {
          await this.redirectToPatientCard();
        }

      } catch (exception) {
        this.errors = processResponseException(exception);
        if (exception instanceof BadRequestException) {
          this.showToast({
            message: "Formularz zawiera błędy.\nUzupełnij dane aby zarejestrować pacjenta",
            variant: "danger"
          });
        } else {
          this.showToast({
            message: "Wystąpił błąd.\nDane nie zostały zapisane",
            variant: "danger",
          });
        }
      }
      this.loading = false;
    },
    async redirectToPatientCard(){
      await this.$router.push({name: "patientCard", params: {patientId: this.patientId}});
    },
    birthDateString() {
      if (this.personalData && this.personalData.birthdate) {
        return stringifyDate(this.personalData.birthdate, DATE_FORMAT.DATE);
      }
      return null
    },
    showSearchArchiveModal() {
      this.$bvModal.show("patientArchiveSearchModal")
    },
    setDataFromArchive(data) {
      this.archiveId = data.id

      this.personalData = {
        ...this.personalData,
        name: data.name,
        surname: data.surname,
        birthDate: new Date(data.birthDate),
        pesel: data.pesel,
        phoneNumber: data.phoneNumber,
        fileNumber: data.fileNumber,
        externalNumber: data.externalNumber,
      }
      this.socioDemographicData = {
        ...this.socioDemographicData,
        educationLevel: data.education,
        disabilityLevel: data.disabilityLevel,
        maritalStatus: data.maritalStatus,
      }

      this.laborMarketStatus = {
        ...this.laborMarketStatus,
        ...data.laborMarketStatus,
      }

      this.patientParticipantStatusData = {
        ...this.patientParticipantStatusData,
        ...data.patientParticipantStatusData,
      }

      this.livingAddress = {
        ...this.livingAddress,
        ...data.livingAddress,
      }

      this.differentCorrespondenceAddress = true

      this.correspondenceAddress = {
        ...data.livingAddress,
        ...this.correspondenceAddress,
        ...data.correspondenceAddress,
      }

      this.differentRegisteredAddress = true

      this.registeredAddress = {
        ...data.livingAddress,
        ...data.registeredAddress,
      }
    }
  },
}
</script>
