




























import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import Tooltip from "../../Tooltip.vue";

@Component({
  name: "InsuranceStatusIcon",
  components: {
    Tooltip,
  }
})
export default class InsuranceStatusIcon extends Vue {
  @Prop({type: Boolean, default: () => null}) status!: boolean | null;
  @Prop({type: String, default: () => "10px"}) size!: string;
  @Prop({type: Boolean, default: () => false}) withLabel!: boolean;

  get text(): string
  {
    if (null === this.status) {
      return "Brak danych";
    }

    return this.status? "Pacjent ubezpieczony" : "Pacjent nieubezpieczony";
  }

  get shortText(): string {
    if (null === this.status) {
      return "Brak danych";
    }

    return this.status? "Tak" : "Nie";
  }
}
